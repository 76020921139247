import React from 'react';

import { Modal, Spinner } from 'react-bootstrap';

function ModalConfirm({ open, setOpen, onConfirm, loading }) {
  return (
    <Modal show={open} onHide={() => setOpen(false)} centered>
      <Modal.Body className="text-center">
        <h5>Você tem certeza que deseja alterar o status desse produto?</h5>
        <div className="d-flex align-items-center justify-content-center mb-3 mt-3">
          <button className="btn btn-outline-danger btn-sm" onClick={onConfirm} disabled={loading}>
            {loading && (
              <Spinner animation="border" size="14px"/>
            )} Sim
          </button>
          <button className="btn btn-danger btn-sm ml-2" onClick={() => setOpen(false)} disabled={loading}>
            Não
          </button>
        </div>
      </Modal.Body>
    </Modal>
  );
}

export default ModalConfirm;