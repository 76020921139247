import React, { useState, useEffect } from "react";

import TableCustomers from "./TableCustomers";
import ViewCustomer from "./ViewCustomer";
import RegistrationCustomer from "./RegistrationCustomer";
import HistoryCustomer from "./HistoryCustomer";
import axios from "axios";

function Customers() {
  const [customers, setCustomers] = useState([]);
  const [view, setView] = useState({
    active: false,
    id: null,
  });
  const [registration, setRegistration] = useState({
    active: false,
    id: null,
  });
  const [history, setHistory] = useState({
    active: false,
    id: null,
  });

  async function loadCustomers() {
    const { data } = await axios.get("/customers");
    setCustomers(data);
  }

  useEffect(() => {
    loadCustomers();
  }, []); // eslint-disable-line

  return (
    <div id="customer_container">
      {!view.active && !registration.active && !history.active && (
        <TableCustomers
          customers={customers}
          viewCustomer={(e) =>
            setView({
              active: true,
              id: e,
            })
          }
          registrationCustomer={(e) =>
            setRegistration({
              active: true,
              id: e,
            })
          }
          historyCustomer={(e) =>
            setHistory({
              active: true,
              id: e,
            })
          }
        />
      )}
      {view.active && (
        <ViewCustomer
          onUpdate={() => loadCustomers()}
          onClose={() =>
            setView({
              active: false,
              id: null,
            })
          }
          id={view.id}
        />
      )}
      {registration.active && (
        <RegistrationCustomer
          onClose={() =>
            setRegistration({
              active: false,
              id: null,
            })
          }
          id={registration.id}
        />
      )}
      {history.active && (
        <HistoryCustomer
          onClose={() =>
            setHistory({
              active: false,
              id: null,
            })
          }
          id={history.id}
        />
      )}
    </div>
  );
}

export default Customers;
