import React, { useEffect, useState } from "react";
import axios from "axios";

import { Content } from "./styles";

import Course from "./components/Course";

import UserHeader from '../components/SubHeader';

function Register({ match }) {
  const [product, setProduct] = useState("");

  useEffect(() => {
    async function loadData() {
      const { data } = await axios.get(`/products/${match.params.product_id}`);
      setProduct(data.title);
    }
    loadData();
  }, [match.params.product_id]);

  return (
    <Content>
      <UserHeader />
      <div id="main_content">
        <h2>{product} - Cursos</h2>
        <Course product_id={match.params.product_id} />
      </div>
    </Content>
  );
}

export default Register;
