import React from 'react';

import { Modal, Button } from 'react-bootstrap';

function ModalRemove({ open, setOpen, onConfirm }) {
  return (
    <Modal show={open} onHide={setOpen} centered>
      <Modal.Body>
        <div className="text-center" style={{ padding: '15px' }}>
          <h4>Você tem certeza que deseja deletar este vídeo?</h4>
          <div className="d-flex justify-content-center align-items-center mt-4">
            <Button type="button" onClick={onConfirm} variant="outline-danger" className="mr-2">
              Sim, excluir
            </Button>
            <Button type="button" onClick={setOpen} variant="danger">
              Cancelar
            </Button>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}

export default ModalRemove;