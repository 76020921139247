import React from "react";

import { Content } from "./styles";

import UserHeader from "../components/SubHeader";
import { useTranslation } from "react-i18next";

function LiveCommerce({ match }) {
  const { t } = useTranslation();

  const [iFrameHeight, setIFrameHeight] = React.useState(0);
  const iframeRef = React.useRef(null);
  const [embed, setEmbed] = React.useState(false);

  return (
    <Content>
      <UserHeader />
      <div id="main_content">
        <h2>{t("Live commerce")}</h2>

        <iframe
          style={{ height: 800 }}
          src="https://api.livecommerce.tupi.io/front"
          allow="clipboard-write"
        ></iframe>
      </div>
    </Content>
  );
}

export default LiveCommerce;
