import React, { useState, useEffect } from "react";

import { Form, Table } from "react-bootstrap";
import { RiVideoLine } from "react-icons/ri";
import { FaAngleUp, FaAngleDown } from "react-icons/fa";
import { Content } from "./styles";

import axios from "axios";

import statusVideo from "../../../utils/statusVideo";
import statusVideoColor from "../../../utils/statusVideoColor";

import SendVideo from "./SendVideo";
import ModalEdit from "./components/ModalEdit";
import ModalRemove from "./components/ModalRemove";

import UserHeader from "../components/SubHeader";

function ModalSendVideo({ match }) {
  const [files, setFiles] = useState([]);
  const [uploaded, setUploaded] = useState([]);
  const [video, setVideo] = useState({
    productId: null,
    courseId: null,
  });
  const [videos, setVideos] = useState([]);
  const [products, setProducts] = useState([]);
  const [courses, setCourses] = useState([]);

  const [editVideo, setEditVideo] = useState({
    active: false,
    id: null,
  });
  const [removeVideo, setRemoveVideo] = useState({
    active: false,
    id: null,
  });

  useEffect(() => {
    async function loadData() {
      const { data: dataProducts } = await axios.get(`products/`);
      setProducts(dataProducts);
    }
    loadData();
  }, []);

  async function loadCourse(productId) {
    const { data: dataCourses } = await axios.get(
      `products/${productId}/courses`
    );
    setFiles([]);
    setCourses(dataCourses);
    setVideo({
      ...video,
      productId,
    });
  }
  async function loadVideo(courseId) {
    const { data: dataVideos } = await axios.get(`courses/${courseId}/videos`);
    let entry_ids = [];
    for (let i = 0; i < dataVideos.length; i++) {
      const video = dataVideos[i];
      if (
        !video.video_status_id ||
        video.video_status_id === 3 ||
        video.video_status_id === 4 ||
        video.video_status_id === 7 ||
        video.video_status_id === 10
      ) {
        entry_ids.push(video.entry_id);
      }
    }
    if (entry_ids.length > 0) {
      await axios.post(`kaltura/status`, {
        entry_ids,
      });
      const { data } = await axios.get(`courses/${courseId}/videos`);
      setVideos(data);
    } else {
      setVideos(dataVideos);
    }
    setFiles([]);
    setVideo({
      ...video,
      courseId,
    });
  }

  async function upOrder(id, changeId) {
    try {
      await axios.put(`videos/order/${id}/${changeId}`);
      await loadVideo(video.courseId)
    } catch (error) {}
  }

  async function downOrder(id, changeId) {
    try {
      await axios.put(`videos/order/${id}/${changeId}`);
      await loadVideo(video.courseId)
    } catch (error) {}
  }

  async function handleRemove(id) {
    try {
      await axios.delete(`courses/${video.courseId}/videos/${id}`);
      setVideos(videos.filter((u) => u.id !== id));
    } catch (err) {}
  }

  return (
    <Content>
      {editVideo.active && (
        <ModalEdit
          open={editVideo.active}
          setOpen={() => setEditVideo({ active: false, id: null })}
          course_id={video.courseId}
          id={editVideo.id}
          onUpdate={() => {
            loadVideo(video.courseId);
            setEditVideo({ active: false, id: null });
          }}
        />
      )}
      <ModalRemove
        open={removeVideo.active}
        setOpen={() => setRemoveVideo({ active: false, id: null })}
        id={removeVideo.id}
        onConfirm={() => {
          handleRemove(removeVideo.id);
          setRemoveVideo({ active: false, id: null });
        }}
      />
      <UserHeader />
      <div id="main_content">
        <h2>Enviar Vídeos</h2>
        <div id="container">
          {products.length > 0 && (
            <>
              <Form>
                <div className="row">
                  <Form.Group className="col-md-6">
                    <Form.Label>Produto</Form.Label>
                    <Form.Control
                      as="select"
                      value={video.productId}
                      onChange={(e) => {
                        loadCourse(e.target.value);
                      }}
                    >
                      <option value="">Selecione o produto</option>
                      {products.map((e, i) => {
                        return (
                          <option value={e.id} key={i}>
                            {e.title}
                          </option>
                        );
                      })}
                    </Form.Control>
                  </Form.Group>
                  {courses.length > 0 && (
                    <Form.Group className="col-md-6">
                      <Form.Label>Curso</Form.Label>
                      <Form.Control
                        as="select"
                        value={video.courseId}
                        onChange={(e) => {
                          loadVideo(e.target.value);
                        }}
                      >
                        <option>Selecione o curso</option>
                        {courses.map((e, i) => {
                          return (
                            <option value={e.id} key={i}>
                              {e.title}
                            </option>
                          );
                        })}
                      </Form.Control>
                    </Form.Group>
                  )}
                </div>
                {videos.length > 0 && (
                  <div className="row">
                    <div className="col-12">
                      <label className="form-label">Vídeos</label>
                      <Table responsive hover style={{ background: "#fff" }}>
                        <thead>
                          <tr>
                            <th>Ordem</th>
                            <th>Thumbnail</th>
                            <th>Status</th>
                            <th>Título</th>
                            <th>Descrição</th>
                            <th className="text-right">Ações</th>
                          </tr>
                        </thead>
                        <tbody>
                          {videos &&
                            videos.map((video,index) => (
                              <tr key={video.id}>
                                <td>
                                  {videos[index - 1] && (
                                    <button
                                      type="button"
                                      className="btnOrder"
                                      onClick={() =>
                                        upOrder(video.id, videos[index - 1].id)
                                      }
                                    >
                                      <FaAngleUp />
                                    </button>
                                  )}
                                  {videos[index + 1] && (
                                    <button
                                      type="button"
                                      className="btnOrder"
                                      onClick={() =>
                                        downOrder(
                                          video.id,
                                          videos[index + 1].id
                                        )
                                      }
                                    >
                                      <FaAngleDown />
                                    </button>
                                  )}
                                </td>
                                <td>
                                  <div className="thumb">
                                    <img
                                      src={video.thumbnail}
                                      alt={video.title}
                                    />
                                  </div>
                                </td>
                                <td>
                                  <span
                                    className={`btn-sm btn-${statusVideoColor(
                                      String(video.video_status_id)
                                    )}`}
                                  >
                                    {statusVideo(video.video_status_id)}
                                  </span>
                                </td>
                                <td>{video.title}</td>
                                <td>{video.description}</td>
                                <td>
                                  <div className="d-flex justify-content-end">
                                    <button
                                      type="button"
                                      onClick={() =>
                                        setEditVideo({
                                          active: true,
                                          id: video.id,
                                        })
                                      }
                                    >
                                      Editar
                                    </button>
                                    <button
                                      type="button"
                                      onClick={() =>
                                        setRemoveVideo({
                                          active: true,
                                          id: video.id,
                                        })
                                      }
                                    >
                                      Excluir
                                    </button>
                                  </div>
                                </td>
                              </tr>
                            ))}
                        </tbody>
                      </Table>
                    </div>
                    <hr />
                  </div>
                )}
              </Form>
            </>
          )}
          {video.productId && video.courseId && (
            <>
              <h3
                className="mb-0 pt-4 text-center"
                style={{ borderTop: "1px solid #ced4da" }}
              >
                Enviar vídeos
              </h3>
              <Form.File
                className="mt-4"
                custom
                label={
                  files.length > 0 ? "Escolher mais vídeos" : "Escolher vídeos"
                }
                onChange={(e) => setFiles([...files, ...e.target.files])}
                accept="video/mp4,video/x-m4v,video/*"
                multiple
              />
              {files.length === 0 && (
                <h4 className="mt-4 mb-2 text-center">
                  <RiVideoLine
                    className="d-block"
                    size={80}
                    style={{ margin: "0 auto" }}
                  />
                  Nenhum vídeo selecionado
                </h4>
              )}
              <div
                style={{
                  display: "grid",
                  gridTemplateColumns: "1fr",
                  gridGap: "16px",
                  marginTop: "20px",
                }}
              >
                {video.courseId &&
                  files.length > 0 &&
                  files.map((file, index) => (
                    <SendVideo
                      file={file}
                      key={index}
                      onRemove={() =>
                        setFiles(files.filter((f, i) => i !== index))
                      }
                      uploaded={uploaded.find((u) => u === index)}
                      onUpload={(e) => {
                        setUploaded([...uploaded, index]);
                        setVideos([...videos, e]);
                      }}
                      courseId={video.courseId}
                    />
                  ))}
              </div>
            </>
          )}
        </div>
      </div>
    </Content>
  );
}

export default ModalSendVideo;
