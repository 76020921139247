import React, { useState, useEffect } from "react";

import TableCourse from "./TableCourse";
import AddCourse from "./AddCourse";
import EditCourse from "./EditCourse";
import axios from "axios";

import { MdWarning } from "react-icons/md";
import { Modal, Alert } from "react-bootstrap";

function Courses({ product_id }) {
  const [loading, setLoading] = useState(false);
  const [msg, setMsg] = useState("");
  const [courses, setCourse] = useState([]);
  const [edit, setEdit] = useState({
    active: false,
    id: null,
  });
  const [deleteCourse, setDeleteCourse] = useState({
    active: false,
    course: {},
  });
  const [addCourse, setAddCourse] = useState(false);

  async function loadCourse() {
    const { data } = await axios.get(`/products/${product_id}/courses`);
    setCourse(data);
  }

  useEffect(() => {
    loadCourse();
  }, []); // eslint-disable-line

  async function handleDelete() {
    setMsg("");
    setLoading(true);
    try {
      await axios.delete(
        `/products/${product_id}/courses/${deleteCourse.course.id}`
      );
      setCourse(courses.filter((u) => u.id !== deleteCourse.course.id));
      setDeleteCourse({
        active: false,
        course: {},
      });
    } catch (err) {
      setMsg("Ocorreu um erro em nossos servidores, contate a equipe técnica");
    }
    setLoading(false);
  }

  return (
    <div id="container">
      <Modal
        show={deleteCourse.active}
        onHide={() =>
          setDeleteCourse({
            active: false,
            course: {},
          })
        }
        centered
      >
        <Modal.Body>
          <div className="text-center">
            <MdWarning style={{ fontSize: "80px" }} />
          </div>
          {msg !== "" && <Alert variant="danger">{msg}</Alert>}
          <h4 className="text-center">
            Você tem certeza que deseja excluir o produto{" "}
            <strong>{deleteCourse.course.title}</strong>?
          </h4>
          <div className="text-center">
            <button
              type="button"
              className="btn btn-outline-danger"
              disabled={loading}
              onClick={handleDelete}
            >
              Sim, excluir
            </button>
            <button
              type="button"
              className="btn btn-danger ml-2"
              disabled={loading}
              onClick={() =>
                setDeleteCourse({
                  active: false,
                  course: {},
                })
              }
            >
              Cancelar
            </button>
          </div>
        </Modal.Body>
      </Modal>
      {!edit.active && !addCourse && (
        <TableCourse
          product_id={product_id}
          courses={courses}
          addCourse={() => setAddCourse(true)}
          editCourse={(e) =>
            setEdit({
              active: true,
              id: e,
            })
          }
          onDelete={(e) =>
            setDeleteCourse({
              active: true,
              course: e,
            })
          }
        />
      )}
      {addCourse && (
        <AddCourse
          product_id={product_id}
          onAdd={() => loadCourse()}
          onClose={() => setAddCourse(false)}
        />
      )}
      {edit.active && (
        <EditCourse
          product_id={product_id}
          onUpdate={() => loadCourse()}
          onClose={() =>
            setEdit({
              active: false,
              id: null,
            })
          }
          id={edit.id}
        />
      )}
    </div>
  );
}

export default Courses;
