export default function statusVideo(status_id) {
  let status = "";
  switch (status_id) {
    case 1:
      status = "Erro ao importar";
      break;
    case 2:
      status = "Erro ao converter";
      break;
    case 3:
      status = "Importar";
      break;
    case 4:
      status = "Convertendo";
      break;
    case 5:
      status = "Pronto";
      break;
    case 6:
      status = "Excluído";
      break;
    case 7:
      status = "Pendente";
      break;
    case 8:
      status = "Moderado";
      break;
    case 9:
      status = "Bloqueado";
      break;
    case 10:
      status = "Sem conteúdo";
      break;
    default:
      status = "Enviando";
      break;
  }
  return status;
}
