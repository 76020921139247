import React, { useState } from 'react';

import { Content } from './styles';

import Users from './components/Users';
import UserHeader from '../components/SubHeader';

function User() {
  const [current, ] = useState(1);
  return (
      <Content>
        <UserHeader />
        <div id="main_content">
          <h2>Usuários</h2>
          {current === 1 && <Users />}
        </div>
      </Content>
  );
}

export default User;