import React, { useState, useEffect } from "react";
import axios from "axios";
import { Modal, Button } from "react-bootstrap";

import { MdContentCopy } from "react-icons/md";

function ModalEmbed({ entry_id, open, setOpen }) {
  const randomId = Math.round(Math.random() * 1000001);
  const [clipboard, setClipboard] = useState(false);
  function copyToClipBoard() {
    var copyText = document.getElementById("embed_textarea");
    copyText.select();
    copyText.setSelectionRange(0, 99999);
    document.execCommand("copy");
    setClipboard(true);
  }

  const [company, setCompany] = useState(null);

  useEffect(() => {
    async function loadUser() {
      try {
        const { data: dataUser } = await axios.get(`/me`);
        setCompany(dataUser.company);
      } catch (err) {}
    }

    loadUser();
  }, []);
  return (
    <Modal show={open} onHide={() => setOpen(false)} size="lg" centered>
      <Modal.Body>
        {company && (
          <textarea
            value={`
<div id="flix_player_${randomId}" style="width: 560px;height: 395px"></div>
<script src="https://admin.flix.tupi.io/${
              company.player
                ? `embed_player_${company.player}.min.js`
                : "embed_player.min.js"
            }"></script>
<script>
  try {
    var flixPlayer = FlixPlayer.setup({
      targetId: "flix_player_${randomId}",
      provider: {
        partnerId: 2601552,
        uiConfId: ${company.player ? company.player : "47235343"}
      }
    });
    flixPlayer.loadMedia({ entryId: '${entry_id}' });
  }catch(err) {
    console.log(err);
  }
</script>
        `}
            readOnly
            rows="15"
            style={{
              width: "100%",
              background: "#ececec",
              padding: "0px 30px",
              resize: "none",
              borderColor: "#017bfe",
              borderRadius: "6px",
              color: "#00356d",
            }}
            id="embed_textarea"
          ></textarea>
        )}
        <hr />
        <div className="d-flex">
          <Button
            variant={clipboard ? "outline-secondary" : "primary"}
            onClick={copyToClipBoard}
          >
            <MdContentCopy /> {clipboard ? "Copiado" : "Copiar"}
          </Button>
          <Button
            variant="outline-primary"
            className="ml-2"
            onClick={() => setOpen(false)}
          >
            Fechar
          </Button>
        </div>
      </Modal.Body>
    </Modal>
  );
}

export default ModalEmbed;
