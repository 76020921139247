import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { Table } from "react-bootstrap";
import axios from "axios";
import statusVideo from "../../../../utils/statusVideo";
import statusVideoColor from "../../../../utils/statusVideoColor";
import { FaAngleUp, FaAngleDown } from "react-icons/fa";
import { useTranslation } from "react-i18next";

function TableUsers({
  videos,
  addVideo,
  loading,
  editVideo,
  onDelete,
  product_id,
  course_id,
  upOrder,
  downOrder,
  categories,
}) {
  const history = useHistory();

  const [company, setCompany] = useState(null);
  const { t } = useTranslation();
  useEffect(() => {
    async function loadUser() {
      try {
        const { data: dataUser } = await axios.get(`/me`);
        setCompany(dataUser.company);
      } catch (err) {}
    }

    loadUser();
  }, []);

  return (
    <>
      <button
        type="button"
        onClick={() =>
          history.push(
            `/product/${company.product_id}/course/${company.course_id}/add-video`
          )
        }
      >
        {t("Novo Vídeo")}
      </button>
      <Table responsive hover>
        <thead>
          <tr>
            <th>{t("Ordem")}</th>
            <th>{t("Thumbnail")}</th>
            <th>{t("Status")}</th>
            <th>{t("Título")}</th>
            <th>{t("Descrição")}</th>
            <th>{t("Categoria")}</th>
            <th className="text-right">{t("Ações")}</th>
          </tr>
        </thead>
        <tbody>
          {videos &&
            videos.map((video, index) => (
              <tr key={video.id}>
                <td>
                  {videos[index - 1] && (
                    <button
                      type="button"
                      className="btnOrder"
                      disabled={loading}
                      onClick={() => upOrder(video.id, videos[index - 1].id)}
                    >
                      <FaAngleUp />
                    </button>
                  )}
                  {videos[index + 1] && (
                    <button
                      type="button"
                      className="btnOrder"
                      disabled={loading}
                      onClick={() => downOrder(video.id, videos[index + 1].id)}
                    >
                      <FaAngleDown />
                    </button>
                  )}
                </td>
                <td>
                  <div className="thumb">
                    <img src={video.thumbnail} alt={video.title} />
                  </div>
                </td>
                <td>
                  <span
                    className={`btn-sm btn-${statusVideoColor(
                      String(video.video_status_id)
                    )}`}
                  >
                    {t(statusVideo(video.video_status_id))}
                  </span>
                </td>
                <td>{video.title}</td>
                <td>{video.description}</td>
                <td>{video.category ? video.category.name : ""}</td>
                <td>
                  <div className="d-flex justify-content-end">
                    <button type="button" onClick={() => editVideo(video.id)}>
                      {t("Editar")}
                    </button>
                    <button type="button" onClick={() => onDelete(video)}>
                      {t("Excluir")}
                    </button>
                  </div>
                </td>
              </tr>
            ))}
        </tbody>
      </Table>
    </>
  );
}

export default TableUsers;
