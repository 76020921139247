import React, { useState, useEffect } from "react";

import axios from "axios";

import { Modal, Card, Button, Form } from "react-bootstrap";

function ModalAddRegistration({ open, setOpen, onUpdate, customer_id }) {
  const [step, setStep] = useState(1);
  const [product, setProduct] = useState({});
  const [products, setProducts] = useState([]);

  const [loading, setLoading] = useState(false);

  const [reason, setReason] = useState("");

  function resetForm() {
    setStep(1);
    setProduct({});
    setOpen(false);
  }

  useEffect(() => {
    async function loadProducts() {
      try {
        const { data } = await axios.get("/products");
        setProducts(data);
      } catch (err) {
        console.log(err);
      }
    }
    loadProducts();
  }, []);

  async function handleAdd() {
    setLoading(true);
    try {
      await axios.post(`enrollments`, {
        product_id: product.id,
        customer_id,
        reason,
      });
      onUpdate();
      resetForm();
    }catch(err) {
      console.log(err);
    }
    setLoading(false);
  }

  return (
    <Modal show={open} onHide={resetForm} backdrop="static" centered size="lg">
      <Modal.Header closeButton />
      <Modal.Body>
        {step === 1 && (
          <div>
            <h4 className="text-center">Escolha um produto</h4>
            <hr />
            <div>
              {products.map((p) => (
                <Card style={{ width: "100%" }} className="mb-3">
                  <Card.Body>
                    <Card.Title>{p.title}</Card.Title>
                    <Card.Text>{p.description}</Card.Text>
                    <Button
                      variant="primary"
                      onClick={() => {
                        setProduct(p);
                        setStep(2);
                      }}
                    >
                      Escolher
                    </Button>
                  </Card.Body>
                </Card>
              ))}
            </div>
          </div>
        )}
        {step === 2 && (
          <div>
            <h5 className="text-center">
              Para adicionar a matrícula do produto{" "}
              <i>
                <strong>{product.title}</strong>
              </i>{" "}
              digite o motivo para a adição
            </h5>
            <Form.Control
              as="textarea"
              rows={3}
              style={{ reSize: "none" }}
              value={reason}
              onChange={(e) => setReason(e.target.value)}
              placeholder="Motivo aqui..."
            />
            <hr />
            <div className="d-flex justify-content-center align-items-center">
              <Button
                variant="primary"
                onClick={handleAdd}
                disabled={reason === "" || loading}
              >
                Adicionar
              </Button>
              <Button
                className="ml-2"
                variant="secondary"
                onClick={() => {
                  setProduct({});
                  setStep(1);
                }}
              >
                Voltar
              </Button>
            </div>
          </div>
        )}
      </Modal.Body>
    </Modal>
  );
}

export default ModalAddRegistration;
