import React from 'react';

import { ThemeProvider } from 'styled-components';
import { Switch, BrowserRouter, Redirect } from 'react-router-dom';

import { useSelector } from 'react-redux';

import AuthPage from './pages/auth/AuthPage';
import MainPage from './pages/main/MainPage';

function Routes() {
  const { signed } = useSelector(state => state.auth);
  const theme = useSelector(state => state.theme);
  return (
    <ThemeProvider theme={{
      background: theme.background,
      primary: theme.primary,
      secondary: theme.secondary,
    }}>
      <BrowserRouter>
        <Switch>
          {!signed ? (<AuthPage />) : (<MainPage />)}
          {!signed ? <Redirect to="/" /> : <Redirect to="/dashboard" />}
        </Switch>
      </BrowserRouter>
    </ThemeProvider>
  );
}

export default Routes;