import React from "react";

import { Table } from "react-bootstrap";
import { format } from "date-fns";
import { normalizeCurrency } from "../../../../utils/normalizeCurrency";

function TablePayments({ payments }) {
  return (
    <Table id="table_users">
      <thead>
        <tr>
          <th>Cliente</th>
          <th>Produto</th>
          <th>Valor</th>
          <th>Status</th>
          <th className="text-right">Data</th>
        </tr>
      </thead>
      <tbody>
        {payments &&
          payments.map((payment) => (
            <tr key={payment.id}>
              <td>{payment.customer.name}</td>
              <td>
                {payment.enrollment ? payment.enrollment.product.title : "-"}
              </td>
              <td>R$ {normalizeCurrency(payment.value)}</td>
              <td>{payment.status.name}</td>
              <td className="text-right">
                {format(new Date(payment.created_at), "dd/MM/yyyy - HH:mm")}
              </td>
            </tr>
          ))}
      </tbody>
    </Table>
  );
}

export default TablePayments;

