import React, { useState, useEffect } from 'react';

import TableRoles from './TableRoles';
import AddRole from './AddRole';
import EditRole from './EditRole';
import axios from 'axios';

import { MdWarning } from 'react-icons/md';
import { Modal, Alert } from 'react-bootstrap';

function Roles() {
  const [loading, setLoading] = useState(false);
  const [msg, setMsg] = useState('');
  const [roles, setRoles] = useState([]);
  const [edit, setEdit] = useState({
    active: false,
    id: null,
  });
  const [deleteRole, setDeleteRole] = useState({
    active: false,
    role: {},
  });
  const [addRole, setAddRole] = useState(false); 

  async function loadRoles() {
    const { data } = await axios.get('/roles');
    setRoles(data);
  }

  useEffect(() => {
    loadRoles();
  }, []); // eslint-disable-line

  async function handleDelete() {
    setMsg('');
    setLoading(true);
    try {
      await axios.delete(`/roles/${deleteRole.role.id}`);
      setRoles(roles.filter(u => u.id !== deleteRole.role.id));
      setDeleteRole({
        active: false,
        role: {},
      });
    }catch(err) {
      setMsg('Ocorreu um erro em nossos servidores, contate a equipe técnica')
    }
    setLoading(false);
  }

  return (
    <div id="role_container">
      <Modal show={deleteRole.active} onHide={() => setDeleteRole({
        active: false,
        role: {},
      })} centered>
        <Modal.Body>
          <div className="text-center">
            <MdWarning style={{ fontSize: '80px' }}/>
          </div>
          {msg !== '' && (
            <Alert variant="danger">
              {msg}
            </Alert>
          )}
          <h4 className="text-center">
            Você tem certeza que deseja excluir o perfil <strong>{deleteRole.role.name}</strong>?
          </h4>
          <div className="text-center">
            <button type="button" className="btn btn-outline-danger" disabled={loading} onClick={handleDelete}>
              Sim, excluir
            </button>
            <button type="button" className="btn btn-danger ml-2" disabled={loading} onClick={() => setDeleteRole({
                active: false,
                role: {},
              })}>
              Cancelar
            </button>
          </div>
        </Modal.Body>
      </Modal>
      {!edit.active && !addRole && (
        <TableRoles roles={roles} addRole={() => setAddRole(true)} editRole={(e) => setEdit({
          active: true,
          id: e,
        })} onDelete={(e) => setDeleteRole({
          active: true,
          role: e,
        })}/>
      )}
      {addRole && (
        <AddRole onAdd={() => loadRoles()} onClose={() => setAddRole(false)}/>
      )}
      {edit.active && (
        <EditRole onUpdate={() => loadRoles()} onClose={() => setEdit({
          active: false,
          id: null,
        })} id={edit.id}/>
      )}
    </div>
  );
}

export default Roles;