import produce from 'immer';

const INITIAL_STATE = {
  background: '',
  primary: '',
  secondary: '',
  logo: '',
  type: '',
  companyId: '',
};

export default function auth(state = INITIAL_STATE, action) {
  return produce(state, (draft) => {
    switch (action.type) {
      case '@auth/SIGN_IN_SUCCESS': {
        draft.background = action.payload.theme.background;
        draft.primary = action.payload.theme.primary;
        draft.secondary = action.payload.theme.secondary;
        draft.logo = action.payload.theme.logo;
        draft.type = action.payload.theme.type;
        draft.companyId = action.payload.theme.companyId;
        break;
      }
      default:
    }
  });
}
