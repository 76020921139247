export const normalizeCurrency = (valueNumber) => {
  const stringOfNumber = String(valueNumber);
  if(stringOfNumber.length === 2) {
    return `0,${stringOfNumber[0] || 0}${stringOfNumber[1] || 0}`;
  }else if (stringOfNumber.length === 1) {
    return `0,0${stringOfNumber[0] || 0}`;
  }
  let cents = stringOfNumber[stringOfNumber.length-2] + stringOfNumber[stringOfNumber.length-1];
  let reals = stringOfNumber.slice(0, stringOfNumber.length - 2);
  let pointValue = "";

  let arrayNumber = reals.split("");
  arrayNumber.reverse();
  arrayNumber.forEach((value, index) => {
    if((index + 1)%3 === 0) {
      pointValue = value + pointValue;
    }else{
      pointValue = value + pointValue;
    }
  });
  return pointValue + "," + cents;
}