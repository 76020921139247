import React, { useState, useEffect } from "react";

import TableVideo from "./TableVideo";
import AddVideo from "./AddVideo";
import EditVideo from "./EditVideo";
import axios from "axios";

import { MdWarning } from "react-icons/md";
import { Modal, Alert } from "react-bootstrap";

import { useTranslation } from "react-i18next";

function Video({ product_id, course_id }) {
  const [loading, setLoading] = useState(false);
  const [msg, setMsg] = useState("");
  const [videos, setVideos] = useState([]);
  const [page] = useState(1);
  const [search, setSearch] = useState("");
  const [edit, setEdit] = useState({
    active: false,
    id: null,
  });
  const [deleteVideo, setDeleteVideo] = useState({
    active: false,
    video: {},
  });
  const [addVideo, setAddVideo] = useState(false);
  const [categories, setCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState(null);

  const { t } = useTranslation();

  async function loadVideo(page, search, selectedCategory) {
    setSearch(search);
    const { data } = await axios.get(
      `/vpass/videos?page=${page || 1}&search=${search}&category_id=${
        selectedCategory || ""
      }`
    );
    let entry_ids = [];
    for (let i = 0; i < data.data.length; i++) {
      const video = data.data[i];
      if (!video.entry_id) {
        console.log("skipping", video);
        continue;
      }
      if (
        !video.video_status_id ||
        video.video_status_id === 3 ||
        video.video_status_id === 4 ||
        video.video_status_id === 7 ||
        video.video_status_id === 10
      ) {
        entry_ids.push(video.entry_id);
      }
    }
    if (entry_ids.length > 0) {
      try {
        await axios.post(`kaltura/status`, {
          entry_ids,
        });
        console.log("entry_ids", entry_ids.length);
        console.log(1);
        const { data: dataVideos } = await axios.get(
          `courses/${course_id}/videos`
        );
        console.log(course_id);
        setVideos(dataVideos);
      } catch (error) {
        console.log("loadVideo -> error", error);
        setVideos(data);
      }
    } else {
      setVideos(data);
    }
  }

  useEffect(() => {
    loadVideo(1, "");
  }, []); // eslint-disable-line

  async function loadCategories() {
    const { data } = await axios.get("/categories");
    setCategories(data);
  }

  useEffect(() => {
    loadCategories();
  }, []); // eslint-disable-line

  async function handleDelete() {
    setMsg("");
    setLoading(true);
    try {
      await axios.delete(`vpass/videos/${deleteVideo.video.id}`);
      setVideos(videos.data.filter((u) => u.id !== deleteVideo.video.id));
      setDeleteVideo({
        active: false,
        video: {},
      });
    } catch (err) {
      setMsg("Ocorreu um erro em nossos servidores, contate a equipe técnica");
    }
    setLoading(false);
  }

  async function upOrder(id, changeId) {
    try {
      setLoading(true);
      await axios.put(`videos/order/${id}/${changeId}`);
      loadVideo(1, "");
      setLoading(false);
    } catch (error) {}
  }

  async function downOrder(id, changeId) {
    try {
      setLoading(true);
      await axios.put(`videos/order/${id}/${changeId}`);
      loadVideo(1, "");
      setLoading(false);
    } catch (error) {}
  }

  return (
    <div id="container">
      <Modal
        show={deleteVideo.active}
        onHide={() =>
          setDeleteVideo({
            active: false,
            video: {},
          })
        }
        centered
      >
        <Modal.Body>
          <div className="text-center">
            <MdWarning style={{ fontSize: "80px" }} />
          </div>
          {msg !== "" && <Alert variant="danger">{msg}</Alert>}
          <h4 className="text-center">
            {t("Você tem certeza que deseja excluir o produto")}{" "}
            <strong>{deleteVideo.video.title}</strong>?
          </h4>
          <div className="text-center">
            <button
              type="button"
              className="btn btn-outline-danger"
              disabled={loading}
              onClick={handleDelete}
            >
              {t("Sim, excluir")}
            </button>
            <button
              type="button"
              className="btn btn-danger ml-2"
              disabled={loading}
              onClick={() =>
                setDeleteVideo({
                  active: false,
                  video: {},
                })
              }
            >
              {t("Cancelar")}
            </button>
          </div>
        </Modal.Body>
      </Modal>
      {!edit.active && !addVideo && (
        <>
          <div className="formSearch">
            <select
              className="form-select p-1 mr-2"
              aria-label="Selecionar categoria"
              onChange={(event) => {
                setSelectedCategory(event.target.value);
                loadVideo(page, search, event.target.value);
              }}
            >
              <option selected value={""}>
                Selecionar categoria
              </option>

              {categories.map((category) => (
                <option value={category.id}>{category.name}</option>
              ))}
            </select>
            <input
              placeholder={t("Digite o título ou Entry_id")}
              onChange={(e) => setSearch(e.target.value)}
            />
            <button onClick={() => loadVideo(page, search, selectedCategory)}>
              {t("Pesquisar")}
            </button>
          </div>
          <TableVideo
            videos={videos.data}
            product_id={product_id}
            course_id={course_id}
            loading={loading}
            upOrder={(id, changeId) => upOrder(id, changeId)}
            downOrder={(id, changeId) => downOrder(id, changeId)}
            addVideo={() => setAddVideo(true)}
            editVideo={(e) =>
              setEdit({
                active: true,
                id: e,
              })
            }
            onDelete={(e) =>
              setDeleteVideo({
                active: true,
                video: e,
              })
            }
          />
          <div className="table-footer">
            <span>{videos.total} Total</span>
            <ul className="paginate">
              {videos.page - 1 > 0 && (
                <li onClick={() => loadVideo(videos.page - 1, search)}>
                  <button>{videos.page - 1}</button>
                </li>
              )}
              <li className="active">
                <button>{videos.page}</button>
              </li>
              {videos.page + 1 < videos.lastPage && (
                <li onClick={() => loadVideo(videos.page + 1, search)}>
                  <button>{videos.page + 1}</button>
                </li>
              )}
              {videos.lastPage !== videos.page && <li>...</li>}
              {videos.lastPage !== videos.page && (
                <li>
                  <button onClick={() => loadVideo(videos.lastPage, search)}>
                    {videos.lastPage}
                  </button>
                </li>
              )}
            </ul>
          </div>
        </>
      )}
      {addVideo && (
        <AddVideo
          product_id={product_id}
          course_id={course_id}
          onAdd={() => loadVideo()}
          onClose={() => setAddVideo(false)}
        />
      )}
      {edit.active && (
        <EditVideo
          product_id={product_id}
          course_id={course_id}
          onUpdate={() => loadVideo()}
          onClose={() =>
            setEdit({
              active: false,
              id: null,
            })
          }
          id={edit.id}
        />
      )}
    </div>
  );
}

export default Video;
