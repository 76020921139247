import React, { useState, useEffect } from "react";

import axios from "axios";
import { MdClose } from 'react-icons/md';
import { RiVideoLine } from 'react-icons/ri';
import { ProgressBar, Button } from "react-bootstrap";

function SendVideo({ file, onRemove, onUpload, courseId, uploaded }) {
  const [progress, setProgress] = useState(uploaded ? 100 : 0);
  const [error, setError] = useState("");
  const [success, setSuccess] = useState(uploaded ? true : false);

  async function uploadFile() {
    try {
      setProgress(0);
      setError('');
      const formData = new FormData();
      formData.append("path", file);
      formData.append("course_id", courseId);

      const { data } = await axios.post(`/video`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
        onUploadProgress: (e) => {
          setProgress(Math.round((100 * e.loaded) / e.total));
        },
      });
      onUpload(data);
      setSuccess(true);
    } catch (err) {
      if (err.response && err.response.status === 500) {
        setError(
          "Ocorreu um erro em nossos servidores, contate a equipe técnica"
        );
      } else if (err.response && err.response.status === 400) {
        setError("Erro ao fazer upload do arquivo, tente novamente.");
      } else if (err.response && err.response.status === 404) {
        setError("Arquivo não encontrado");
      } else {
        setError(
          "Ocorreu um erro em nossa aplicação, contate a equipe técnica"
        );
      }
    }
  }

  useEffect(() => {
    if (file && progress === 0 && !uploaded) {
      uploadFile();
    }
  }, [file]); // eslint-disable-line

  const reduceTitle = (title) => {
    if (title.length > 12) {
      return `${title.slice(0, 12)}...`;
    } else {
      return title;
    }
  };
  const fileSize = (size) => {
    if (size < 1024) {
      return `${size} B`;
    } else if (size > 1024 && size < 1024000) {
      return `${Math.round(size / 1024)} KB`;
    } else if (size > 1024000 && size < 1024000000) {
      return `${Math.round(size / 1024 / 1024)} MB`;
    } else {
      return `${Math.round(size / 1024 / 1024 / 1024)} GB`;
    }
  };

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "flex-start",
        padding: "16px",
        background: "#f9f9f9",
        border: "1px solid #c9c9c9",
      }}
    >
      <div className="d-flex flex-column justify-content-center align-items-center">
        <div
          style={{
            width: "100px",
            height: "60px",
            background: "#c9c9c9",
            borderRadius: "2px",
            color: '#fff',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
          }}
        >
          <RiVideoLine size={30}/>
        </div>
        <small>{reduceTitle(file.name)}</small>
        <small>
          {fileSize(file.size)} | {file.type.split("/")[1].toUpperCase()}
        </small>
      </div>
      <div
        className="d-flex flex-column justify-content-center align-items-center"
        style={{ width: "90%" }}
      >
        <ProgressBar
          animated={error !== '' ? false : (!success)}
          now={progress}
          variant={
            error !== '' ? "danger" : (progress === 100 ? "success" : "primary")
          }
          style={{ width: "90%" }}
          label={error !== '' ? 'ERRO' : (success ? 'ENVIADO' : `${progress}%`)}
        />
        <small className="text-danger d-block mt-2">
          {error}
        </small>
        {error !== '' && (
          <Button type="button" variant="outline-secondary" size="sm" className="mt-2" onClick={() => uploadFile()}>
            Tentar Novamente
          </Button>
        )}
      </div>
      {(progress < 100 || error !== '') && (
        <button
        type="button"
        style={{
          width: "24px",
          height: "24px",
          borderRadius: "50%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          background: 'none',
          border: 'none'
        }}
        onClick={() => onRemove()}
      >
        <MdClose />
      </button>
      )}
      
    </div>
  );
}

export default SendVideo;
