import React from "react";

import { FaUser, FaEdit } from "react-icons/fa";
import { useSelector, useDispatch } from "react-redux";

import { Container } from "./styles";

import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";

function SubHeader() {
  const { user } = useSelector((state) => state.user);
  const history = useHistory();
  const { i18n } = useTranslation();
  const translation = useSelector((state) => state.translation);
  const dispatch = useDispatch();

  const onChangeLanguage = (e) => {
    const { value } = e.target;

    i18n.changeLanguage(value);
    dispatch({ type: "CHANGE_LANGUAGE", language: value });
  };

  return (
    <Container>
      <select
        onChange={onChangeLanguage}
        class="selectpicker"
        data-width="fit"
        style={{ marginRight: 10 }}
        defaultValue={translation.language}
      >
        <option value="pt">Português</option>
        <option value="en">English</option>
      </select>
      <div>
        <FaUser className="avatar_user" />
        <span>{user.username}</span>
      </div>
      <button type="button" onClick={() => history.push("/profile")}>
        <FaEdit />
      </button>
    </Container>
  );
}

export default SubHeader;
