import React, { useState, useEffect } from "react";

import TableVideo from "./TableVideo";
import AddVideo from "./AddVideo";
import EditVideo from "./EditVideo";
import axios from "axios";

import { MdWarning } from "react-icons/md";
import { Modal, Alert } from "react-bootstrap";

function Video({ product_id, course_id }) {
  const [loading, setLoading] = useState(false);
  const [msg, setMsg] = useState("");
  const [videos, setVideos] = useState([]);
  const [edit, setEdit] = useState({
    active: false,
    id: null,
  });
  const [deleteVideo, setDeleteVideo] = useState({
    active: false,
    video: {},
  });
  const [addVideo, setAddVideo] = useState(false);

  async function loadVideo() {
    const { data } = await axios.get(`courses/${course_id}/videos`);
    let entry_ids = [];
    for (let i = 0; i < data.length; i++) {
      const video = data[i];
      if (
        !video.video_status_id ||
        video.video_status_id === 3 ||
        video.video_status_id === 4 ||
        video.video_status_id === 7 ||
        video.video_status_id === 10
      ) {
        entry_ids.push(video.entry_id);
      }
    }
    if (entry_ids.length > 0) {
      try {
        await axios.post(`kaltura/status`, {
          entry_ids,
        });
        console.log("entry_ids", entry_ids.length);
        console.log(1);
        const { data: dataVideos } = await axios.get(
          `courses/${course_id}/videos`
        );
        setVideos(dataVideos);
      } catch (error) {
        console.log("loadVideo -> error", error);
        setVideos(data);
      }
    } else {
      console.log("loadVideo -> data", data);
      setVideos(data);
    }
  }

  useEffect(() => {
    loadVideo();
  }, []); // eslint-disable-line

  async function handleDelete() {
    setMsg("");
    setLoading(true);
    try {
      await axios.delete(`courses/${course_id}/videos/${deleteVideo.video.id}`);
      setVideos(videos.filter((u) => u.id !== deleteVideo.video.id));
      setDeleteVideo({
        active: false,
        video: {},
      });
    } catch (err) {
      setMsg("Ocorreu um erro em nossos servidores, contate a equipe técnica");
    }
    setLoading(false);
  }

  async function upOrder(id, changeId) {
    try {
      setLoading(true);
      await axios.put(`videos/order/${id}/${changeId}`);
      loadVideo();
      setLoading(false);
    } catch (error) {}
  }

  async function downOrder(id, changeId) {
    try {
      setLoading(true);
      await axios.put(`videos/order/${id}/${changeId}`);
      loadVideo();
      setLoading(false);
    } catch (error) {}
  }

  return (
    <div id="container">
      <Modal
        show={deleteVideo.active}
        onHide={() =>
          setDeleteVideo({
            active: false,
            video: {},
          })
        }
        centered
      >
        <Modal.Body>
          <div className="text-center">
            <MdWarning style={{ fontSize: "80px" }} />
          </div>
          {msg !== "" && <Alert variant="danger">{msg}</Alert>}
          <h4 className="text-center">
            Você tem certeza que deseja excluir o produto{" "}
            <strong>{deleteVideo.video.title}</strong>?
          </h4>
          <div className="text-center">
            <button
              type="button"
              className="btn btn-outline-danger"
              disabled={loading}
              onClick={handleDelete}
            >
              Sim, excluir
            </button>
            <button
              type="button"
              className="btn btn-danger ml-2"
              disabled={loading}
              onClick={() =>
                setDeleteVideo({
                  active: false,
                  video: {},
                })
              }
            >
              Cancelar
            </button>
          </div>
        </Modal.Body>
      </Modal>
      {!edit.active && !addVideo && (
        <TableVideo
          videos={videos}
          product_id={product_id}
          course_id={course_id}
          loading={loading}
          upOrder={(id, changeId) => upOrder(id, changeId)}
          downOrder={(id, changeId) => downOrder(id, changeId)}
          addVideo={() => setAddVideo(true)}
          editVideo={(e) =>
            setEdit({
              active: true,
              id: e,
            })
          }
          onDelete={(e) =>
            setDeleteVideo({
              active: true,
              video: e,
            })
          }
        />
      )}
      {addVideo && (
        <AddVideo
          product_id={product_id}
          course_id={course_id}
          onAdd={() => loadVideo()}
          onClose={() => setAddVideo(false)}
        />
      )}
      {edit.active && (
        <EditVideo
          product_id={product_id}
          course_id={course_id}
          onUpdate={() => loadVideo()}
          onClose={() =>
            setEdit({
              active: false,
              id: null,
            })
          }
          id={edit.id}
        />
      )}
    </div>
  );
}

export default Video;
