import styled from "styled-components";

export const Content = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  #header_content {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    background: #f9f9f9;
    padding: 20px 30px;
    border-bottom: 1px solid #f0f0f0;
    div {
      padding: 10px;
      border-radius: 6px;
      background: #e9e9e9;
      color: ${(props) => props.theme.primary};
      .avatar_user {
        padding: 5px;
        border-radius: 6px;
        margin-right: 10px;
        background: ${(props) => props.theme.primary};
        color: #fff;
        font-size: 30px;
      }
    }
  }
  #main_content {
    padding: 40px;
    @media screen and (max-width: 600px) {
      & {
        padding: 15px;
      }
    }
    display: flex;
    flex-direction: column;
    flex: 1;
    background: #fff;
    overflow: auto;
    #file_image {
      margin-bottom: 20px;
      label {
        width: 400px;
        height: 225px;
        padding: 0px;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: 50% 50%;
        border: 2px dashed ${(props) => props.theme.primary};
        margin-bottom: 16px;
        cursor: pointer;
        h4 {
          width: 100%;
          min-height: 200px;
          transition: 400ms;
          display: flex;
          margin: 0px;
          justify-content: center;
          align-items: center;
          &:hover {
            background-color: rgba(0, 0, 0, 0.8);
            color: #fff;
          }
        }
      }
    }
    #sub_menu {
      border-top: 1px solid ${(props) => props.theme.primary};
      margin-top: 15px;
      button {
        background: #f9f9f9;
        border: 1px solid ${(props) => props.theme.primary};
        border-top: none;
        color: ${(props) => props.theme.primary};
        padding: 10px 15px;
        border-radius: 0px 0px 10px 10px;
        transition: 300ms;
        &:hover {
          background: ${(props) => props.theme.primary};
          color: #fff;
        }
        &.active_button {
          background: ${(props) => props.theme.primary};
          color: #fff;
        }
      }
    }
    h2 {
      color: ${(props) => props.theme.primary};
    }
    #container {
      padding: 30px;
      min-height: 55vh;
      background: #eee;
      border-radius: 10px;
      margin-top: 15px;
      > button {
        background: #f9f9f9;
        padding: 5px 10px;
        border: 1px solid ${(props) => props.theme.primary};
        color: ${(props) => props.theme.primary};
        border-radius: 6px;
      }
      > a {
        border-color: #7d7d7d;
        color: #7d7d7d;
      }
      #add_container {
        background: #f9f9f9;
        margin-top: 10px;
        border-radius: 10px;
        padding: 30px;
        display: grid;
        grid-template-columns: 3fr 5fr;
        grid-gap: 20px;
        span#avatar {
          font-size: 110px;
          color: #444;
          display: flex;
          justify-content: center;
          align-items: flex-start;
          flex-direction: column;
        }
        @media screen and (max-width: 900px) {
          & {
            padding: 0;
            background: none;
            grid-template-columns: 1fr;
          }
          span#avatar {
            padding: 20px;
          }
        }
        > div {
          border: 1px solid #c9c9c9;
          padding: 20px;
        }
      }
      table {
        background: #f9f9f9;
        height: 88%;
        margin-top: 10px;
        border-radius: 10px;
        overflow: auto;
        padding: 30px;
        td,
        th {
          vertical-align: middle;
          div.thumb {
            width: 120px;
            height: auto;
            background-color: ${(props) => props.theme.primary};
            background-size: cover;
            background-position: 50% 50%;
            border-radius: 4px;
            img {
              max-width: 100%;
            }
          }
          padding: 15px;
          a {
            margin-left: 6px;
            border: 1px solid #155724;
            color: #155724;
            border-radius: 6px;
            padding: 5px 15px;
            transition: 300ms;
            text-decoration: none;
            &:hover {
              background: #155724;
              color: #fff;
            }
          }
          button:nth-child(1) {
            margin-left: 6px;
            border: 1px solid #400ff2;
            color: #400ff2;
            border-radius: 6px;
            padding: 5px 15px;
            transition: 300ms;
            &:hover {
              background: #400ff2;
              color: #fff;
            }
          }
          button:nth-child(2) {
            margin-left: 6px;
            border: 1px solid #f23d4c;
            color: #f23d4c;
            border-radius: 6px;
            padding: 5px 15px;
            transition: 300ms;
            &:hover {
              background: #f23d4c;
              color: #222;
            }
          }
        }
        thead {
          padding: 10px 15px;
          border-bottom: 1px solid #c9c9c9;
          transition: 400ms;
          &:hover {
            background: rgba(0, 0, 0, 0.1);
          }
          span {
            display: block;
            margin: auto 0px;
          }
          &:nth-child(1) {
            span:nth-child(3) {
              text-align: right;
            }
            &:hover {
              background: none;
            }
            margin-top: 0;
            border-bottom: 4px solid #222;
          }
          @media screen and (max-width: 900px) {
            & {
              grid-template-columns: 1fr;
            }
            span {
              text-align: center !important;
            }
            div {
              justify-content: center;
            }
          }
        }
      }
    }
    div.grid_items {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr 1fr;
      grid-gap: 30px;
      margin-top: 30px;
      @media screen and (max-width: 900px) {
        & {
          grid-template-columns: 1fr;
        }
      }
      .item {
        padding: 30px;
        border-radius: 4px;
        color: #444;
        border: 1px solid #c9c9c9;
        h4 {
          font-size: 20px;
          padding-bottom: 15px;
          margin-bottom: 15px;
          border-bottom: 1px solid #444;
        }
        div {
          background: #f9f9f9;
          padding: 20px;
          text-align: center;
          border-radius: 4px;
          color: ${(props) => props.theme.primary};
          svg {
            font-size: 80px;
            margin-bottom: 20px;
          }
          p {
            font-size: 12px;
          }
        }
      }
    }
    .btnOrder {
      background: transparent !important;
      border-color: transparent !important;
      color: #333 !important;
    }
  }
  .formSearch {
    float: right;
    input {
      background: #fff;
      padding: 5px 10px;
      border: 1px solid #c5c5c5;
      font-size: 14px;
      height: 35px;
    }
    button {
      background: #c5c5c5;
      color: #222;
      border: none;
      padding: 6px 20px;
      height: 35px;
    }
  }
  .table-footer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 10px 0 0;
    > span {
    }
    .paginate {
      list-style: none;
      display: flex;
      align-items: center;
      padding: 0;
      margin: 0;
      li {
        margin: 0 2.5px;
        button {
          border: 1px solid #c5c5c5;
          background: #fff;
          line-height: 1;
          padding: 7.5px 10px;
          border-radius: 5px;
        }
        &.active {
          button {
            background: #c5c5c5;
          }
        }
      }
    }
  }
`;
