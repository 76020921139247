import React from 'react';

import { Content } from './styles';

import UserHeader from '../components/SubHeader';
import Payment from './components/Payment';

function Register() {

  return (
      <Content>
        <UserHeader />
        <div id="main_content">
          <h2>Pagamentos</h2>
          <Payment />
        </div>
      </Content>
  );
}

export default Register;