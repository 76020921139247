import React, { useState } from 'react';

import { Content } from './styles';

import UserHeader from '../components/SubHeader';
import Roles from './components/Roles';

function Role() {
  const [current,] = useState(1);
  return (
      <Content>
        <UserHeader />
        <div id="main_content">
          <h2>Perfil</h2>
          {current === 1 && <Roles />}
        </div>
      </Content>
  );
}

export default Role;