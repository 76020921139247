import React, { useState } from 'react';

import { Content } from './styles';

import UserHeader from '../components/SubHeader';
import Product from './components/Product';

function Register() {
  const [menuActive] = useState(sessionStorage.getItem('menu_active'));
  return (
      <Content>
        {menuActive !== 'not' && (
          <UserHeader />
        )}
        <div id="main_content">
          <h2>Produtos</h2>
          <Product />
        </div>
      </Content>
  );
}

export default Register;