import styled from 'styled-components';

export const Container = styled.div`
  width:100%;
  display:flex;
  justify-content:flex-end;
  transition:500ms;
  &.opened {
    padding-left:190px;
  }
  @media screen and (max-width: 600px) {
    &.opened {
      padding-left:0px;
    }
  }
  @media screen and (max-width:900px) {
    & {
      grid-template-columns:1fr;
    }
  }
`; 
