export default function statusVideoColor(status_id) {
  if (
    status_id === "1" ||
    status_id === "2" ||
    status_id === "6" ||
    status_id === "9"
  ) {
    return 'danger'
  } else if (
    status_id === "3" ||
    status_id === "4" ||
    status_id === "7" ||
    status_id === "8" ||
    status_id === "10"
  ) {
    return 'info'
  } else if (status_id === "5") {
    return 'success'
  }
}
