import React from 'react';

import { Content } from './styles';

import UserHeader from '../components/SubHeader';

function Config() {
  return (
      <Content>
        <UserHeader />
        <div id="main_content">
          <h2>Configurações</h2>
        </div>
      </Content>
  );
}

export default Config;