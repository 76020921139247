import React, { useState, useEffect } from "react";

import axios from "axios";

import { FaUser } from "react-icons/fa";
import { Form, Alert } from "react-bootstrap";

import { normalizeCurrency } from "../utils";

function EditCoupon({ onClose, onUpdate, id }) {
  const [title, setTitle] = useState("");
  const [value, setValue] = useState(normalizeCurrency(0));
  const [code, setCode] = useState("");
  const [description, setDescription] = useState("");
  const [dateStart, setDateStart] = useState("");
  const [dateEnd, setDateEnd] = useState("");

  const [msgData, setMsgData] = useState({
    type: "",
    text: "",
  });
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    async function loadData() {
      const { data: dataCoupon } = await axios.get(`/coupons/${id}`);
      setTitle(dataCoupon.title);
      setValue(String((dataCoupon.value / 100)).replace(".",","));
      setCode(dataCoupon.code);
      setDescription(dataCoupon.description);
      setDateStart(dataCoupon.dateStart);
      setDateEnd(dataCoupon.dateEnd);
    }
    loadData();
  }, [id]);

  async function handleSubmitData(e) {
    e.preventDefault();
    setMsgData({
      type: "",
      text: "",
    });
    setLoading(true);
    try {
      const response = await axios.put(`/coupons/${id}`, {
        title,
        code,
        value: Number(value.replace(",","")),
        description,
        dateStart,
        dateEnd,
      });
      if (response.status === 203) {
        setMsgData({
          type: "danger",
          text: response.data.msg,
        });
      }
      setMsgData({
        type: "success",
        text: "Cupon de desconto atualizado",
      });
      onUpdate();
    } catch (err) {
      if (err.response && err.response.data && err.response.data.msg) {
        setMsgData({
          type: "danger",
          text: err.response.data.msg,
        });
      } else {
        setMsgData({
          type: "danger",
          text:
            "Ocorreu um erro em nossos servidores, contate a equipe técnica.",
        });
      }
    }
    setLoading(false);
  }

  return (
    <>
      <button type="button" disabled={loading} onClick={onClose}>
        Voltar
      </button>
      <div id="add_coupon_container">
        <span id="avatar">
          <FaUser />
        </span>
        <div>
          <Form onSubmit={handleSubmitData}>
            {(msgData.type !== "" || msgData.text !== "") && (
              <Alert variant={msgData.type}>{msgData.text}</Alert>
            )}
            <div className="row">
              <Form.Group className="col-md-6">
                <Form.Label>Título</Form.Label>
                <Form.Control
                  value={title}
                  onChange={(e) => setTitle(e.target.value)}
                  disabled={loading}
                />
              </Form.Group>
              <Form.Group className="col-md-6">
                <Form.Label>Descrição</Form.Label>
                <Form.Control
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
                  disabled={loading}
                  type="text"
                />
              </Form.Group>
            </div>
            <div className="row">
              <Form.Group className="col-md-6">
                <Form.Label>Código</Form.Label>
                <Form.Control
                  value={code}
                  onChange={(e) => setCode(e.target.value)}
                  disabled={loading}
                  type="text"
                />
              </Form.Group>
              <Form.Group className="col-md-6">
                <Form.Label>Valor</Form.Label>
                <Form.Control
                  value={value}
                  onChange={(e) =>
                    setValue(
                      normalizeCurrency(
                        Number(e.target.value.replace(/[^\d]/g, ""))
                      )
                    )
                  }
                  disabled={loading}
                />
              </Form.Group>
            </div>
            <div className="row">
              <Form.Group className="col-md-6">
                <Form.Label>Data de início</Form.Label>

                <Form.Control
                  value={dateStart}
                  onChange={(e) => setDateStart(e.target.value)}
                  disabled={loading}
                  type="date"
                />
              </Form.Group>
              <Form.Group className="col-md-6">
                <Form.Label>Data de expiração</Form.Label>
                <Form.Control
                  value={dateEnd}
                  onChange={(e) => setDateEnd(e.target.value)}
                  disabled={loading}
                  type="date"
                />
              </Form.Group>
            </div>
            <button type="submit" disabled={loading}>
              {loading ? "Salvando..." : "Salvar"}
            </button>
          </Form>
        </div>
      </div>
    </>
  );
}

export default EditCoupon;
