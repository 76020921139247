import React, { useState, useEffect } from "react";

import axios from "axios";

import { AiOutlineProfile } from "react-icons/ai";
import { Form, Alert, Spinner } from "react-bootstrap";

function EditUser({ onClose, onUpdate, id }) {
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [permissions, setPermissions] = useState([]);
  const [msgData, setMsgData] = useState({
    type: "",
    text: "",
  });
  const [permissionsOptions, setPermissionsOptions] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    async function loadPermissions() {
      setLoading(true);
      try {
        const { data } = await axios.get('/permissions');
        setPermissionsOptions(data);
      }catch(err) {
        setMsgData({ type: 'danger', text: err.message });
      }
      setLoading(false);
    }
    loadPermissions();
  }, []);

  useEffect(() => {
    async function loadData() {
      const { data: dataRole } = await axios.get(`/roles/${id}`);
      setName(dataRole.name);
      setDescription(dataRole.description);
      const permissionsArr = [];
      dataRole.permissions.forEach(p => {
        permissionsArr.push(p.id);
      });
      setPermissions(permissionsArr);
    }
    loadData();
  }, [id]);

  async function handleSubmitData(e) {
    e.preventDefault();
    setMsgData({
      type: "",
      text: "",
    });
    if(name === '' || description === '' || permissions.length === 0) {
      return setMsgData({ type: 'danger', text: 'Preencha todos os campos' })
    }
    setLoading(true);
    try {
      const response = await axios.put(`/roles/${id}`, {
        name,
        description,
        permissions,
      });
      if (response.status === 203) {
        setMsgData({
          type: "danger",
          text: response.data.msg,
        });
      }
      setMsgData({
        type: "success",
        text: "Perfil atualizado",
      });
      onUpdate();
    } catch (err) {
      if (err.response && err.response.data && err.response.data.msg) {
        setMsgData({
          type: "danger",
          text: err.response.data.msg,
        });
      } else {
        setMsgData({
          type: "danger",
          text:
            "Ocorreu um erro em nossos servidores, contate a equipe técnica.",
        });
      }
    }
    setLoading(false);
  }


  useEffect(() => {
    async function loadPermissions() {
      setLoading(true);
      try {
        const { data } = await axios.get('/permissions');
        setPermissionsOptions(data);
      }catch(err) {
        setMsgData({ type: 'danger', text: err.message });
      }
      setLoading(false);
    }
    loadPermissions();
  }, []);


  function handleAddPermission(permission) {
    if(permissions.find(p => p === permission.id)) {
      permissions.filter(p => p !== permission.id);
    }else{
      setPermissions([...permissions, permission.id]);
    }
  }

  return (
    <>
      <button type="button" onClick={handleSubmitData} disabled={loading}>
        Salvar
      </button>
      <button type="button" className="ml-2" onClick={onClose} disabled={loading}>
        Voltar
      </button>
      <div id="add_role_container">
        <span id="avatar">
          <AiOutlineProfile />
        </span>
        <div>
          <Form>
            {loading && (
              <Spinner animation="border"/>
            )}
            {(msgData.type !== "" || msgData.text !== "") && (
              <Alert variant={msgData.type}>{msgData.text}</Alert>
            )}
            <Form.Group>
              <Form.Label>Nome</Form.Label>
              <Form.Control
                value={name}
                disabled={loading} onChange={(e) => setName(e.target.value)}
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>Descrição</Form.Label>
              <Form.Control
                value={description}
                disabled={loading} onChange={(e) => setDescription(e.target.value)}
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>Permissões</Form.Label>
              <div className="d-flex justify-content-between align-items-center flex-wrap">
                {permissionsOptions.length > 0 && permissionsOptions.map(permission => {
                  const checked = permissions.find(p => p === permission.id);
                  return (
                    <Form.Group controlId={`check_${permission.id}`} key={permission.id}>
                    <Form.Check type="checkbox" label={permission.name} checked={checked ? true : false} disabled={loading} onChange={() => handleAddPermission(permission)} />
                  </Form.Group>
                  )
                })}
              </div>
            </Form.Group>
          </Form>
        </div>
      </div>
    </>
  );
}

export default EditUser;
