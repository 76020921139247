import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";

import TableProduct from "./TableProduct";
import AddProduct from "./AddProduct";
import EditProduct from "./EditProduct";
import ModalConfirm from "./ModalConfirm";
import axios from "axios";

import { MdWarning } from "react-icons/md";
import { Modal, Alert } from "react-bootstrap";

function Products() {
  const [loading, setLoading] = useState(false);
  const [msg, setMsg] = useState("");
  const [products, setProducts] = useState([]);
  const [edit, setEdit] = useState({
    active: false,
    id: null,
  });
  const [deleteProduct, setDeleteProduct] = useState({
    active: false,
    product: {},
  });
  const [cancel, setCancel] = useState({
    id: null,
    active: false,
  })
  const [addProduct, setAddProduct] = useState(false);

  const { companyId } = useSelector((state) => state.theme);

  async function loadProducts() {
    const { data } = await axios.get("/products");
    setProducts(data);
  }

  useEffect(() => {
    loadProducts();
  }, []); // eslint-disable-line

  async function handleDelete() {
    setMsg("");
    setLoading(true);
    try {
      await axios.delete(`/products/${deleteProduct.product.id}`);
      setProducts(products.filter((u) => u.id !== deleteProduct.product.id));
      setDeleteProduct({
        active: false,
        product: {},
      });
    } catch (err) {
      setMsg("Ocorreu um erro em nossos servidores, contate a equipe técnica");
    }
    setLoading(false);
  }

  async function cancelRegistration(idR) {
    setLoading(true);
    try {
      console.log('>>',idR)
      await axios.post(`products/status/${idR.id}`);
      await loadProducts();
      setCancel({
        active: false,
        id: null,
      });
    } catch (err) {}
    setLoading(false);
  }

  function shareProduct(e) {
    const companyIdBase64 = Buffer.from(String(companyId)).toString("base64");
    const productIdBase64 = Buffer.from(String(e)).toString("base64");
    navigator.clipboard.writeText(
      `https://site.flix.tupi.io/checkout/${companyIdBase64}/${productIdBase64}`
    );
  }

  return (
    <div id="container">
      <ModalConfirm
        open={cancel.active}
        loading={loading}
        setOpen={() =>
          setCancel({
            active: false,
            id: null,
          })
        }
        onConfirm={() => cancelRegistration(cancel.id)}
      />
      <Modal
        show={deleteProduct.active}
        onHide={() =>
          setDeleteProduct({
            active: false,
            product: {},
          })
        }
        centered
      >
        <Modal.Body>
          <div className="text-center">
            <MdWarning style={{ fontSize: "80px" }} />
          </div>
          {msg !== "" && <Alert variant="danger">{msg}</Alert>}
          <h4 className="text-center">
            Você tem certeza que deseja excluir o produto{" "}
            <strong>{deleteProduct.product.title}</strong>?
          </h4>
          <div className="text-center">
            <button
              type="button"
              className="btn btn-outline-danger"
              disabled={loading}
              onClick={handleDelete}
            >
              Sim, excluir
            </button>
            <button
              type="button"
              className="btn btn-danger ml-2"
              disabled={loading}
              onClick={() =>
                setDeleteProduct({
                  active: false,
                  product: {},
                })
              }
            >
              Cancelar
            </button>
          </div>
        </Modal.Body>
      </Modal>
      {!edit.active && !addProduct && (
        <TableProduct
          products={products}
          shareProduct={(e) => shareProduct(e)}
          addProduct={() => setAddProduct(true)}
          editProduct={(e) =>
            setEdit({
              active: true,
              id: e,
            })
          }
          setCancel={(e) =>
            setCancel({
              active: true,
              id: e,
            })
          }
          onDelete={(e) =>
            setDeleteProduct({
              active: true,
              product: e,
            })
          }
        />
      )}
      {addProduct && (
        <AddProduct
          onAdd={() => loadProducts()}
          onClose={() => setAddProduct(false)}
        />
      )}
      {edit.active && (
        <EditProduct
          onUpdate={() => loadProducts()}
          onClose={() =>
            setEdit({
              active: false,
              id: null,
            })
          }
          id={edit.id}
        />
      )}
    </div>
  );
}

export default Products;
