import React, { useState, useEffect } from "react";

import { Content } from "./styles";
import { Form, Spinner, Table } from "react-bootstrap";

import { format, subDays } from "date-fns";

import { normalizeCurrency, normalizeDate } from "./normalize";

import axios from "axios";
import UserHeader from "../components/SubHeader";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import { useTranslation } from "react-i18next";

const DAY_IN_MS = 1000 * 60 * 60 * 24;

function Dashboard() {
  const [company, setCompany] = useState(null);
  const [revenue, setRevenue] = useState(null);
  const [signTotal, setSignTotal] = useState(285);
  const [ltv, setLtv] = useState(20000);
  const [topVideos, setTopVideos] = useState([]);
  const [insights, setInsights] = useState([]);
  const [totalNew, setTotalNew] = useState({
    startDate: format(subDays(new Date(), 30), "yyyy-MM-dd"),
    endDate: format(new Date(), "yyyy-MM-dd"),
    payments: [],
    loading: false,
  });
  const [signsNew, setSignsNew] = useState({
    startDate: format(subDays(new Date(), 30), "yyyy-MM-dd"),
    endDate: format(new Date(), "yyyy-MM-dd"),
    payments: [],
    loading: false,
  });
  const [cancelNew, setCancelNew] = useState({
    startDate: format(subDays(new Date(), 30), "yyyy-MM-dd"),
    endDate: format(new Date(), "yyyy-MM-dd"),
    loading: false,
    payments: [],
  });
  const [loading, setLoading] = useState(false);

  const [totalVideosCount, setTotalVideosCount] = useState(null);
  const [totalUsageMb, setTotalUsageMb] = useState(null);
  const [bandwidthConsuption, setBandwidthConsuption] = useState(null);
  const [totalMs, setTotalMs] = useState(null);

  const [startDate, setStartDate] = useState(
    format(new Date(Date.now() - DAY_IN_MS * 7), "yyyy-MM-dd")
  );
  const [endDate, setEndDate] = useState(
    format(new Date(Date.now()), "yyyy-MM-dd")
  );

  const { t } = useTranslation();

  useEffect(() => {
    async function loadCompany() {
      const { data: dataMe } = await axios.get(`/me`);
      setCompany(dataMe);
    }
    loadCompany();
  }, []);

  useEffect(() => {
    async function loadInfos() {
      setLoading(true);
      try {
        const { data: ltvData } = await axios.get(`/ltv`);
        setLtv(String(ltvData).split(".").join(","));

        const { data: signTotalData } = await axios.get("/signs-total");
        setSignTotal(Number(signTotalData));
      } catch (err) {
        console.log("err", err);
      }
      setLoading(false);
    }
    loadInfos();
  }, []);

  useEffect(() => {
    async function loadInfos() {
      setLoading(true);
      try {
        const { data } = await axios.get(`/revenue`);
        setRevenue(Number(data));
      } catch (err) {}
      setLoading(false);
    }
    loadInfos();
  }, []);

  useEffect(() => {
    async function loadInfos() {
      setSignsNew({
        ...signsNew,
        loading: true,
      });
      try {
        const { data } = await axios.get(
          `/new-signs?startDate=${signsNew.startDate}&endDate=${signsNew.endDate}`
        );
        setSignsNew({
          ...signsNew,
          payments: data,
          loading: false,
        });
      } catch (err) {
        setSignsNew({
          ...signsNew,
          loading: false,
        });
      }
    }
    loadInfos();
  }, [signsNew.startDate, signsNew.endDate]); // eslint-disable-line

  useEffect(() => {
    async function loadInfos() {
      setCancelNew({
        ...cancelNew,
        loading: true,
      });
      try {
        const { data } = await axios.get(
          `/new-cancel?startDate=${cancelNew.startDate}&endDate=${cancelNew.endDate}`
        );
        setCancelNew({
          ...cancelNew,
          payments: data,
          loading: false,
        });
      } catch (err) {
        setCancelNew({
          ...cancelNew,
          loading: false,
        });
      }
    }
    loadInfos();
  }, [cancelNew.startDate, cancelNew.endDate]); // eslint-disable-line

  useEffect(() => {
    async function loadInfos() {
      setLoading(true);
      try {
        const { data: dataMe } = await axios.get(`/me`);

        const { data } = await axios.get(
          `/kaltura/topvideos/${dataMe.company.owner}`
        );
        setTopVideos(data);
      } catch (err) {}
      setLoading(false);
    }
    loadInfos();
  }, []);

  useEffect(() => {
    async function loadInfos() {
      setLoading(true);
      try {
        const { data: dataMe } = await axios.get(`/me`);

        const { data } = await axios.get(
          `/kaltura/insights/${dataMe.company.owner}`
        );
        setInsights(data);
      } catch (err) {}
      setLoading(false);
    }
    loadInfos();
  }, []);

  // dataUsage fetch
  useEffect(() => {
    async function fetchUsage() {
      setLoading(true);

      // const startTime = startDate.getTime();
      // const endTime = endDate.getTime();
      const [year, month, day] = startDate.split("-");
      const startTime = new Date(year, month - 1, day).getTime();
      const [year2, month2, day2] = endDate.split("-");
      const endTime =
        new Date(year2, month2 - 1, day2).getTime() + DAY_IN_MS - 1;

      try {
        const { data: dataMe } = await axios.get(`/me`);

        const { data } = await axios.get(
          `kaltura/datausage/${dataMe.company.owner}?startTime=${startTime}&endTime=${endTime}`
        );

        const res = await axios.get(`/vpass/videos?page=1&search=`);

        console.log(res);
        setTotalUsageMb(data["total_storage_mb"]);
        setBandwidthConsuption(data["bandwidth_consumption"]);
        setTotalVideosCount(res.data.total.toString());
        setTotalMs(data["total_msecs"]);
      } catch (err) {
        console.log(err);
      }
    }
    fetchUsage();
    setLoading(false);
  }, [startDate, endDate]);

  const formatMb = (size) => {
    if (size < 1024) return `${parseFloat(size).toFixed(2)} MB`;
    if (size < 1024 * 1024) return `${Math.round(size / 1024)} GB`;
    return `${(size / 1024 / 1024).toFixed(2)} TB`;
  };

  const formatMs = (msecs) => {
    if (msecs < 1000) return `${msecs} milisegundos`; // milliseconds
    if (msecs < 1000 * 60) return `${(msecs / 1000).toFixed(0)} segundos`; // seconds
    if (msecs < 1000 * 60 * 60)
      return `${(msecs / 1000 / 60).toFixed(0)} minutos`; // minutes
    return `${(msecs / 1000 / 60 / 60).toFixed(2)} horas`; // hours
  };

  async function exportTotal() {
    window.open(
      `https://api.flix.tupi.io/csv/payments?company_id=${company.company_id}&startDate=${totalNew.startDate}&endDate=${totalNew.endDate}`,
      "_blank"
    );
  }

  async function exportSigns() {
    window.open(
      `https://api.flix.tupi.io/new-signs/csv?company_id=${company.company_id}&startDate=${signsNew.startDate}&endDate=${signsNew.endDate}`,
      "_blank"
    );
  }

  async function exportCancel() {
    window.open(
      `https://api.flix.tupi.io/new-cancel/csv?company_id=${company.company_id}&startDate=${signsNew.startDate}&endDate=${signsNew.endDate}`,
      "_blank"
    );
  }

  return (
    <Content>
      <UserHeader />
      <div id="main_content">
        <h2>Dashboard</h2>
        {company && company.company.type !== "vpass" && (
          <>
            <div className="grid_items2">
              <div className="item">
                <h4>
                  <span>
                    Novas Assinaturas
                    <br />
                    <small>
                      De{" "}
                      <Form.Control
                        type="date"
                        size="sm"
                        value={signsNew.startDate}
                        onChange={(e) =>
                          setSignsNew({
                            ...signsNew,
                            startDate: e.target.value,
                          })
                        }
                        disabled={signsNew.loading}
                      />{" "}
                      até{" "}
                      <Form.Control
                        type="date"
                        size="sm"
                        value={signsNew.endDate}
                        onChange={(e) =>
                          setSignsNew({
                            ...signsNew,
                            endDate: e.target.value,
                          })
                        }
                        disabled={signsNew.loading}
                      />
                      {signsNew.loading && (
                        <Spinner
                          animation="border"
                          size="sm"
                          className="ml-2"
                        />
                      )}
                    </small>
                  </span>
                  <button type="button" onClick={() => exportSigns()}>
                    Exportar
                  </button>
                </h4>
                <div className="content">
                  <p className="amount">{signsNew.amount} Assinatura(s)</p>
                  <div style={{ overflow: "auto", height: "250px" }}>
                    <Table size="sm" className="mt-3">
                      <thead>
                        <tr>
                          <th>
                            <small className="d-block text-left">Curso</small>
                          </th>
                          <th>
                            <small className="d-block text-left">Valor</small>
                          </th>
                          <th>
                            <small className="d-block text-left">Data</small>
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {signsNew.payments.length > 0 &&
                          signsNew.payments.map((i, index) => (
                            <tr key={index}>
                              <td>
                                <small className="d-block text-left">
                                  {i.enrollment && i.enrollment.product.title}
                                </small>
                              </td>
                              <td>
                                <small className="d-block text-left">
                                  R$ {normalizeCurrency(i.value)}
                                </small>
                              </td>
                              <td>
                                <small>
                                  {format(
                                    new Date(i.created_at),
                                    "dd/MM/yyyy - HH:mm"
                                  )}
                                </small>
                              </td>
                            </tr>
                          ))}
                      </tbody>
                    </Table>
                  </div>
                </div>
              </div>
              <div className="item">
                <h4>
                  <span>
                    Canelamentos de Assinatura
                    <br />
                    <small>
                      De{" "}
                      <Form.Control
                        type="date"
                        size="sm"
                        value={cancelNew.startDate}
                        onChange={(e) =>
                          setCancelNew({
                            ...cancelNew,
                            startDate: e.target.value,
                          })
                        }
                        disabled={cancelNew.loading}
                      />{" "}
                      até{" "}
                      <Form.Control
                        type="date"
                        size="sm"
                        value={cancelNew.endDate}
                        onChange={(e) =>
                          setCancelNew({
                            ...cancelNew,
                            endDate: e.target.value,
                          })
                        }
                        disabled={cancelNew.loading}
                      />
                      {cancelNew.loading && (
                        <Spinner
                          animation="border"
                          size="sm"
                          className="ml-2"
                        />
                      )}
                    </small>
                  </span>
                  <button type="button" onClick={() => exportCancel()}>
                    Exportar
                  </button>
                </h4>
                <div className="content">
                  <p className="amount">{cancelNew.amount} Cancelamento(s)</p>
                  <div style={{ overflow: "auto", height: "250px" }}>
                    <Table size="sm" className="mt-3">
                      <thead>
                        <tr>
                          <th>
                            <small className="d-block text-left">Cliente</small>
                          </th>
                          <th>
                            <small className="d-block text-left">Valor</small>
                          </th>
                          <th>
                            <small className="d-block text-left">Data</small>
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {cancelNew.payments.length > 0 &&
                          cancelNew.payments.map((i, index) => (
                            <tr key={index}>
                              <td>
                                <small className="d-block text-left">
                                  {i.customer.name}
                                </small>
                              </td>
                              <td>
                                <small className="d-block text-left">
                                  R$ {normalizeCurrency(i.value)}
                                </small>
                              </td>
                              <td>
                                <small>
                                  {format(
                                    new Date(i.created_at),
                                    "dd/MM/yyyy - HH:mm"
                                  )}
                                </small>
                              </td>
                            </tr>
                          ))}
                      </tbody>
                    </Table>
                  </div>
                </div>
              </div>
            </div>
            <div className="grid_items">
              <div className="item">
                <h4>
                  Relatório total de pagamentos (Aprovado e negado)
                  {loading && (
                    <Spinner animation="border" size="sm" className="ml-2" />
                  )}
                </h4>
                <div className="dateExport">
                  <span>
                    <small>
                      De{" "}
                      <Form.Control
                        type="date"
                        size="sm"
                        value={totalNew.startDate}
                        onChange={(e) =>
                          setTotalNew({
                            ...totalNew,
                            startDate: e.target.value,
                          })
                        }
                        disabled={totalNew.loading}
                      />{" "}
                      até{" "}
                      <Form.Control
                        type="date"
                        size="sm"
                        value={totalNew.endDate}
                        onChange={(e) =>
                          setTotalNew({
                            ...totalNew,
                            endDate: e.target.value,
                          })
                        }
                        disabled={totalNew.loading}
                      />
                      {totalNew.loading && (
                        <Spinner
                          animation="border"
                          size="sm"
                          className="ml-2"
                        />
                      )}
                    </small>
                    <button type="button" onClick={() => exportTotal()}>
                      Exportar
                    </button>
                  </span>
                </div>
              </div>
              <div className="item">
                <h4>
                  Assinaturas Totais do Serviço
                  {loading && (
                    <Spinner animation="border" size="sm" className="ml-2" />
                  )}
                </h4>
                <div className="content">
                  <p className="amount">{signTotal}</p>
                </div>
              </div>
              <div className="item">
                <h4>
                  Faturamento Total
                  {loading && (
                    <Spinner animation="border" size="sm" className="ml-2" />
                  )}
                </h4>
                <div className="content">
                  <p className="amount">R$ {normalizeCurrency(revenue)}</p>
                </div>
              </div>
              <div className="item">
                <h4>
                  LTV{" "}
                  {loading && (
                    <Spinner animation="border" size="sm" className="ml-2" />
                  )}
                </h4>
                <div className="content">
                  <p className="amount">R$ {ltv}/cliente</p>
                </div>
              </div>
            </div>
          </>
        )}
        <div className="grid_items3">
          <div className="item">
            <h4>
              {t("Top Vídeos")}
              {loading && (
                <Spinner animation="border" size="sm" className="ml-2" />
              )}
            </h4>
            <div className="content">
              <table className="table-bordered">
                {topVideos.length > 0 &&
                  topVideos.map((e, i) => (
                    <tr key={i}>
                      <td>{e.position}</td>
                      <td>{e.name}</td>
                    </tr>
                  ))}
              </table>
            </div>
          </div>
          <div className="item">
            <h4>
              {t("Maiores dias de videos assistidos")}
              {loading && (
                <Spinner animation="border" size="sm" className="ml-2" />
              )}
            </h4>
            <div className="content box_insights">
              {insights.length > 0 &&
                insights.map((e, i) => (
                  <div className="insights" key={i}>
                    <p>
                      <strong>{normalizeDate(e.date_id)}</strong>
                    </p>
                    <p>
                      <strong>{e.count_loads}</strong> {t("impressões")}
                    </p>
                    <p>
                      <strong>{e.count_plays}</strong> {t("vídeos")}
                    </p>
                    <p>
                      <strong>{e.unique_known_users}</strong>{" "}
                      {t("usuários únicos")}
                    </p>
                    <p>
                      <strong>{e.sum_time_viewed}</strong> {t("minutos vistos")}
                    </p>
                  </div>
                ))}
            </div>
          </div>
          {company && company.company.owner !== "elite" && (
            <div className="grid_items2 mt-0">
              <div className="item">
                <h4>
                  <span>
                    Dados de uso
                    <br />
                    <small>
                      De{" "}
                      <Form.Control
                        type="date"
                        size="sm"
                        value={startDate}
                        onChange={(e) => {
                          setStartDate(e.target.value);
                        }}
                        disabled={loading}
                      />{" "}
                      até{" "}
                      <Form.Control
                        type="date"
                        size="sm"
                        value={endDate}
                        onChange={(e) => {
                          setEndDate(e.target.value);
                        }}
                        disabled={loading}
                      />
                    </small>
                  </span>
                </h4>

                {!loading && totalVideosCount && (
                  <div className="content box_insights2">
                    <div className="insights">
                      <p>
                        <strong>{totalVideosCount}</strong> {t("vídeos")}
                      </p>
                      <p>
                        <strong>{formatMb(totalUsageMb)}</strong>{" "}
                        {t("de vídeo")}
                      </p>
                      <p>
                        <strong>{formatMb(bandwidthConsuption)}</strong>{" "}
                        {t("de consumo da banda")}
                      </p>
                      <p>
                        <strong>{formatMs(totalMs)}</strong> de vídeo
                      </p>
                    </div>
                  </div>
                )}
              </div>
            </div>
          )}
        </div>
      </div>
    </Content>
  );
}

export default Dashboard;
