import React from "react";

import { Table } from 'react-bootstrap';

function TableUsers({ customers, viewCustomer, registrationCustomer, historyCustomer }) {
  return (
    <>
      <Table responsive hover>
        <thead>
          <tr>
            <th>Nome</th>
            <th>Email</th>
            <th className="text-right">Ações</th>
          </tr>
        </thead>
        <tbody>
        {customers &&
          customers.map((customer) => (
            <tr key={customer.id}>
              <td>{customer.name}</td>
              <td>{customer.email}</td>
              <td>
              <div className="d-flex justify-content-end">
                  <button type="button" onClick={() => viewCustomer(customer.id)}>Visualizar</button>
                  <button type="button" onClick={() => registrationCustomer(customer.id)}>Matrículas</button>
                  {/* <button type="button" onClick={() => historyCustomer(customer.id)}>Histórico</button> */}
              </div>
              </td>
            </tr>
          ))}
          </tbody>
      </Table>
    </>
  );
}

export default TableUsers;
