import React, { useState, useEffect, useMemo } from "react";

import axios from "axios";

import { FaProductHunt } from "react-icons/fa";
import { Form, Alert, Spinner, InputGroup } from "react-bootstrap";

import { normalizeCurrency } from '../utils';

function EditUser({ onClose, onUpdate, id }) {
  const [file, setFile] = useState('');
  const [image, setImage] = useState(null);
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [value, setValue] = useState(null);

  const [msgData, setMsgData] = useState({
    type: "",
    text: "",
  });
  const [loading, setLoading] = useState(false);

  const preview = useMemo(
    () => {
        return image ? URL.createObjectURL(image) : null;
    }, [image]
  );

  useEffect(() => {
    async function loadData() {
      const { data } = await axios.get(`/products/${id}`);
      setTitle(data.title);
      setDescription(data.description);
      setFile(`${process.env.REACT_APP_API_URL}files/${data.file && data.file.id}`);
      setValue(normalizeCurrency(data.value));
    }
    loadData();
  }, [id]);

  async function handleSubmitData(e) {
    e.preventDefault();
    setMsgData({
      type: "",
      text: "",
    });
    setLoading(true);
    try {
      const obj = new FormData();
      if (image !== null) {
        obj.append("file", image);
      }
      obj.append("title", title);
      obj.append("description", description);
      obj.append("value", Number(value.replace(/[^\d]/g, '')));
      const response = await axios.put(`/products/${id}`, obj, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });
      if (response.status === 203) {
        setMsgData({
          type: "danger",
          text: response.data.msg,
        });
      }
      setMsgData({
        type: "success",
        text: "Produto atualizado",
      });
      onUpdate();
    } catch (err) {
      if (err.response && err.response.data && err.response.data.msg) {
        setMsgData({
          type: "danger",
          text: err.response.data.msg,
        });
      } else {
        setMsgData({
          type: "danger",
          text:
            "Ocorreu um erro em nossos servidores, contate a equipe técnica.",
        });
      }
    }
    setLoading(false);
  }

  return (
    <>
      <div id="add_container">
        <span id="avatar">
          <FaProductHunt />
        </span>
        <div>
          <Form onSubmit={handleSubmitData}>
            {(msgData.type !== "" || msgData.text !== "") && (
              <Alert variant={msgData.type}>{msgData.text}</Alert>
            )}
            <div id="file_image">
              <label htmlFor="custom-file" style={{ backgroundImage: `url(${preview || file})` }}>
              <h4>{image !== null ? 'Trocar imagem' : 'Selecionar Imagem'}</h4>
              </label>
              <input type="file"
                id="custom-file"
                label={image !== null ? image.name : "Escoher"}
                onChange={(e) => setImage(e.target.files[0])}
                disabled={loading}
                style={{ display: 'none' }}
              />
              <small>[INFORMAR TAMANHO E TIPO DE IMAGEM]</small>
            </div>
            <div className="row">
              <Form.Group className="col-md-4">
                <Form.Label>Título</Form.Label>
                <Form.Control
                  value={title}
                  onChange={(e) => setTitle(e.target.value)}
                  type="title"
                  disabled={loading}
                />
              </Form.Group>
              <Form.Group className="col-md-4">
                <Form.Label>Descrição</Form.Label>
                <Form.Control
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
                  type="text"
                  disabled={loading}
                />
              </Form.Group>
              <Form.Group className="col-md-4">
                <Form.Label>Valor</Form.Label>
                <InputGroup>
                  <InputGroup.Prepend>
                    <InputGroup.Text>R$</InputGroup.Text>
                  </InputGroup.Prepend>
                <Form.Control
                  value={value}
                  onChange={(e) => setValue(normalizeCurrency(Number(e.target.value.replace(/[^\d]/g, ''))))}
                  type="text"
                  disabled={loading}
                />
                </InputGroup>
              </Form.Group>
            </div>
            <button
              type="submit"
              disabled={loading}
              className="btn btn-primary btn-sm"
            >
              {loading ? <Spinner animation="border" /> : "Salvar"}
            </button>
            <button
              type="button"
              className="btn btn-light btn-sm ml-2"null
              disabled={loading}
              onClick={onClose}
            >
              Voltar
            </button>
          </Form>
        </div>
      </div>
    </>
  );
}

export default EditUser;
