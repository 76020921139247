import React, { useEffect, useState } from 'react';

import axios from 'axios';

import { useDispatch } from 'react-redux';
import { signInSuccess } from '../../../store/modules/auth/actions';

function Iframe({ match }) {
  const [message, setMessage] = useState("");
  const dispatch = useDispatch();

  const { token } = match.params;

  useEffect(() => {
    async function loadUser() {
      try {
        axios.defaults.headers.Authorization = `Bearer ${token}`;

        const { data: dataUser } = await axios.get(
          `/me`
        );
        sessionStorage.setItem('menu_active', 'not');
        dispatch(
          signInSuccess(token, {
            username: dataUser.username,
            email: dataUser.email,
            id: dataUser.id,
          }, {
            companyId: dataUser.company.id,
            background: dataUser.company.background,
            primary: dataUser.company.colorPrimary,
            secondary: dataUser.company.colorSecundary,
            logo: dataUser.company.logo,
          })
        );
      }catch(err) {
        setMessage(err.message);
      }
    }
    if(token) {
      loadUser();
    }
  }, [dispatch, token]);

  return (
    <div>
      <h3>{message !== '' ? message : 'Conectando...'}</h3>
    </div>
  );
}

export default Iframe;