import styled from 'styled-components';

export const Container = styled.div`
  display:flex;
  width: 100%;
  flex-direction:column;
  main {
    padding:40px;
    color:${props => props.theme.primary};
    form {
      padding:25px;
      border-radius:16px;
      background:#f9f9f9;
    }
  }
`;
