import React, { useState, useEffect } from "react";

import { Switch, Route, Redirect } from "react-router-dom";

import { Container } from "./styles";
import Header from "./components/Header";

import Dashboard from "./Dashboard";
import User from "./User";
import Role from "./Role";
import Customer from "./Customer";
import Product from "./Product";
import Category from "./Category";
import Course from "./Course";
import Video from "./Video";
import Videos from "./Videos";
import AddVideo from "./AddVideo";
import SendVideo from "./SendVideo";
import Payment from "./Payment";
import Coupon from "./Coupon";
import Config from "./Config";
import Profile from "./Profile";
import Iframe from "./Iframe";
import LiveCommerce from "./LiveCommerce";

import axios from "axios";

import verifyProfile from "./verifyProfile";

import { useHistory } from "react-router-dom";

export default function MainPage() {
  const [user, setUser] = useState({ roles: [] });

  const [menuActive] = useState(sessionStorage.getItem("menu_active"));
  const history = useHistory();

  useEffect(() => {
    async function loadUser() {
      const { data } = await axios.get(`/me`);
      setUser(data);
    }
    loadUser();
  }, []);

  useEffect(() => {
    if (menuActive === "not") {
      history.push("/product");
    }
  }, []); // eslint-disable-line

  return (
    <Container className="opened">
      {menuActive !== "not" && <Header user={user} />}
      <Switch>
        <Route exact path="/dashboard" component={Dashboard} />
        {verifyProfile(user, "user") && (
          <Route exact path="/user" component={User} />
        )}
        {verifyProfile(user, "role") && (
          <Route exact path="/role" component={Role} />
        )}
        {verifyProfile(user, "customer") && (
          <Route exact path="/customer" component={Customer} />
        )}
        <Route exact path="/profile" component={Profile} />
        <Route exact path="/video" component={SendVideo} />
        <Route exact path="/videos" component={Videos} />
        <Route exact path="/category" component={Category} />
        <Route exact path="/product" component={Product} />
        <Route exact path="/product/:product_id/course" component={Course} />
        <Route
          exact
          path="/product/:product_id/course/:course_id/video"
          component={Video}
        />
        <Route
          exact
          path="/product/:product_id/course/:course_id/add-video"
          component={AddVideo}
        />
        <Route exact path="/payment" component={Payment} />
        <Route exact path="/coupon" component={Coupon} />
        <Route exact path="/config" component={Config} />
        <Route exact path="/iframe/:token" component={Iframe} />
        <Route exact path="/ecommerce" component={LiveCommerce} />
        <Redirect to="/dashboard" />
      </Switch>
    </Container>
  );
}
