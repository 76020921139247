import styled from 'styled-components';

export const Content = styled.div`
  display:flex;
  width: 100%;
  flex-direction:column;
  #header_content {
    display:flex;
    justify-content:flex-end;
    align-items:center;
    background:#f9f9f9;
    padding:20px 30px;
    border-bottom:1px solid #f0f0f0;
    div {
      padding:10px;
      border-radius:6px;
      background:#e9e9e9;
      color:${props => props.theme.primary};
      .avatar_user {
        padding:5px;
        border-radius:6px;
        margin-right:10px;
        background:${props => props.theme.primary};
        color:#fff;
        font-size:30px;
      }
    }
  }
  #main_content {
    padding:40px;
    @media screen and (max-width: 600px) {
    & {
         padding:15px;
    }
}
    display:flex;
    flex-direction:column;
    flex:1;
    background:#fff;
    overflow:auto;
    #sub_menu {
      border-top:1px solid ${props => props.theme.primary};
      margin-top:15px;
      button {
        background:#f9f9f9;
        border:1px solid ${props => props.theme.primary};
        border-top:none;
        color:${props => props.theme.primary};
        padding:10px 15px;
        border-radius:0px 0px 10px 10px;
        transition:300ms;
        &:hover {
          background:${props => props.theme.primary};
          color:#fff;
        }
        &.active_button {
          background:${props => props.theme.primary};
          color:#fff;
        }
      }
    }
    h2 {
      color:${props => props.theme.primary};
    }
    #user_container {
      padding:30px;
      min-height:55vh;
      background:#eee;
      border-radius:10px;
      margin-top:15px;
      display: flex;
      flex-direction: column;
      #filter_content {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
      }
      #table_users {
        background:#f9f9f9;
        margin-top:10px;
        border-radius:10px;
        padding:30px;
      }
      #page_control {
        margin-top: auto;
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
        flex-wrap: wrap;
        a {
          padding: 10px 20px;
          border: 1px dashed green;
          border-radius: 6px;
          color: green;
          text-decoration: none;
        }
        div {
          display: flex;
          align-items: center;
          button {
            border: none;
            margin: 0px 10px;
            outline: none !important;
          }
        }
      }
    }
  }
`; 
