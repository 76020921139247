import React from "react";
import { Link } from "react-router-dom";

import { Table } from "react-bootstrap";

function TableCourses({
  courses,
  addCourse,
  editCourse,
  onDelete,
  product_id,
}) {
  return (
    <>
      <button type="button" onClick={addCourse}>
        Novo Curso
      </button>
      <Link to={"/product"} className="btn ml-3">
        Voltar
      </Link>
      <Table responsive hover>
        <thead>
          <tr>
            <th>Thumbnail</th>
            <th>Título</th>
            <th>Descrição</th>
            <th className="text-right">Ações</th>
          </tr>
        </thead>
        <tbody>
          {courses &&
            courses.map((course) => (
              <tr key={course.id}>
                <td>
                  {course.file ? (
                    <img
                      style={{ width: "320px" }}
                      className="thumb img-thumbnail"
                      src={`${process.env.REACT_APP_API_URL}files/${course.file.id}`}
                      alt=""
                    />
                  ) : (
                    <img
                      src="http://via.placeholder.com/320x180"
                      className="thumb img-thumbnail"
                      alt=""
                    />
                  )}
                </td>
                <td>{course.title}</td>
                <td>{course.description}</td>
                <td>
                  <div className="d-flex justify-content-end">
                    <Link
                      to={`/product/${product_id}/course/${course.id}/video`}
                    >
                      Videos
                    </Link>
                    <button type="button" onClick={() => editCourse(course.id)}>
                      Editar
                    </button>
                    <button type="button" onClick={() => onDelete(course)}>
                      Excluir
                    </button>
                  </div>
                </td>
              </tr>
            ))}
        </tbody>
      </Table>
    </>
  );
}

export default TableCourses;
