import React from "react";

import { Table } from "react-bootstrap";

function TableUsers({ categories, addCategory, editCategory, onDelete }) {
  return (
    <>
      <button type="button" onClick={addCategory}>
        Nova Categoria
      </button>
      <Table responsive hover>
        <thead>
          <tr>
            <th>Título</th>
            <th className="text-right">Ações</th>
          </tr>
        </thead>
        <tbody>
          {categories &&
            categories.map((category) => (
              <tr key={category.id}>
                <td>{category.name}</td>
                <td>
                  <div className="d-flex justify-content-end">
                    <button
                      type="button"
                      onClick={() => editCategory(category.id)}
                    >
                      Editar
                    </button>
                    <button type="button" onClick={() => onDelete(category)}>
                      Excluir
                    </button>
                  </div>
                </td>
              </tr>
            ))}
        </tbody>
      </Table>
    </>
  );
}

export default TableUsers;
