
const INITIAL_STATE = {
  language: "pt",
};

export default function translation(state = INITIAL_STATE, action) {
  switch (action.type) {
    case "CHANGE_LANGUAGE": {
      return { language: action.language };
    }
    default:
      return state;
  }
}
