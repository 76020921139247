import React, { useState, useEffect } from 'react';

import TableCoupons from './TableCoupons';
import AddCoupon from './AddCoupon';
import EditCoupon from './EditCoupon';
import axios from 'axios';

import { MdWarning } from 'react-icons/md';
import { Modal, Alert } from 'react-bootstrap';

function Coupons() {
  const [loading, setLoading] = useState(false);
  const [msg, setMsg] = useState('');
  const [coupons, setCoupons] = useState([]);
  const [edit, setEdit] = useState({
    active: false,
    id: null,
  });
  const [deleteCoupon, setDeleteCoupon] = useState({
    active: false,
    coupon: {},
  });
  const [addCoupon, setAddCoupon] = useState(false); 

  async function loadCoupons() {
    const { data } = await axios.get('/coupons');
    setCoupons(data);
  }

  useEffect(() => {
    loadCoupons();
  }, []); // eslint-disable-line

  async function handleDelete() {
    setMsg('');
    setLoading(true);
    try {
      await axios.delete(`/coupons/${deleteCoupon.coupon.id}`);
      setCoupons(coupons.filter(u => u.id !== deleteCoupon.coupon.id));
      setDeleteCoupon({
        active: false,
        coupon: {},
      });
    }catch(err) {
      setMsg('Ocorreu um erro em nossos servidores, contate a equipe técnica')
    }
    setLoading(false);
  }

  return (
    <div id="coupon_container">
      <Modal show={deleteCoupon.active} onHide={() => setDeleteCoupon({
        active: false,
        coupon: {},
      })} centered>
        <Modal.Body>
          <div className="text-center">
            <MdWarning style={{ fontSize: '80px' }}/>
          </div>
          {msg !== '' && (
            <Alert variant="danger">
              {msg}
            </Alert>
          )}
          <h4 className="text-center">
            Você tem certeza que deseja excluir o cupom <strong>{deleteCoupon.coupon.title}</strong>?
          </h4>
          <div className="text-center">
            <button type="button" className="btn btn-outline-danger" disabled={loading} onClick={handleDelete}>
              Sim, excluir
            </button>
            <button type="button" className="btn btn-danger ml-2" disabled={loading} onClick={() => setDeleteCoupon({
                active: false,
                coupon: {},
              })}>
              Cancelar
            </button>
          </div>
        </Modal.Body>
      </Modal>
      {!edit.active && !addCoupon && (
        <TableCoupons coupons={coupons} addCoupon={() => setAddCoupon(true)} editCoupon={(e) => setEdit({
          active: true,
          id: e,
        })} onDelete={(e) => setDeleteCoupon({
          active: true,
          coupon: e,
        })}/>
      )}
      {addCoupon && (
        <AddCoupon onAdd={() => loadCoupons()} onClose={() => setAddCoupon(false)}/>
      )}
      {edit.active && (
        <EditCoupon onUpdate={() => loadCoupons()} onClose={() => setEdit({
          active: false,
          id: null,
        })} id={edit.id}/>
      )}
    </div>
  );
}

export default Coupons;