import React from "react";
import { Link, useHistory } from "react-router-dom";
import { Table } from "react-bootstrap";
import statusVideo from "../../../../utils/statusVideo";
import statusVideoColor from "../../../../utils/statusVideoColor";
import { FaAngleUp, FaAngleDown } from "react-icons/fa";

function TableUsers({
  videos,
  addVideo,
  loading,
  editVideo,
  onDelete,
  product_id,
  course_id,
  upOrder,
  downOrder,
}) {
  const history = useHistory();

  return (
    <>
      <button
        type="button"
        onClick={() =>
          history.push(`/product/${product_id}/course/${course_id}/add-video`)
        }
      >
        Novo Vídeos
      </button>
      <Link to={`/product/${product_id}/course`} className="btn ml-3">
        Voltar
      </Link>
      <Table responsive hover>
        <thead>
          <tr>
            <th>Ordem</th>
            <th>Thumbnail</th>
            <th>Status</th>
            <th>Título</th>
            <th>Descrição</th>
            <th className="text-right">Ações</th>
          </tr>
        </thead>
        <tbody>
          {videos &&
            videos.map((video, index) => (
              <tr key={video.id}>
                <td>
                  {videos[index - 1] && (
                    <button
                      type="button"
                      className="btnOrder"
                      disabled={loading}
                      onClick={() => upOrder(video.id, videos[index - 1].id)}
                    >
                      <FaAngleUp />
                    </button>
                  )}
                  {videos[index + 1] && (
                    <button
                      type="button"
                      className="btnOrder"
                      disabled={loading}
                      onClick={() => downOrder(video.id, videos[index + 1].id)}
                    >
                      <FaAngleDown />
                    </button>
                  )}
                </td>
                <td>
                  <div className="thumb">
                    <img src={video.thumbnail} alt={video.title} />
                  </div>
                </td>
                <td>
                  <span
                    className={`btn-sm btn-${statusVideoColor(
                      String(video.video_status_id)
                    )}`}
                  >
                    {statusVideo(video.video_status_id)}
                  </span>
                </td>
                <td>{video.title}</td>
                <td>{video.description}</td>
                <td>
                  <div className="d-flex justify-content-end">
                    <button type="button" onClick={() => editVideo(video.id)}>
                      Editar
                    </button>
                    <button type="button" onClick={() => onDelete(video)}>
                      Excluir
                    </button>
                  </div>
                </td>
              </tr>
            ))}
        </tbody>
      </Table>
    </>
  );
}

export default TableUsers;
