import React, { useState, useEffect } from "react";

import axios from "axios";

import { AiOutlineProfile } from "react-icons/ai";
import { Form, Alert, Spinner } from "react-bootstrap";

function AddUser({ onClose, onAdd }) {
  const [loading, setLoading] = useState(false);
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [permissions, setPermissions] = useState([]);

  const [msg, setMsg] = useState({
    type: "",
    text: "",
  });
  const [permissionsOptions, setPermissionsOptions] = useState([]);

  useEffect(() => {
    async function loadPermissions() {
      setLoading(true);
      try {
        const { data } = await axios.get('/permissions');
        setPermissionsOptions(data);
      }catch(err) {
        setMsg({ type: 'danger', text: err.message });
      }
      setLoading(false);
    }
    loadPermissions();
  }, []);

  async function handleSubmit() {
    if(name === '' || description === '' || permissions.length === 0) {
      return setMsg({ type: 'danger', text: 'Preencha todos os campos' })
    }
    setLoading(true);
    try {
      const response = await axios.post("/roles", {
        name,
        description,
        permissions,
      });
      if (response.status === 203) {
        setMsg({
          type: "danger",
          text: response.data.msg,
        });
      } else {
        setName("");
        setDescription("");
        setPermissions([]);
        setMsg({
          type: "success",
          text: "Perfil Criado",
        });
        onAdd();
      }
    } catch (err) {
      if (err.response && err.response.data && err.response.data.msg) {
        setMsg({
          type: "danger",
          text: err.response.data.msg,
        });
      } else {
        setMsg({
          type: "danger",
          text:
            "Ocorreu um erro em nossos servidores, contate a equipe técnica.",
        });
      }
    }
    setLoading(false);
  }

  function handleAddPermission(permission) {
    if(permissions.find(p => p === permission.id)) {
      permissions.filter(p => p !== permission.id);
    }else{
      setPermissions([...permissions, permission.id]);
    }
  }

  return (
    <>
      <div id="add_role_container">
        <span id="avatar">
          <AiOutlineProfile />
        </span>
        <div>
          <Form>
            {loading && (
              <Spinner animation="border"/>
            )}
            {(msg.type !== "" || msg.text !== "") && (
              <Alert variant={msg.type}>{msg.text}</Alert>
            )}
            <Form.Group>
              <Form.Label>Nome</Form.Label>
              <Form.Control
                value={name}
                disabled={loading} onChange={(e) => setName(e.target.value)}
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>Descrição</Form.Label>
              <Form.Control
                value={description}
                disabled={loading} onChange={(e) => setDescription(e.target.value)}
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>Permissões</Form.Label>
              <div className="d-flex justify-content-between align-items-center flex-wrap">
                {permissionsOptions.length > 0 && permissionsOptions.map(permission => {
                  const checked = permissions.find(p => p === permission.id);
                  return (
                    <Form.Group controlId={`check_${permission.id}`} key={permission.id}>
                    <Form.Check type="checkbox" label={permission.name} checked={checked ? true : false} disabled={loading} onChange={() => handleAddPermission(permission)} />
                  </Form.Group>
                  )
                })}
              </div>
            </Form.Group>
            <button type="button" onClick={handleSubmit} className="btn btn-warning btn-sm" disabled={loading}>
              Salvar
            </button>
            <button type="button" className="btn btn-light btn-sm ml-2" onClick={onClose} disabled={loading}>
              Voltar
            </button>
          </Form>
        </div>
      </div>
    </>
  );
}

export default AddUser;
