import React, { useEffect, useState } from "react";
import axios from "axios";

import { Content } from "./styles";

import Video from "./components/Video";
import UserHeader from '../components/SubHeader';

function Register({ match }) {
  const [course, setCourse] = useState("");

  useEffect(() => {
    async function loadData() {
      const { data } = await axios.get(
        `/products/${match.params.product_id}/courses/${match.params.course_id}`
      );
      setCourse(data.title);
    }
    loadData();
  }, [match.params.product_id, match.params.course_id]);

  return (
    <Content>
      <UserHeader />
      <div id="main_content">
        <h2>{course} - Vídeos</h2>
        <Video
          product_id={match.params.product_id}
          course_id={match.params.course_id}
        />
      </div>
    </Content>
  );
}

export default Register;
