import React, { useState, useEffect } from "react";

import { Form, Col, Button } from "react-bootstrap";
import Iframe from 'react-iframe'
import { FaUser } from "react-icons/fa";
import { RiVideoLine } from "react-icons/ri";
import { Content } from "./styles";

import { useSelector } from "react-redux";
import axios from "axios";

import { useHistory } from "react-router-dom";

import VideoUpload from "./VideoUpload";
import youtube from "youtube-metadata-from-url";
import { useTranslation } from "react-i18next";

function ModalAddVideo({ match }) {
  const [files, setFiles] = useState([]);
  const [uploaded, setUploaded] = useState([]);
  const [course, setCourse] = useState("");
  const [isUploadingFromComputer, setIsUploadingFromComputer] = useState(true);
  const [youtubeLinkInput, setYoutubeLinkInput] = useState("");
  const [invalidYoutubeUrl, setInvalidYoutubeUrl] = useState(false);

  const history = useHistory();
  const { t } = useTranslation();

  useEffect(() => {
    async function loadData() {
      const { data } = await axios.get(
        `products/${match.params.product_id}/courses/${match.params.course_id}`
      );
      setCourse(data.title);
    }
    loadData();
  }, [match.params.course_id, match.params.product_id]);

  const { user } = useSelector((state) => state.user);
  const theme = useSelector((state) => state.theme);

  const uploadFromComputerMessage = t("Adicionar do computador");
  const uploadFromYoutubeMessage = t("Adicionar por link do YouTube");
  const onUploadMethodChanged = (e) => {
    if (e.target.value === uploadFromYoutubeMessage)
      return setIsUploadingFromComputer(false);

    setIsUploadingFromComputer(true);
  };

  const onNewYoutubeUrl = () => {
    try {
      youtube.metadata(youtubeLinkInput).then(
        (json) => {
          console.log(json);
          setFiles([
            ...files,
            {
              name: json.title,
              url: youtubeLinkInput,
              type: "youtube",
              size: "indefinido",
              description: "sem descrição ",
              thumbnailUrl: json.thumbnail_url,
            },
          ]);
          setYoutubeLinkInput("");
        },
        (err) => {
          console.log("ERRO!", err);
          setInvalidYoutubeUrl(true);
        }
      );
    } catch (err) {
      console.log("ERRO!", err);
      setInvalidYoutubeUrl(true);
    }
  };

  return (
    <Content>
      <div id="header_content">
        <div>
          <FaUser className="avatar_user" />
          <span>{user.username}</span>
        </div>
      </div>
      <div id="main_content">
        <h2>{course} - {t("Adicionar Vídeos")}</h2>
        <div id="container">
          <button
            type="button"
            className="btn"
            onClick={() =>
              history.push(
                theme.type === "vpass"
                  ? "/videos"
                  : `/product/${match.params.product_id}/course/${match.params.course_id}/video`
              )
            }
          >
            {t("Voltar")}
          </button>
          <Form>
            <div>
              <div>
                <Form.Control
                  as="select"
                  className="mt-4 md-4"
                  onChange={onUploadMethodChanged}
                >
                  <option>{uploadFromComputerMessage}</option>
                  <option>{uploadFromYoutubeMessage}</option>
                </Form.Control>
              </div>
                {isUploadingFromComputer ? (
                  // <Form.File
                  //   className="mt-4"
                  //   md="auto"
                  //   custom
                  //   label={
                  //     files.length > 0
                  //       ? t("Escolher mais vídeos")
                  //       : t("Escolher vídeos")
                  //   }
                  //   onChange={(e) => setFiles([...files, ...e.target.files])}
                  //   accept="video/mp4,video/x-m4v,video/*"
                  //   multiple
                  // />
                  <Iframe url={`https://uploadfront.flix.tupi.io/?token=${user.id}`}
                    width="100%"
                    height="550px"
                    id="myId"
                    className="iframeUploadFromComputer mt-3"
                    display="initial"
                    position="relative"/>
                ) : (
                  <Form.Row className="align-items-center">
                    <Col>
                      <Form.Control
                        className="mt-4"
                        id="inlineFormInput"
                        placeholder="youtube.com/watch?..."
                        value={youtubeLinkInput}
                        onChange={(e) => setYoutubeLinkInput(e.target.value)}
                        isInvalid={invalidYoutubeUrl}
                      />
                    </Col>
                    <Col md="auto">
                      <Button
                        type="submit"
                        className="mt-4"
                        onClick={(e) => {
                          e.preventDefault();
                          onNewYoutubeUrl();
                        }}
                      >
                        Adicionar
                      </Button>
                    </Col>
                  </Form.Row>
                )}
            </div>
          </Form>
          {!isUploadingFromComputer && files.length === 0 && (
            <h4 className="mt-4 mb-2 text-center">
              <RiVideoLine
                className="d-block"
                size={80}
                style={{ margin: "0 auto" }}
              />
              Nenhum vídeo selecionado
            </h4>
          )}
          <div
            style={{
              display: "grid",
              gridTemplateColumns: "1fr",
              gridGap: "16px",
              marginTop: "20px",
            }}
          >
            {files.length > 0 &&
              files.map((file, index) => (
                <VideoUpload
                  file={file}
                  key={index}
                  onRemove={() => setFiles(files.filter((f, i) => i !== index))}
                  uploaded={uploaded.find((u) => u === index)}
                  onUpload={() => setUploaded([...uploaded, index])}
                  courseId={match.params.course_id}
                />
              ))}
          </div>
        </div>
      </div>
    </Content>
  );
}

export default ModalAddVideo;
