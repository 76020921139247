import React from 'react';

import { Switch, Route, Redirect } from 'react-router-dom';

import Login from './Login';
import ForgotPassword from './ForgotPassword';
import Recovery from './Recovery';
import Iframe from './Iframe';

export default function AuthPage() {
  return (
    <Switch>
      <Route exact path="/" component={Login} />
      <Route exact path="/forgot-password" component={ForgotPassword} />
      <Route exact path="/recovery/:token" component={Recovery} />
      <Route exact path="/iframe/:token" component={Iframe} />
      <Redirect to="/" />
    </Switch>
  );
}
