import produce from 'immer';

const INITIAL_STATE = {
  user: {
    username: '',
    email: '',
    id: '',
  },
};

export default function auth(state = INITIAL_STATE, action) {
  return produce(state, (draft) => {
    switch (action.type) {
      case '@auth/SIGN_IN_SUCCESS': {
        draft.user.username = action.payload.user.username;
        draft.user.email = action.payload.user.email;
        draft.user.id = action.payload.user.id;
        break;
      }
      case '@auth/SIGN_OUT': {
        draft.user.username = '';
        draft.user.email = '';
        draft.user.id = '';
        break;
      }
      case '@user/UPDATE_USER': {
        draft.user.username = action.payload.user.username;
        draft.user.email = action.payload.user.email;
        break;
      }
      default:
    }
  });
}
