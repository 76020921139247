import i18n from "i18next";
import { initReactI18next } from "react-i18next";

// the translations
// (tip move them in a JSON file and import them)
const resources = {
  en: {
    translation: {
      "Welcome to React": "Welcome to React and react-i18next",
      "Dados de uso": "Data usage",
      "Maiores dias de videos assistidos": "Days with most views",
      "Top Vídeos": "Top Videos",
      "de": "from",
      "a": "to",      
      "vídeos": "videos",
      "de vídeo": "of video",
      "de consumo da banda": "of bandwidth usage",
      "impressões": "impressions",
      "usuários únicos": "unique users",
      "minutos vistos": "minutes seen",
      "Vídeos": "Videos",
      "Novo Vídeo": "New Video",
      "Digite o título ou Entry_id": "Type the title or the Entry_id",
      "Pesquisar": "Search",
      "Ordem": "Order",
      "Thumbnail": "Thumbnail",
      "Status": "Status",
      "Título": "Title",
      "Descrição": "Description",
      "Ações": "Actions",
      "Pronto": "Ready",
      "Convertendo": "Converting",
      "Enviando": "Uploading",
      "Editar": "Edit",
      "Excluir": "Delete",
      "Erro ao importar": "Error when importing",
      "Erro ao converter": "Error when converting",
      "Importar": "Import",
      "Excluído": "Deleted",
      "Pendente": "Peding",
      "Moderado":  "Moderate",
      "Bloqueado": "Blocked",
      "Sem conteúdo": "No content",
      "Você tem certeza que deseja excluir o produto": "Are you sure you want to delete the video",
      "Sim, excluir": "Yes, delete",
      "Cancelar": "Cancel",
      "Adicionar Vídeos": "Add Videos",
      "Voltar": "Back",
      "Adicionar do computador": "Upload from computer",
      "Escolher vídeos": "Select videos",
      "Escolher mais vídeos": "Select more videos",
      "Adicionar por link do YouTube": "Add by Youtube URL",
      "Adicionar": "Add",
      "Nenhum vídeo selecionado": "No video selected",
      "Categoria": "Category",
      "Selecione a categoria": "Choose category",
      "Salvar": "Save",
      "Selecionar Imagem": "Choose image",
      "Trocar imagem": "Change image",
      "Baixar": "Download",
      "Nome": "Name",
      "Dimensões": "Dimensions",
      "Formato": "File type",
      "Tamanho": "Size",
      "Sair": "Logout",
      "Editar Conta": "Edit Account",
      "Editar Senha": "Change Password",
      "Senha": "Password",
      "Digite seu e-mail": "Type your e-mail",
      "Digite sua senha": "Type your password",
      "Acessar": "Login",
      "Esqueceu sua senha?": "Forgot your password?",
      "Painel Administrativo": "Admin Dashboard",
      "Recuperar Senha": "Recover password",
      "Categorias": "Categories",
      "Usuário": "User",
    },
  },
  pt: {
    translation: {
      "Dados de uso": "Dados de uso",
      "Maiores dias de videos assistidos": "Maiores dias de videos assistidos",
      "Top Vídeos": "Top Vídeos",
      "de": "de",
      "a": "a",
      "vídeos": "videos",
      "de vídeo": "de vídeo",
      "de consumo da banda": "de consumo de banda",
      "impressões": "impressões",
      "usuários únicos": "usuários únicos",
      "minutos vistos": "minutos vistos",
      "Vídeos": "Videos",
      "Novo Vídeo": "Novo Vídeo",
      "Digite o título ou Entry_id": "Digite o título ou Entry_id",
      "Pesquisar": "Pesquisar",
      "Ordem": "Ordem",
      "Thumbnail": "Thumbnail",
      "Status": "Status",
      "Título": "Título",
      "Descrição": "Descrição",
      "Ações": "Ações",
      "Pronto": "Pronto",
      "Convertendo": "Convertendo",
      "Enviando": "Enviando",
      "Editar": "Editar",
      "Excluir": "Excluir",
      "Erro ao importar": "Erro ao importar",
      "Erro ao converter": "Erro ao converter",
      "Importar": "Importar",
      "Excluído": "Excluído",
      "Pendente": "Pendente",
      "Moderado":  "Moderado",
      "Bloqueado": "Bloqueado",
      "Sem conteúdo": "Sem conteúdo",
      "Você tem certeza que deseja excluir o produto": "Você tem certeza que deseja excluir o produto",
      "Sim, excluir": "Sim, excluir",
      "Cancelar": "Cancel",
      "Adicionar Vídeos": "Adicionar Vídeos",
      "Voltar": "Voltar",
      "Adicionar do computador": "Adicionar do computador",
      "Escolher vídeos": "Escolher vídeos",
      "Adicionar por link do YouTube": "Adicionar por link do YouTube",
      "Adicionar": "Adicionar",
      "Nenhum vídeo selecionado": "Nenhum vídeo selecionado"
    },
  },
};

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: "pt",

    keySeparator: false, // we do not use keys in form messages.welcome

    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  });

export default i18n;
