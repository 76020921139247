import React from "react";

import { Container } from "./styles";

import { Link } from "react-router-dom";

import { useDispatch, useSelector } from "react-redux";
import { signOut } from "../../../../store/modules/auth/actions";

import verifyProfile from "../../verifyProfile";
import { useTranslation } from "react-i18next";

function Header({ user }) {
  const dispatch = useDispatch();

  const { logo, type } = useSelector((state) => state.theme);

  const { t } = useTranslation();

  return (
    <Container className={"opened"}>
      <img src={`/assets/${logo}`} alt="Tupi - Plataforma de vídeo" />
      <nav>
        <ul>
          <Link to="/dashboard">{t("Home")}</Link>
          {type !== "vpass" && (
            <Link to="/payment">Relatório de pagamento</Link>
          )}
          {type === "vpass" && <Link to="/videos">{t("Vídeos")}</Link>}
          {type !== "vpass" && <Link to="/product">Produtos/Cursos</Link>}
          {type !== "vpass" && <Link to="/video">Enviar vídeos</Link>}
          <Link to="/category">{t("Categorias")}</Link>
          {type !== "vpass" && verifyProfile(user, "customer") && (
            <Link to="/customer">Cliente</Link>
          )}
          {type !== "vpass" && <Link to="/coupon">Cupom</Link>}
          {verifyProfile(user, "user") && (
            <Link to="/user">{t("Usuário")}</Link>
          )}
          {type !== "vpass" && verifyProfile(user, "role") && (
            <Link to="/role">Perfil</Link>
          )}
          {/*
          <Link to="/payments">Pagamentos</Link>
          <Link to="/config">Configurações</Link> */}
          <Link to="/ecommerce">Live commerce</Link>

          <button type="button" onClick={() => dispatch(signOut())}>
            {t("Sair")}
          </button>
        </ul>
      </nav>
    </Container>
  );
}

export default Header;
