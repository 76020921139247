import React, { useState, useEffect } from "react";

import axios from "axios";

import { Spinner, Table } from "react-bootstrap";
import { format } from "date-fns";

function HistoryCustomer({ onClose, id }) {
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(false);

  async function loadData() {
    setLoading(true);
    const { data: dataCustomer } = await axios.get(`/customers/${id}`);
    setData(dataCustomer[0]);
    setLoading(false);
  }

  useEffect(() => {
    loadData();
  }, []); // eslint-disable-line

  return (
    <>
      <div id="add_user_container">
        {loading ? (
          <Spinner animation="border" />
        ) : (
          <>
            <div>
              <strong>{data.name} {'>'} Histórico</strong>
              <hr />
              <Table>
                <tbody>
                  {data.histories &&
                    data.histories.map((history) => (
                      <tr key={history.id}>
                        <td>**** **** **** {history.card}</td>
                        <td className="text-center">
                          {history.value}
                        </td>
                        <td className="text-right">
                          {format(new Date(history.date.replace(/ /g, "T")), 'dd/MM/yyyy')}
                        </td>
                      </tr>
                    ))}
                </tbody>
              </Table>
            </div>
          </>
        )}
        <button
          type="button"
          className="btn btn-secondary btn-sm"
          disabled={loading}
          onClick={onClose}
        >
          Voltar
        </button>
      </div>
    </>
  );
}

export default HistoryCustomer;
