import React from 'react';

import { Content } from './styles';

import UserHeader from '../components/SubHeader';
import Category from './components/Category';

function Register() {

  return (
      <Content>
        <UserHeader />
        <div id="main_content">
          <h2>Categorias</h2>
          <Category />
        </div>
      </Content>
  );
}

export default Register;