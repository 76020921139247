import React, { useState, useEffect } from "react";

import axios from "axios";

import { FaUser } from "react-icons/fa";
import { Form, Alert } from "react-bootstrap";

function EditUser({ onClose, onUpdate, id }) {
  const [username, setUsername] = useState("");
  const [email, setEmail] = useState("");
  const [role, setRole] = useState('');

  const [roleOptions, setRoleOptions] = useState([]);

  const [msgData, setMsgData] = useState({
    type: '',
    text: '',
  });
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    async function loadData() {
      const { data: dataUser } = await axios.get(`/users/${id}`);
      setUsername(dataUser.username);
      setEmail(dataUser.email);
      setRole(dataUser.roles[0].id)
      const { data: dataRole } = await axios.get('/roles');
      setRoleOptions(dataRole);
    }
    loadData();
  }, [id]);

  async function handleSubmitData(e) {
    e.preventDefault();
    setMsgData({
      type: '',
      text: '',
    });
    setLoading(true);
    try {
      const response = await axios.put(`/users/${id}`, {
        username,
        email,
        roles: [role]
      });
      if(response.status === 203) {
        setMsgData({
          type: 'danger',
          text: response.data.msg,
        });
      }
      setMsgData({
        type: 'success',
        text: 'Usuário atualizado'
      });
      onUpdate();
    }catch(err) {
      if(err.response && err.response.data && err.response.data.msg) {
        setMsgData({
          type: 'danger',
          text: err.response.data.msg,
        });
      }else{
        setMsgData({
          type: 'danger',
          text: 'Ocorreu um erro em nossos servidores, contate a equipe técnica.',
        });
      }
    }
    setLoading(false);
  }

  return (
    <>
      <div id="add_user_container">
        <span id="avatar">
          <FaUser />
        </span>
        <div>
          <Form onSubmit={handleSubmitData}>
          {(msgData.type !== '' || msgData.text !== '') && (
              <Alert variant={msgData.type}>
                {msgData.text}
              </Alert>
            )}
            <Form.Group>
              <Form.Label>Nome</Form.Label>
              <Form.Control
                value={username}
                onChange={(e) => setUsername(e.target.value)}
                disabled={loading}
                required
              />
            </Form.Group>
            <div className="row">
              <Form.Group className="col-md-6">
                <Form.Label>E-mail</Form.Label>
                <Form.Control
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  disabled={loading}
                  type="email"
                  required
                />
              </Form.Group>
              <Form.Group className="col-md-6">
              <Form.Label>Perfil</Form.Label>
                <Form.Control as="select" value={role} onChange={(e) => setRole(e.target.value)}>
                  <option value=""></option>
                  {roleOptions.length > 0 && roleOptions.map((rol, index) => (
                    <option key={index} value={rol.id}>{rol.name}</option>
                  ))}
                </Form.Control>
              </Form.Group>
            </div>
            <button type="submit" disabled={loading} className="btn btn-warning btn-sm">
            {loading ? 'Salvando...' : 'Salvar'}
            </button>
            <button type="button" disabled={loading} className="btn btn-light btn-sm ml-2" onClick={onClose}>
              Voltar
            </button>
          </Form>
        </div>
      </div>
    </>
  );
}

export default EditUser;
