import React, { useState, useEffect } from 'react';

import TablePayments from './TablePayments';
import axios from 'axios';

import { Form } from 'react-bootstrap';

function Users() {
  const [loading, setLoading] = useState(false);
  const [payments, setPayments] = useState([]);

  const [customersOptions, setCustomersOptions] = useState([]);

  const [filters, setFilters] = useState({
    startDate: '',
    endDate: '',
    statusPayment: '',
    customer_id: '',
    page: 1,
    perPage: 20,
  })

  async function loadPayments() {
    setLoading(true);
    try {
      const filtersArr = [];
      if(filters.startDate !== '') {
        filtersArr.push(`startDate=${filters.startDate}`)
      }
      if(filters.endDate !== '') {
        filtersArr.push(`endDate=${filters.endDate}`)
      }
      if(filters.statusPayment !== '') {
        filtersArr.push(`statusPayment=${filters.statusPayment}`)
      }
      if(filters.customer_id !== '') {
        filtersArr.push(`customerId=${filters.customer_id}`)
      }
      const { data } = await axios.get(`/payments/report?${filtersArr.map(i => i).join('&')}`);
      setPayments(data);
    }catch(err) {

    }
    setLoading(false);
  }

  useEffect(() => {
    loadPayments();
  }, [filters]); // eslint-disable-line

  useEffect(() => {
    async function loadOptions() {
      try {
        const { data } = await axios.get('/customers');
        setCustomersOptions(data);
      }catch(err) {

      }
    }
    loadOptions();
  }, []);

  return (
    <div id="user_container">
      <div id="filter_content">
        <div className="d-flex">
          <Form.Group>
            <Form.Label>
              De
            </Form.Label>
            <Form.Control type="date" disabled={loading} value={filters.startDate} onChange={(e) => setFilters({...filters, startDate: e.target.value })}/>
          </Form.Group>
          <Form.Group className="ml-3">
            <Form.Label>
              Até
            </Form.Label>
            <Form.Control type="date" disabled={loading} value={filters.endDate} onChange={(e) => setFilters({...filters, endDate: e.target.value })}/>
          </Form.Group>
          <Form.Group className="ml-3">
            <Form.Label>
              Status
            </Form.Label>
            <Form.Control as="select" disabled={loading} value={filters.statusPayment} onChange={(e) => setFilters({...filters, statusPayment: e.target.value })}>
              <option value="">Todos</option>
              <option value="2">Em análise</option>
              <option value="3">Aprovado</option>
              <option value="5">Negado</option>
            </Form.Control>
          </Form.Group>
          <Form.Group className="ml-3">
            <Form.Label>
              Cliente
            </Form.Label>
            <Form.Control disabled={loading} as="select" value={filters.customer_id} onChange={(e) => setFilters({...filters, customer_id: e.target.value })}>
              <option value="">Todos</option>
              {customersOptions.map(p => (
                <option key={p.id} value={p.id}>{p.name}</option>
              ))}
            </Form.Control>
          </Form.Group>
        </div>
      </div>
      <TablePayments payments={payments} />
    </div>
  );
}

export default Users;