import React from "react";
import { Link } from "react-router-dom";

import { Table } from "react-bootstrap";

import { normalizeCurrency } from "../utils";

function TableUsers({
  products,
  shareProduct,
  addProduct,
  editProduct,
  onDelete,
  setCancel,
}) {
  return (
    <>
      <button type="button" onClick={addProduct}>
        Novo Produto
      </button>
      <Table responsive hover>
        <thead>
          <tr>
            <th>Thumbnail</th>
            <th>Título</th>
            <th>Valor</th>
            <th className="text-right">Ações</th>
          </tr>
        </thead>
        <tbody>
          {products &&
            products.map((product) => (
              <tr key={product.id}>
                <td>
                  {product.file ? (
                    <img
                      style={{ width: "320px" }}
                      className="thumb img-thumbnail"
                      src={`${process.env.REACT_APP_API_URL}files/${product.file.id}`}
                      alt=""
                    />
                  ) : (
                    <img
                      src="http://via.placeholder.com/320x180"
                      className="thumb img-thumbnail"
                      alt=""
                    />
                  )}
                </td>
                <td>{product.title}</td>
                <td>R$ {normalizeCurrency(product.value)}</td>
                <td>
                  <div className="d-flex justify-content-end">
                    <button
                      type="button"
                      onClick={() => shareProduct(product.id)}
                    >
                      Copiar Link
                    </button>
                    <button
                      type="button"
                      onClick={() =>
                        setCancel({
                          id: product.id,
                          active: true,
                        })
                      }
                    >
                    {product.inactive === null ? "Inativar" : "Ativar"}
                    </button>
                    <Link to={`/product/${product.id}/course`}>Cursos</Link>
                    <button
                      type="button"
                      onClick={() => editProduct(product.id)}
                    >
                      Editar
                    </button>
                    <button type="button" onClick={() => onDelete(product)}>
                      Excluir
                    </button>
                  </div>
                </td>
              </tr>
            ))}
        </tbody>
      </Table>
    </>
  );
}

export default TableUsers;
