import React from "react";

import { Table } from 'react-bootstrap';

function TableRoles({ roles, addRole, editRole, onDelete }) {
  return (
    <>
      <button type="button" onClick={addRole}>
        Novo Perfil
      </button>
      <Table responsive hover>
        <thead>
          <tr>
            <th>Nome</th>
            <th className="text-right">Ações</th>
          </tr>
        </thead>
        <tbody>
        {roles &&
          roles.map((role) => (
            <tr key={role.id}>
              <td>{role.name}</td>
              <td>
              <div className="d-flex justify-content-end">
                <button type="button" onClick={() => editRole(role.id)}>
                  Editar
                </button>
                <button type="button" onClick={() => onDelete(role)}>
                  Excluir
                </button>
              </div>
              </td>
            </tr>
          ))}
          </tbody>
      </Table>
    </>
  );
}

export default TableRoles;
