import React from "react";

// import { Container } from './styles';

function VideoElemet({ link }) {
  return (
    <iframe
      type="text/javascript"
      title={`kaltura_video_${Math.random() * 1000}`}
      src={link}
      style={{ width: "100%", height: "100%", minHeight: "400px" }}
      allowFullScreen
      frameBorder="0"
    ></iframe>
  );
}

export default VideoElemet;
