import React, { useState, useEffect } from "react";

import axios from "axios";

import { Spinner, Table } from "react-bootstrap";
import { format } from "date-fns";

import ModalConfirm from "./ModalConfirm";
import ModalAddRegistration from "./ModalAddRegistration";

function RegistrationCustomer({ onClose, id }) {
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(false);

  const [addRegister, setAddRegister] = useState(false);
  const [cancel, setCancel] = useState({
    id: null,
    active: false,
  })

  async function loadData() {
    setLoading(true);
    const { data: dataCustomer } = await axios.get(`/customers/${id}`);
    setData(dataCustomer[0]);
    setLoading(false);
  }

  async function cancelRegistration(idR) {
    setLoading(true);
    try {
      await axios.post(`enrollments/inactive/${idR}`);
      await loadData();
      setCancel({
        active: false,
        id: null,
      });
    } catch (err) {}
    setLoading(false);
  }

  useEffect(() => {
    loadData();
  }, []); // eslint-disable-line

  return (
    <>
    <ModalConfirm open={cancel.active} loading={loading} setOpen={() => setCancel({
      active: false,
      id: null,
    })} onConfirm={() => cancelRegistration(cancel.id)}/>
    <ModalAddRegistration open={addRegister} setOpen={() => setAddRegister(false)} customer_id={id} onUpdate={loadData}/>
      <div id="add_user_container">
        {loading ? (
          <Spinner animation="border" />
        ) : (
          <>
            <div>
        <strong>{data.name} {'>'} Matrículas</strong>
              <hr />
              <Table>
                <thead>
                  <tr>
                    <th>Produto</th>
                    <th className="text-center">Data de Matrícula</th>
                    <th />
                  </tr>
                </thead>
                <tbody>
                  {data.enrollment &&
                    data.enrollment.map((enrollment) => (
                      <tr key={enrollment.id}>
                        <td>{enrollment.product.title}</td>
                        <td className="text-center">
                          {format(
                            new Date(enrollment.created_at.replace(/ /g, "T")),
                            "dd/MM/yyyy - HH:mm"
                          )}
                        </td>
                        <td className="text-right">
                          {enrollment.inactive === null ? (
                            <button type="button" onClick={() => setCancel({
                              id: enrollment.id,
                              active: true
                            })}>Cancelar Matrícula</button>
                          ) : (
                            <>Matrícula Cancelada</>
                          )}
                        </td>
                      </tr>
                    ))}
                </tbody>
              </Table>
            </div>
          </>
        )}
        <button
          type="button"
          className="btn btn-primary btn-sm"
          disabled={loading}
          onClick={() => setAddRegister(true)}
        >
          Adicionar matrícula
        </button>
        <button
          type="button"
          className="btn btn-secondary btn-sm ml-2"
          disabled={loading}
          onClick={onClose}
        >
          Voltar
        </button>
      </div>
    </>
  );
}

export default RegistrationCustomer;
