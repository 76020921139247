import styled from 'styled-components';

export const Container = styled.div`
  display:flex;
    justify-content:flex-end;
    align-items:center;
    background:#f9f9f9;
    padding:20px 30px;
    border-bottom:1px solid #f0f0f0;
    div {
      padding:10px;
      border-radius:6px;
      background:#e9e9e9;
      color:${props => props.theme.primary};
      .avatar_user {
        padding:5px;
        border-radius:6px;
        margin-right:10px;
        background:${props => props.theme.primary};
        color:#fff;
        font-size:30px;
      }
    }
    button {
      font-size:22px;
      background:none;
      border:none;
      margin-left:4px;
      padding:10px;
      outline:none !important;
      color:${props => props.theme.primary};
      transition:350ms;
      &:hover {
        color:${props => props.theme.secondary};
      }
    }
`;
