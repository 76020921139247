import React, { useState } from "react";

import axios from "axios";

import { FaProductHunt } from "react-icons/fa";
import { Form, Alert, Spinner } from "react-bootstrap";


function AddUser({ onClose, onAdd }) {
  const [loading, setLoading] = useState(false);
  
  const [name, setName] = useState("");
  
  const [msg, setMsg] = useState({
    type: "",
    text: "",
  });

  async function handleSubmit() {
    if (name === "") {
      return setMsg({
        type: "danger",
        text: "Preencha todos os campos",
      });
    }
    setLoading(true);
    try {
      const response = await axios.post("/categories", {
        name
      });
      if (response.status === 203) {
        setMsg({
          type: "danger",
          text: response.data.msg,
        });
      } else {
        setName("");
        setMsg({
          type: "success",
          text: "Categoria Criada",
        });
        onAdd();
        onClose();
      }
    } catch (err) {
      if (err.response && err.response.data && err.response.data.msg) {
        setMsg({
          type: "danger",
          text: err.response.data.msg,
        });
      } else {
        setMsg({
          type: "danger",
          text:
            "Ocorreu um erro em nossos servidores, contate a equipe técnica.",
        });
      }
    }
    setLoading(false);
  }

  return (
    <>
      <div id="add_container">
        <span id="avatar">
          <FaProductHunt />
        </span>
        <div>
          <Form>
            {(msg.type !== "" || msg.text !== "") && (
              <Alert variant={msg.type}>{msg.text}</Alert>
            )}
            <div className="row">
              <Form.Group className="col-md-4">
                <Form.Label>Nome</Form.Label>
                <Form.Control
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  disabled={loading}
                />
              </Form.Group>
            </div>
            <button
              type="button"
              onClick={handleSubmit}
              className="btn btn-primary btn-sm"
              disabled={loading}
            >
              {loading ? <Spinner animation="border" /> : "Salvar"}
            </button>
            <button
              type="button"
              className="btn btn-light btn-sm ml-2"
              onClick={onClose}
              disabled={loading}
            >
              Cancelar
            </button>
          </Form>
        </div>
      </div>
    </>
  );
}

export default AddUser;
