import React, { useState } from "react";

import { Container } from "./styles";
import { Form, Button, Spinner } from "react-bootstrap";

import { Link } from "react-router-dom";

import axios from "axios";

import { useTranslation } from "react-i18next";

function ForgotPassword() {
  const [values, setValues] = useState({
    email: "",
  });
  const [errors, setErrors] = useState({
    email: "",
  });
  const [loading, setLoading] = useState(false);
  const [msg, setMsg] = useState("");

  const { t } = useTranslation();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setErrors({
      email: "",
    });
    setMsg("");

    let lock_errors = {
      email: "",
    };

    if (values.email.length < 4) {
      lock_errors.email = "Preencha esse campo";
    } else if (
      values.email.indexOf("@") === -1 ||
      values.email.indexOf(".") === -1
    ) {
      lock_errors.email = "E-mail inválido";
    }

    if (lock_errors.email !== "") {
      return setErrors(lock_errors);
    }
    setLoading(true);
    try {
      await axios.post(`/forgot-password`, {
        email: values.email,
      });

      setValues({
        email: "",
      });
      setMsg("Enviamos um e-mail para recuperar sua senha.");

      // const { data: dataUser } = await axios.get(
      //   `/me`
      // );
    } catch (err) {
      if (err.response && err.response.status === 404) {
        setMsg("Este e-mail não está cadastrado");
      } else if (err.response && err.response.status === 400) {
        setMsg(err.response.data.msg);
      } else if (err.response && err.response.status === 500) {
        setMsg("Ocorreu um erro em nossos servidores, tente mais tarde.");
      } else {
        setMsg("Ocorreu um erro, tente novamente");
      }
    }
    setLoading(false);
  };

  return (
    <Container>
      <div className="d-flex justify-content-center align-items-center flex-column">
        <img src="/assets/logo.png" alt="Tupi - Plataforma de vídeo" className="logo" />
        <h4>{t("Esqueceu sua senha?")}</h4>
      </div>
      <div className="d-flex justify-content-center align-items-center">
        <Form onSubmit={handleSubmit}>
          {msg !== "" ? (
            <Form.Text className="text-white text-center mb-2">{msg}</Form.Text>
          ) : (
            <Form.Text className="text-white text-center mb-2">
              {t("Digite seu e-mail")}
            </Form.Text>
          )}
          <Form.Group>
            <Form.Label>E-mail</Form.Label>
            <Form.Control
              value={values.email}
              onChange={(e) =>
                setValues({
                  ...values,
                  email: e.target.value,
                })
              }
              type="email"
              placeholder={t("Digite seu e-mail")}
              disabled={loading}
              required
            />
            {errors.email !== "" && (
              <Form.Text className="text-white">{errors.email}</Form.Text>
            )}
          </Form.Group>
          <Button
            variant="success"
            className="btn-block mt-4"
            type="submit"
            disabled={loading}
          >
            {loading ? <Spinner animation="border" /> : t("Recuperar Senha")}
          </Button>
          {!loading && (
            <Link to="/" className="text-white text-center mt-4 d-block">
              {t("Voltar")}
            </Link>
          )}
        </Form>
      </div>
    </Container>
  );
}

export default ForgotPassword;
