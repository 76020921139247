import React, { useState, useEffect } from "react";

import axios from "axios";

import { FaUser } from "react-icons/fa";
import { Form, Alert } from "react-bootstrap";

function AddUser({ onClose, onAdd }) {
  const [username, setUsername] = useState("");
  const [email, setEmail] = useState("");
  const [role, setRole] = useState("");
  const [password, setPassword] = useState("");

  const [roleOptions, setRoleOptions] = useState([]);

  const [msg, setMsg] = useState({
    type: "",
    text: "",
  });

  useEffect(() => {
    async function loadData() {
      const { data: dataRole } = await axios.get("/roles");
      setRoleOptions(dataRole);
    }
    loadData();
  }, []);

  async function handleSubmit() {
    if (password.length < 6) {
      return setMsg({
        type: "danger",
        text: "Senha deve ter no mínimo 06 carateres",
      });
    }
    try {
      const response = await axios.post("/users", {
        username,
        password,
        email,
        roles: [role],
      });
      if (response.status === 203) {
        setMsg({
          type: "danger",
          text: response.data.msg,
        });
      } else {
        setUsername("");
        setEmail("");
        setPassword("");
        setMsg({
          type: "success",
          text: "Usuário Criado",
        });
        onAdd();
      }
    } catch (err) {
      if (err.response && err.response.data && err.response.data.msg) {
        setMsg({
          type: "danger",
          text: err.response.data.msg,
        });
      } else {
        setMsg({
          type: "danger",
          text:
            "Ocorreu um erro em nossos servidores, contate a equipe técnica.",
        });
      }
    }
  }

  return (
    <>
      <div id="add_user_container">
        <span id="avatar">
          <FaUser />
        </span>
        <div>
          <Form>
            {(msg.type !== "" || msg.text !== "") && (
              <Alert variant={msg.type}>{msg.text}</Alert>
            )}
            <Form.Group>
              <Form.Label>Nome</Form.Label>
              <Form.Control
                value={username}
                onChange={(e) => setUsername(e.target.value)}
              />
            </Form.Group>
            <div className="row">
              <Form.Group className="col-md-6">
                <Form.Label>E-mail</Form.Label>
                <Form.Control
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  type="email"
                />
              </Form.Group>
              <Form.Group className="col-md-6">
                <Form.Label>Senha</Form.Label>
                <Form.Control
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  type="password"
                />
              </Form.Group>
            </div>
            <div className="row">
              <Form.Group className="col-md-6">
                <Form.Label>Perfil</Form.Label>
                <Form.Control
                  as="select"
                  value={role}
                  onChange={(e) => setRole(e.target.value)}
                >
                  <option value=""></option>
                  {roleOptions.length > 0 &&
                    roleOptions.map((rol, index) => (
                      <option key={index} value={rol.id}>
                        {rol.name}
                      </option>
                    ))}
                </Form.Control>
              </Form.Group>
            </div>
            <button type="button" onClick={handleSubmit} className="btn btn-warning btn-sm">
              Salvar
            </button>
            <button type="button" className="btn btn-light btn-sm ml-2" onClick={onClose}>
              Voltar
            </button>
          </Form>
        </div>
      </div>
    </>
  );
}

export default AddUser;
