import React, { useState, useEffect, useMemo } from "react";

import axios from "axios";

import ModalEmbed from "./ModalEmbed";
import { Form, Alert, Spinner, Button } from "react-bootstrap";

import { ImEmbed2, ImDownload, ImCopy } from "react-icons/im";
import { useTranslation } from "react-i18next";

function EditUser({ onClose, onUpdate, id }) {
  const [file, setFile] = useState("");
  const [image, setImage] = useState(null);
  const [title, setTitle] = useState("");
  const [categoryId, setCategoryId] = useState("");
  const [categories, setCategories] = useState([]);
  const [description, setDescription] = useState("");
  const [download, setDownload] = useState("");
  const [srcLink, setSrcLink] = useState("");

  const [entry_id, setEntry_id] = useState("");
  const [embed, setEmbed] = useState(false);
  const [thumbnailUrl, setThumbnailUrl] = useState("");
  const [linkUrl, setLinkUrl] = useState("");

  const [msgData, setMsgData] = useState({
    type: "",
    text: "",
  });
  const [loading, setLoading] = useState(false);
  const [company, setCompany] = useState(null);

  const [flavors, setFlavors] = useState(null);
  const [runLoadFlavorsAgain, setRunLoadFlavorsAgain] = useState(0);

  const { t } = useTranslation();

  const preview = useMemo(() => {
    return image ? URL.createObjectURL(image) : null;
  }, [image]);

  useEffect(() => {
    async function loadCategories() {
      try {
        const { data } = await axios.get(`/categories`);
        setCategories(data);
      } catch (error) {}
    }
    loadCategories();
  }, []);

  useEffect(() => {
    async function loadData() {
      const { data: dataUser } = await axios.get(`/me`);
      setCompany(dataUser.company);

      const { data } = await axios.get(
        `/courses/${dataUser.company.course_id}/videos/${id}`
      );
      setTitle(data.title);
      setFile(data.thumbnail);
      setDescription(data.description);
      setCategoryId(data.category_id);
      setDownload(data.download ? data.download : "");
      setSrcLink(
        `https://cdnapisec.kaltura.com/p/2601552/embedPlaykitJs/uiconf_id/45179411?iframeembed=true&entry_id=${data.entry_id}`
      );
      setEntry_id(data.entry_id);
      setThumbnailUrl(data.thumbnail);
      setLinkUrl(
        `${process.env.REACT_APP_API_URL}/embed/${dataUser.company.owner}/${data.entry_id}`
      );
    }
    loadData();
  }, [id]);

  useEffect(() => {
    async function loadFlavors() {
      if (entry_id) {
        const { data } = await axios.get(`/kaltura/flavors/${entry_id}`);

        setFlavors(data);
      }
    }
    loadFlavors();
  }, [entry_id, runLoadFlavorsAgain]);

  async function handleSubmitData(e) {
    e.preventDefault();
    setMsgData({
      type: "",
      text: "",
    });
    setLoading(true);
    try {
      const obj = new FormData();
      if (image !== null) {
        obj.append("file", image);
      }
      obj.append("title", title);
      obj.append("description", description);
      if (categoryId) {
        obj.append("category_id", categoryId);
      }
      const response = await axios.put(
        `/courses/${company.course_id}/videos/${id}`,
        obj
      );
      if (response.status === 203) {
        setMsgData({
          type: "danger",
          text: response.data.msg,
        });
      }
      setMsgData({
        type: "success",
        text: "Vídeo atualizado",
      });
      onUpdate();
    } catch (err) {
      if (err.response && err.response.data && err.response.data.msg) {
        setMsgData({
          type: "danger",
          text: err.response.data.msg,
        });
      } else {
        setMsgData({
          type: "danger",
          text: "Ocorreu um erro em nossos servidores, contate a equipe técnica.",
        });
      }
    }
    setLoading(false);
  }

  async function onDownloadFlavor(id) {
    const { data } = await axios.get(`/kaltura/flavor_download/${id}`);

    const link = document.createElement("a");
    link.href = data;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    return data;
  }

  async function onDeleteFlavor(id) {
    const { data } = await axios.delete(`/kaltura/flavor_delete/${id}`);
    setRunLoadFlavorsAgain(runLoadFlavorsAgain + 1);
    return data;
  }

  return (
    <>
      <ModalEmbed
        entry_id={entry_id}
        open={embed}
        setOpen={(e) => setEmbed(e)}
      />
      <div id="add_container">
        <span id="avatar">
          {srcLink !== "" && (
            <iframe
              type="text/javascript"
              title={`kaltura_video_${Math.random() * 1000}`}
              src={srcLink}
              style={{ width: "100%", height: "100%", minHeight: "400px" }}
              allowFullScreen
              frameBorder="0"
            ></iframe>
          )}
          <div className="d-flex mt-2 w-100">
            <Button
              type="button"
              className="w-50"
              variant="outline-primary"
              onClick={() => setEmbed(true)}
            >
              <ImEmbed2 /> Embed
            </Button>
            {download !== "" && (
              <a
                href={download}
                target="_blank"
                rel="noopener noreferrer"
                className="btn btn-outline-info ml-2 w-50"
              >
                <ImDownload /> Baixar
              </a>
            )}
          </div>
        </span>
        <div>
          <Form onSubmit={handleSubmitData}>
            {(msgData.type !== "" || msgData.text !== "") && (
              <Alert variant={msgData.type}>{msgData.text}</Alert>
            )}
            <div className="row">
              <div className="col-md-6">
                <div id="file_image">
                  <p>Thumbnail</p>
                  <label
                    htmlFor="custom-file"
                    style={{
                      backgroundImage: `url(${preview || file})`,
                      marginBottom: "0px",
                    }}
                  >
                    <h4>
                      {image !== null
                        ? t("Trocar imagem")
                        : t("Selecionar Imagem")}
                    </h4>
                  </label>
                  <input
                    type="file"
                    id="custom-file"
                    label={image !== null ? image.name : "Escoher"}
                    onChange={(e) => setImage(e.target.files[0])}
                    disabled={loading}
                    style={{ display: "none" }}
                  />
                  <small>Medidas 1280x720</small>
                </div>
              </div>
            </div>
            <div className="row">
              <Form.Group className="col-md-6">
                <Form.Label>{t("Título")}</Form.Label>
                <Form.Control
                  value={title}
                  onChange={(e) => setTitle(e.target.value)}
                  type="title"
                  disabled={loading}
                />
              </Form.Group>
              <Form.Group className="col-md-6">
                <Form.Label>{t("Descrição")}</Form.Label>
                <Form.Control
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
                  type="text"
                  disabled={loading}
                />
              </Form.Group>
            </div>
            <div className="row">
              <Form.Group className="col-md-6">
                <Form.Label>{t("Categoria")}</Form.Label>
                <Form.Control
                  value={categoryId}
                  onChange={(e) => setCategoryId(e.target.value)}
                  as="select"
                  disabled={loading}
                >
                  <option value="">{t("Selecione a categoria")}</option>
                  {categories.length > 0 &&
                    categories.map((e, i) => {
                      return (
                        <option value={e.id} key={i}>
                          {e.name}
                        </option>
                      );
                    })}
                </Form.Control>
              </Form.Group>
              <div className="col-md-6 form-group">
                <label class="form-label">Entry_id</label>
                <div
                  className="align-items-center d-flex justify-content-between"
                  style={{
                    border: "1px solid #ccc",
                    borderRadius: "5px",
                    padding: "3px",
                    fontWeight: "normal",
                  }}
                >
                  <span className="ml-2 mr-2">{entry_id}</span>
                  <button
                    type="button"
                    className="btn btn-dark btn-sm"
                    onClick={() => {
                      navigator.clipboard.writeText(entry_id);
                    }}
                  >
                    <ImCopy />
                  </button>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-6 form-group">
                <label class="form-label">Link do vídeo</label>
                <div
                  className="align-items-center d-flex justify-content-between"
                  style={{
                    border: "1px solid #ccc",
                    borderRadius: "5px",
                    padding: "3px",
                    fontWeight: "normal",
                  }}
                >
                  <span className="ml-2 mr-2">
                    {linkUrl.slice(0, 12) + "..."}
                  </span>
                  <button
                    type="button"
                    className="btn btn-dark btn-sm"
                    onClick={() => {
                      navigator.clipboard.writeText(linkUrl);
                    }}
                  >
                    <ImCopy />
                  </button>
                </div>
              </div>
              <div className="col-md-6 form-group">
                <label class="form-label">URL da Thumb</label>
                <div
                  className="align-items-center d-flex justify-content-between"
                  style={{
                    border: "1px solid #ccc",
                    borderRadius: "5px",
                    padding: "3px",
                    fontWeight: "normal",
                  }}
                >
                  <span className="ml-2 mr-2">
                    {thumbnailUrl.slice(0, 12) + "..."}
                  </span>
                  <button
                    type="button"
                    className="btn btn-dark btn-sm"
                    onClick={() => {
                      navigator.clipboard.writeText(thumbnailUrl);
                    }}
                  >
                    <ImCopy />
                  </button>
                </div>
              </div>
            </div>
            <button
              type="submit"
              disabled={loading}
              className="btn btn-warning btn-sm"
            >
              {loading ? <Spinner animation="border" /> : t("Salvar")}
            </button>
            <button
              type="button"
              className="btn btn-light btn-sm ml-2"
              disabled={loading}
              onClick={onClose}
            >
              {t("Voltar")}
            </button>
          </Form>
        </div>
      </div>

      <div id="add_container">
        <>
          <h1>Flavors</h1>
        </>
        <table id="flavor-table" class="table table-hover">
          <thead>
            <tr>
              <th scope="col">{t("Nome")}</th>
              <th scope="col">{t("Dimensões")}</th>
              <th scope="col">{t("Formato")}</th>
              <th scope="col">{t("Tamanho")}</th>
              <th scope="col">{t("Ações")}</th>
            </tr>
          </thead>
          <tbody>
            {flavors &&
              flavors.map((item) => (
                <tr key={item.id}>
                  <td>{item.name}</td>
                  <td>{`${item.width}x${item.height}`}</td>
                  <td>{item.fileExt}</td>
                  <td>{(item.size / 1024).toFixed(2)} MB</td>
                  <div
                    style={{
                      flexDirection: "row",
                      alignContent: "center",
                      alignItems: "center",
                      alignSelf: "center",
                      justifySelf: "center",
                    }}
                  >
                    <button
                      type="button"
                      class="btn btn-secondary"
                      onClick={() => onDownloadFlavor(item.id)}
                    >
                      {t("Baixar")}
                    </button>{" "}
                    {item.name !== "Source" && (
                      <button
                        type="button"
                        class="btn btn-danger"
                        onClick={() => onDeleteFlavor(item.id)}
                      >
                        {t("Excluir")}
                      </button>
                    )}
                  </div>
                </tr>
              ))}
          </tbody>
        </table>
      </div>
    </>
  );
}

export default EditUser;
