import React, { useState } from "react";

import { Container } from "./styles";
import { Form, Button, Spinner } from "react-bootstrap";

import { useHistory } from "react-router-dom";

import axios from "axios";

function Recovery({ match }) {
  const history = useHistory();
  const { token } = match.params;
  const [values, setValues] = useState({
    password: "",
  });
  const [errors, setErrors] = useState({
    password: "",
  });
  const [loading, setLoading] = useState(false);
  const [msg, setMsg] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();
    setErrors({
      password: "",
    });
    setMsg("");

    let lock_errors = {
      password: "",
    };

    if (values.password.length < 6) {
      lock_errors.password = "Mínimo de 06 caracteres";
    }

    if (lock_errors.password !== "") {
      return setErrors(lock_errors);
    }
    setLoading(true);
    try {
      await axios.post(
        `/recovery-password`,
        {
          token,
          password: values.password,
        }
      );

      setValues({
        password: '',
      });
      setMsg('Sua senha foi atualizada com sucesso.');
      setTimeout(() => {
        history.push('/');
      }, 3000)

      // const { data: dataUser } = await axios.get(
      //   `/me`
      // );
    } catch (err) {
      if (err.response && err.response.status === 404) {
        setMsg("Este e-mail não está cadastrado");
      } else if (err.response && err.response.status === 400) {
        setMsg(err.response.data.msg);
      } else if (err.response && err.response.status === 500) {
        setMsg("Ocorreu um erro em nossos servidores, tente mais tarde.");
      } else {
        setMsg("Ocorreu um erro, tente novamente");
      }
    }
    setLoading(false);
  };

  return (
    <Container>
      <div className="d-flex justify-content-center align-items-center flex-column">
        <img src="/assets/logo.png" alt="Tupi - Plataforma de vídeo" className="logo" />
        <h4>Recuperar conta</h4>
      </div>
      <div className="d-flex justify-content-center align-items-center">
        <Form onSubmit={handleSubmit}>
          {msg !== "" ? (
            <Form.Text className="text-white text-center mb-2">
              {msg}
            </Form.Text>
          ) : (
            <Form.Text className="text-white text-center mb-2">
              Digite sua nova Senha
            </Form.Text>
          )}
          <Form.Group>
            <Form.Label>Senha</Form.Label>
            <Form.Control
              value={values.password}
              onChange={(e) =>
                setValues({
                  ...values,
                  password: e.target.value,
                })
              }
              type="password"
              placeholder="Digite sua nova senha"
              disabled={loading}
              required
            />
            {errors.password !== "" && (
              <Form.Text className="text-white">{errors.password}</Form.Text>
            )}
          </Form.Group>
          <Button
            variant="success"
            className="btn-block mt-4"
            type="submit"
            disabled={loading}
          >
            {loading ? <Spinner animation="border" /> : "Recuperar Conta"}
          </Button>
        </Form>
      </div>
    </Container>
  );
}

export default Recovery;
