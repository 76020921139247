import styled from 'styled-components';

export const Container = styled.header`
  background:${props => props.theme.background};
  display:flex;
  flex-direction:column !important;
  align-items:center !important;
  justify-content:flex-start !important;
  padding:30px 20px;
  position:fixed;
  width:100%;
  max-width:200px;
  top:0;
  left:-200px;
  border-right:4px solid ${props => props.theme.primary};
  height:100%;
  transition:500ms;
  z-index:100;
  &.opened {
    left:0;
  }
  button#open_menu {
    position:absolute;
    top:20px;
    left:220px;
    width:50px;
    height:50px;
    border-radius:50%;
    display:flex;
    font-size:30px;
    justify-content:center;
    align-items:center;
    outline:none !important;
    background:${props => props.theme.primary};
    color:${props => props.theme.secondary};
    border:none;
  }
  img {
    width:100%;
    max-width:100px;
    margin:20px auto;
  }
  nav {
    color:${props => props.theme.primary};
    padding:0;
    margin:0;
    ul {
      padding:0;
      margin:0;
      border-top:1px solid ${props => props.theme.primary};
      list-style:none;
      display:flex;
      flex-direction:column;
      align-items:center;
      justify-content:center;
      a, button {
        background:none;
        border:none;
        padding:15px 15px;
        transition:300ms;
        font-size:14px;
        display:block;
        text-align:left;
        color:${props => props.theme.primary} !important;
        text-decoration:none;
        cursor:pointer;
        width:100%;
        &:hover {
          background:${props => props.theme.secondary};
        }
      }
    }
  }
  @media screen and (max-width:900px) {
    & {
      padding:30px;
      flex-direction:row;
      flex-wrap:wrap;
      nav {
        ul {
          flex-direction:row;
          overflow:auto;
          flex-wrap:wrap;
        }
      }
    }
  }
`;
