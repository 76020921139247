import React from "react";

import { Table } from 'react-bootstrap';

function TableCoupons({ coupons, addCoupon, editCoupon, onDelete }) {
  return (
    <>
      <button type="button" onClick={addCoupon}>
        Novo Cupom de desconto
      </button>
      <Table responsive hover>
        <thead>
          <tr>
            <th>Título</th>
            <th>Code</th>
            <th className="text-right">Ações</th>
          </tr>
        </thead>
        <tbody>
        {coupons &&
          coupons.map((coupon) => (
            <tr key={coupon.id}>
              <td>{coupon.title}</td>
              <td>{coupon.code}</td>
              <td>
                <div className="d-flex justify-content-end">
                  <button type="button" onClick={() => editCoupon(coupon.id)}>
                    Editar
                  </button>
                  <button type="button" className="ml-2" onClick={() => onDelete(coupon)}>
                    Excluir
                  </button>
                </div>
              </td>
            </tr>
          ))}
          </tbody>
      </Table>
    </>
  );
}

export default TableCoupons;
