import React, { useState, useEffect } from "react";

import axios from "axios";

import { FaProductHunt } from "react-icons/fa";
import { Form, Alert, Spinner } from "react-bootstrap";

function EditUser({ onClose, onUpdate, id }) {
  const [name, setName] = useState("");

  const [msgData, setMsgData] = useState({
    type: "",
    text: "",
  });
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    async function loadData() {
      const { data } = await axios.get(`/categories/${id}`);
      setName(data.name);
    }
    loadData();
  }, [id]);

  async function handleSubmitData(e) {
    e.preventDefault();
    setMsgData({
      type: "",
      text: "",
    });
    setLoading(true);
    try {
      const response = await axios.put(`/categories/${id}`, {
        name,
      });
      if (response.status === 203) {
        setMsgData({
          type: "danger",
          text: response.data.msg,
        });
      }
      setMsgData({
        type: "success",
        text: "Categoria atualizada",
      });
      onUpdate();
    } catch (err) {
      if (err.response && err.response.data && err.response.data.msg) {
        setMsgData({
          type: "danger",
          text: err.response.data.msg,
        });
      } else {
        setMsgData({
          type: "danger",
          text:
            "Ocorreu um erro em nossos servidores, contate a equipe técnica.",
        });
      }
    }
    setLoading(false);
  }

  return (
    <>
      <div id="add_container">
        <span id="avatar">
          <FaProductHunt />
        </span>
        <div>
          <Form onSubmit={handleSubmitData}>
            {(msgData.type !== "" || msgData.text !== "") && (
              <Alert variant={msgData.type}>{msgData.text}</Alert>
            )}
            <div className="row">
              <Form.Group className="col-md-4">
                <Form.Label>Nome</Form.Label>
                <Form.Control
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  type="name"
                  disabled={loading}
                />
              </Form.Group>
            </div>
            <button
              type="submit"
              disabled={loading}
              className="btn btn-primary btn-sm"
            >
              {loading ? <Spinner animation="border" /> : "Salvar"}
            </button>
            <button
              type="button"
              className="btn btn-light btn-sm ml-2"
              null
              disabled={loading}
              onClick={onClose}
            >
              Voltar
            </button>
          </Form>
        </div>
      </div>
    </>
  );
}

export default EditUser;
