import React from "react";

import { useSelector } from 'react-redux';
import { Table } from 'react-bootstrap';

function TableUsers({ users, addUser, editUser, onDelete }) {
  const { user: authenticated } = useSelector(state => state.user);
  return (
    <>
      <button type="button" onClick={addUser}>Novo Usuário</button>
      <Table responsive hover>
        <thead>
          <tr>
            <th>Nome</th>
            <th>Email</th>
            <th className="text-right">Ações</th>
          </tr>  
        </thead>
        <tbody>
        {users &&
          users.map((user) => (
            <tr key={user.id}>
              <td>{user.username}</td>
              <td>{user.email}</td>
              <td>
                <div className="d-flex justify-content-end">
                  {user.id !== authenticated.id && (
                    <>
                      <button type="button" onClick={() => editUser(user.id)}>Editar</button>
                      <button type="button" onClick={() => onDelete(user)}>Excluir</button>
                    </>
                  )}
                </div>
              </td>
            </tr>
          ))}
          </tbody>
      </Table>
    </>
  );
}

export default TableUsers;
