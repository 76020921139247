import React, { useState, useEffect, useMemo } from "react";

import axios from "axios";

import ModalEmbed from "./ModalEmbed";
import { Form, Alert, Spinner, Button } from "react-bootstrap";

import { ImEmbed2, ImDownload, ImCopy } from "react-icons/im";

function EditUser({ onClose, onUpdate, course_id, id }) {
  const [file, setFile] = useState("");
  const [image, setImage] = useState(null);
  const [title, setTitle] = useState("");
  const [categoryId, setCategoryId] = useState("");
  const [categories, setCategories] = useState([]);
  const [description, setDescription] = useState("");
  const [download, setDownload] = useState("");
  const [srcLink, setSrcLink] = useState("");

  const [entry_id, setEntry_id] = useState("");
  const [embed, setEmbed] = useState(false);

  const [msgData, setMsgData] = useState({
    type: "",
    text: "",
  });
  const [loading, setLoading] = useState(false);

  const preview = useMemo(() => {
    return image ? URL.createObjectURL(image) : null;
  }, [image]);

  useEffect(() => {
    async function loadCategories() {
      try {
        const { data } = await axios.get(`/categories`);
        setCategories(data);
      } catch (error) {}
    }
    loadCategories();
  }, []);

  useEffect(() => {
    async function loadData() {
      const { data } = await axios.get(`/courses/${course_id}/videos/${id}`);
      setTitle(data.title);
      setFile(data.thumbnail);
      setDescription(data.description);
      setCategoryId(data.category_id);
      setDownload(data.download ? data.download : "");
      setSrcLink(
        `https://cdnapisec.kaltura.com/p/2601552/embedPlaykitJs/uiconf_id/45179411?iframeembed=true&entry_id=${data.entry_id}`
      );
      setEntry_id(data.entry_id);
    }
    loadData();
  }, [course_id, id]);

  async function handleSubmitData(e) {
    e.preventDefault();
    setMsgData({
      type: "",
      text: "",
    });
    setLoading(true);
    try {
      const obj = new FormData();
      if (image !== null) {
        obj.append("file", image);
      }
      obj.append("title", title);
      obj.append("description", description);
      if(categoryId){
        obj.append("category_id", categoryId);
      }
      const response = await axios.put(
        `/courses/${course_id}/videos/${id}`,
        obj
      );
      if (response.status === 203) {
        setMsgData({
          type: "danger",
          text: response.data.msg,
        });
      }
      setMsgData({
        type: "success",
        text: "Vídeo atualizado",
      });
      onUpdate();
    } catch (err) {
      if (err.response && err.response.data && err.response.data.msg) {
        setMsgData({
          type: "danger",
          text: err.response.data.msg,
        });
      } else {
        setMsgData({
          type: "danger",
          text:
            "Ocorreu um erro em nossos servidores, contate a equipe técnica.",
        });
      }
    }
    setLoading(false);
  }

  return (
    <>
      <ModalEmbed
        entry_id={entry_id}
        open={embed}
        setOpen={(e) => setEmbed(e)}
      />
      <div id="add_container">
        <span id="avatar">
          {srcLink !== "" && (
            <iframe
              type="text/javascript"
              title={`kaltura_video_${Math.random() * 1000}`}
              src={srcLink}
              style={{ width: "100%", height: "100%", minHeight: "400px" }}
              allowFullScreen
              frameBorder="0"
            ></iframe>
          )}
          <div className="d-flex mt-2 w-100">
            <Button
              type="button"
              className="w-50"
              variant="outline-primary"
              onClick={() => setEmbed(true)}
            >
              <ImEmbed2 /> Embed
            </Button>
            {download !== "" && (
              <a
                href={download}
                target="_blank"
                rel="noopener noreferrer"
                className="btn btn-outline-info ml-2 w-50"
              >
                <ImDownload /> Baixar
              </a>
            )}
          </div>
        </span>
        <div>
          <Form onSubmit={handleSubmitData}>
            {(msgData.type !== "" || msgData.text !== "") && (
              <Alert variant={msgData.type}>{msgData.text}</Alert>
            )}
            <div className="row">
              <div className="col-md-6">
                <div id="file_image">
                  <p>Thumbnail</p> 
                  <label
                    htmlFor="custom-file"
                    style={{
                      backgroundImage: `url(${preview || file})`,
                      marginBottom: "0px",
                    }}
                  >
                    <h4>
                      {image !== null ? "Trocar imagem" : "Selecionar Imagem"}
                    </h4>
                  </label>
                  <input
                    type="file"
                    id="custom-file"
                    label={image !== null ? image.name : "Escoher"}
                    onChange={(e) => setImage(e.target.files[0])}
                    disabled={loading}
                    style={{ display: "none" }}
                  />
                  <small>Medidas 1280x720</small>
                </div>
              </div>
            </div>
            <div className="row">
              <Form.Group className="col-md-6">
                <Form.Label>Título</Form.Label>
                <Form.Control
                  value={title}
                  onChange={(e) => setTitle(e.target.value)}
                  type="title"
                  disabled={loading}
                />
              </Form.Group>
              <Form.Group className="col-md-6">
                <Form.Label>Descrição</Form.Label>
                <Form.Control
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
                  type="text"
                  disabled={loading}
                />
              </Form.Group>
            </div>
            <div className="row">
              <Form.Group className="col-md-6">
                <Form.Label>Categoria</Form.Label>
                <Form.Control
                  value={categoryId}
                  onChange={(e) => setCategoryId(e.target.value)}
                  as="select"
                  disabled={loading}
                >
                  <option value="">Selecione a categoria</option>
                  {categories.length > 0 &&
                    categories.map((e, i) => {
                      return (
                        <option value={e.id} key={i}>
                          {e.name}
                        </option>
                      );
                    })}
                </Form.Control>
              </Form.Group>
              <div className="col-md-6 form-group">
                <label class="form-label">Entry_id</label>
                <div
                  className="align-items-center d-flex justify-content-between"
                  style={{
                    border: "1px solid #ccc",
                    borderRadius: "5px",
                    padding: "3px",
                    fontWeight: "normal",
                  }}
                >
                  <span className="ml-2 mr-2">{entry_id}</span>
                  <button
                    type="button"
                    className="btn btn-dark btn-sm"
                    onClick={() => {
                      navigator.clipboard.writeText(entry_id);
                    }}
                  >
                    <ImCopy />
                  </button>
                </div>
              </div>
            </div>
            <button
              type="submit"
              disabled={loading}
              className="btn btn-warning btn-sm"
            >
              {loading ? <Spinner animation="border" /> : "Salvar"}
            </button>
            <button
              type="button"
              className="btn btn-light btn-sm ml-2"
              disabled={loading}
              onClick={onClose}
            >
              Voltar
            </button>
          </Form>
        </div>
      </div>
    </>
  );
}

export default EditUser;
