import React, { useState, useEffect } from 'react';

import TableCategory from './TableCategory';
import AddCategory from './AddCategory';
import EditCategory from './EditCategory';
import axios from 'axios';

import { MdWarning } from 'react-icons/md';
import { Modal, Alert } from 'react-bootstrap';

function Categories() {
  const [loading, setLoading] = useState(false);
  const [msg, setMsg] = useState('');
  const [categories, setCategories] = useState([]);
  const [edit, setEdit] = useState({
    active: false,
    id: null,
  });
  const [deleteCategory, setDeleteCategory] = useState({
    active: false,
    category: {},
  });
  const [addCategory, setAddCategory] = useState(false); 

  async function loadCategories() {
    const { data } = await axios.get('/categories');
    setCategories(data);
  }

  useEffect(() => {
    loadCategories();
  }, []); // eslint-disable-line

  async function handleDelete() {
    setMsg('');
    setLoading(true);
    try {
      await axios.delete(`/categories/${deleteCategory.category.id}`);
      setCategories(categories.filter(u => u.id !== deleteCategory.category.id));
      setDeleteCategory({
        active: false,
        category: {},
      });
    }catch(err) {
      setMsg('Ocorreu um erro em nossos servidores, contate a equipe técnica')
    }
    setLoading(false);
  }

  return (
    <div id="container">
      <Modal show={deleteCategory.active} onHide={() => setDeleteCategory({
        active: false,
        category: {},
      })} centered>
        <Modal.Body>
          <div className="text-center">
            <MdWarning style={{ fontSize: '80px' }}/>
          </div>
          {msg !== '' && (
            <Alert variant="danger">
              {msg}
            </Alert>
          )}
          <h4 className="text-center">
            Você tem certeza que deseja excluir o produto <strong>{deleteCategory.category.title}</strong>?
          </h4>
          <div className="text-center">
            <button type="button" className="btn btn-outline-danger" disabled={loading} onClick={handleDelete}>
              Sim, excluir
            </button>
            <button type="button" className="btn btn-danger ml-2" disabled={loading} onClick={() => setDeleteCategory({
                active: false,
                category: {},
              })}>
              Cancelar
            </button>
          </div>
        </Modal.Body>
      </Modal>
      {!edit.active && !addCategory && (
        <TableCategory categories={categories} addCategory={() => setAddCategory(true)} editCategory={(e) => setEdit({
          active: true,
          id: e,
        })} onDelete={(e) => setDeleteCategory({
          active: true,
          category: e,
        })}/>
      )}
      {addCategory && (
        <AddCategory onAdd={() => loadCategories()} onClose={() => setAddCategory(false)}/>
      )}
      {edit.active && (
        <EditCategory onUpdate={() => loadCategories()} onClose={() => setEdit({
          active: false,
          id: null,
        })} id={edit.id}/>
      )}
    </div>
  );
}

export default Categories;