import React, { useState, useEffect } from 'react';

import TableUsers from './TableUsers';
import AddUser from './AddUser';
import EditUser from './EditUser';
import axios from 'axios';

import { MdWarning } from 'react-icons/md';
import { Modal, Alert } from 'react-bootstrap';

function Users() {
  const [loading, setLoading] = useState(false);
  const [msg, setMsg] = useState('');
  const [users, setUsers] = useState([]);
  const [edit, setEdit] = useState({
    active: false,
    id: null,
  });
  const [deleteUser, setDeleteUser] = useState({
    active: false,
    user: {},
  });
  const [addUser, setAddUser] = useState(false); 

  async function loadUsers() {
    const { data } = await axios.get('/users');
    setUsers(data);
  }

  useEffect(() => {
    loadUsers();
  }, []); // eslint-disable-line

  async function handleDelete() {
    setMsg('');
    setLoading(true);
    try {
      await axios.delete(`/users/${deleteUser.user.id}`);
      setUsers(users.filter(u => u.id !== deleteUser.user.id));
      setDeleteUser({
        active: false,
        user: {},
      });
    }catch(err) {
      setMsg('Ocorreu um erro em nossos servidores, contate a equipe técnica')
    }
    setLoading(false);
  }

  return (
    <div id="user_container">
      <Modal show={deleteUser.active} onHide={() => setDeleteUser({
        active: false,
        user: {},
      })} centered>
        <Modal.Body>
          <div className="text-center">
            <MdWarning style={{ fontSize: '80px' }}/>
          </div>
          {msg !== '' && (
            <Alert variant="danger">
              {msg}
            </Alert>
          )}
          <h4 className="text-center">
            Você tem certeza que deseja excluir o usuário <strong>{deleteUser.user.username}</strong>?
          </h4>
          <div className="text-center">
            <button type="button" className="btn btn-outline-danger" disabled={loading} onClick={handleDelete}>
              Sim, excluir
            </button>
            <button type="button" className="btn btn-danger ml-2" disabled={loading} onClick={() => setDeleteUser({
                active: false,
                user: {},
              })}>
              Cancelar
            </button>
          </div>
        </Modal.Body>
      </Modal>
      {!edit.active && !addUser && (
        <TableUsers users={users} addUser={() => setAddUser(true)} editUser={(e) => setEdit({
          active: true,
          id: e,
        })} onDelete={(e) => setDeleteUser({
          active: true,
          user: e,
        })}/>
      )}
      {addUser && (
        <AddUser onAdd={() => loadUsers()} onClose={() => setAddUser(false)}/>
      )}
      {edit.active && (
        <EditUser onUpdate={() => loadUsers()} onClose={() => setEdit({
          active: false,
          id: null,
        })} id={edit.id}/>
      )}
    </div>
  );
}

export default Users;