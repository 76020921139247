import React, { useState } from 'react';

import { Content } from './styles';

import Coupons from './components/Coupons';
import UserHeader from '../components/SubHeader';

function Register() {
  const [current, ] = useState(1);

  return (
      <Content>
        <UserHeader />
        <div id="main_content">
          <h2>Cupom de desconto</h2>
          {current === 1 && <Coupons />}
        </div>
      </Content>
  );
}

export default Register;