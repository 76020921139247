import styled from 'styled-components';

export const Content = styled.div`
  display:flex;
  width: 100%;
  flex-direction:column;
  #header_content {
    display:flex;
    justify-content:flex-end;
    align-items:center;
    background:#f9f9f9;
    padding:20px 30px;
    border-bottom:1px solid #f0f0f0;
    div {
      padding:10px;
      border-radius:6px;
      background:#e9e9e9;
      color:${props => props.theme.primary};
      .avatar_user {
        padding:5px;
        border-radius:6px;
        margin-right:10px;
        background:${props => props.theme.primary};
        color:#fff;
        font-size:30px;
      }
    }
  }
  #main_content {
    padding:40px;
    @media screen and (max-width: 600px) {
    & {
         padding:15px;
    }
}
    display:flex;
    flex-direction:column;
    flex:1;
    background:#fff;
    overflow:hidden;
    h2 {
      color:${props => props.theme.primary};
    }
  }
`; 
