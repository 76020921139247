import React, { useState, useEffect } from "react";
import axios from "axios";
import { Modal, Button, Form } from "react-bootstrap";

import { MdContentCopy } from "react-icons/md";

function ModalEmbed({ entry_id, open, setOpen }) {
  const randomId = Math.round(Math.random() * 1000001);
  const [copiedScript, setCopiedScript] = useState(false);
  const [clipboardScriptLink, setClipboardScriptLink] = useState(false);
  const [company, setCompany] = useState(null);
  const [embedType, setEmbedType] = useState("iframe");

  function copyToClipBoard(element, successHook) {
    var copyText = document.getElementById(element);
    copyText.select();
    copyText.setSelectionRange(0, 99999);
    document.execCommand("copy");

    // if (textArea === embed_textarea)
    successHook(true);
  }

  useEffect(() => {
    async function loadUser() {
      try {
        const { data: dataUser } = await axios.get(`/me`);
        setCompany(dataUser.company);
      } catch (err) {}
    }

    loadUser();
  }, []);
  return (
    <Modal show={open} onHide={() => setOpen(false)} size="lg" centered>
      <Modal.Body>
        {company && (
          <>
            <textarea
              value={
                embedType === "script"
                  ? `<div id="flix_player_${randomId}" style="width: 560px;height: 395px"></div>
<script src="https://admin.flix.tupi.io/${
                      company.player
                        ? `embed_player_${company.player}.min.js`
                        : "embed_player.min.js"
                    }"></script>
<script>
  try {
    var flixPlayer = FlixPlayer.setup({
      targetId: "flix_player_${randomId}",
      provider: {
        partnerId: 2601552,
        uiConfId: ${company.player ? company.player : "47235343"}
      }
    });
    flixPlayer.loadMedia({ entryId: '${entry_id}' });
  }catch(err) {
    console.log(err);
  }
</script>
        `
                  : `<iframe
    type="text/javascript"
    src="https://cdnapisec.kaltura.com/p/2601552/embedPlaykitJs/uiconf_id/45179411?iframeembed=true&entry_id=${entry_id}"
    style="width: 100%; aspect-ratio: 16/9"
    allowfullscreen
    webkitallowfullscreen
    mozAllowFullScreen
    allow="autoplay *; fullscreen *; encrypted-media *"
    frameborder="0"
></iframe>
      `
              }
              readOnly
              rows="15"
              style={{
                width: "100%",
                background: "#ececec",
                padding: "0px 30px",
                resize: "none",
                borderColor: "#017bfe",
                borderRadius: "6px",
                color: "#00356d",
              }}
              id="embed_textarea"
            ></textarea>
            <textarea
              value={`${process.env.REACT_APP_API_URL}/embed/${company.owner}/${entry_id}`}
              onChange={() => {}}
              rows="0"
              style={{
                position: "absolute",
                // width: "100%",
                // make position outside dom
                top: "-9999px",
                left: "-9999px",
                background: "#ececec",
                padding: "0px 30px",
                resize: "none",
                borderColor: "#017bfe",
                borderRadius: "6px",
                color: "#00356d",
              }}
              id="embed_link_textarea"
            />
          </>
        )}
        <hr />
        <div className="d-flex">
          <select
            className="form-select mr-2"
            aria-label="Default select example"
            onChange={(e) => setEmbedType(e.target.value)}
            value={embedType}
          >
            <option value="iframe">Iframe</option>
            <option value="script">Script</option>
          </select>
          <Button
            variant={copiedScript ? "outline-secondary" : "primary"}
            onClick={() => copyToClipBoard("embed_textarea", setCopiedScript)}
          >
            <MdContentCopy /> {copiedScript ? "Copiado" : "Copiar código embed"}
          </Button>
          {company && (
            <Button
              variant={clipboardScriptLink ? "outline-secondary" : "primary"}
              onClick={() =>
                copyToClipBoard("embed_link_textarea", setClipboardScriptLink)
              }
              className="ml-2"
            >
              <MdContentCopy />{" "}
              {clipboardScriptLink ? "Copiado" : "Copiar link do vídeo"}
            </Button>
          )}
          <Button
            variant="outline-primary"
            className="ml-2"
            onClick={() => setOpen(false)}
          >
            Fechar
          </Button>
        </div>
      </Modal.Body>
    </Modal>
  );
}

export default ModalEmbed;
