import React, { useState, useEffect } from "react";

import axios from "axios";

import { Spinner, Form } from "react-bootstrap";
import { normalizeCpf } from "../normalize";
import { useTranslation } from "react-i18next";

function ViewCustomer({ onClose, onUpdate, id }) {
  const [data, setData] = useState({});
  const [dataNew, setDataNew] = useState({});
  const [editActive, setEditActive] = useState(false);
  const [loading, setLoading] = useState(false);

  const [editPassword, setEditPassword] = useState(false);
  const { t } = useTranslation();

  useEffect(() => {
    async function loadData() {
      setLoading(true);
      const { data: dataCustomer } = await axios.get(`/customers/${id}`);
      setData(dataCustomer[0]);
      setDataNew({ ...dataCustomer[0], password: '' });
      setLoading(false);
    }
    loadData();
  }, [id]);

  async function handleUpdate() {
    setLoading(true);
    try {
      await axios.put(`/customers/${id}`, {
        ...dataNew,
        cpf: dataNew.cpf.replace(/[^\d]/g, ''),
        password: editPassword ? dataNew.password : undefined,
      });
      setData(dataNew);
      setEditActive(false);
      onUpdate();
    } catch (err) {}
    setLoading(false);
  }

  return (
    <>
      <div id="add_user_container">
        {loading ? (
          <Spinner animation="border" />
        ) : (
          <>
            {!editActive && (
              <div>
                <h4 className="mt-4 mb-2">Dados Gerais</h4>
                <div
                  className="mt-4 mb-2"
                  style={{
                    padding: "15px",
                    background: "#fff",
                    borderRadius: "6px",
                  }}
                >
                  <strong>Nome: </strong>
                  <span>{data.name}</span>
                </div>
                <div
                  className="mt-2 mb-2"
                  style={{
                    padding: "15px",
                    background: "#fff",
                    borderRadius: "6px",
                  }}
                >
                  <strong>E-mail: </strong>
                  <span>{data.email}</span>
                </div>
                <div
                  className="mt-2 mb-4"
                  style={{
                    padding: "15px",
                    background: "#fff",
                    borderRadius: "6px",
                  }}
                >
                  <strong>CPF: </strong>
                  <span>{normalizeCpf(data.cpf)}</span>
                </div>
                <button
                  type="button"
                  className="btn btn-primary btn-sm"
                  disabled={loading}
                  onClick={() => setEditActive(true)}
                >
                  Editar
                </button>
                <button
                  type="button"
                  className="btn btn-secondary btn-sm ml-2"
                  disabled={loading}
                  onClick={onClose}
                >
                  Voltar
                </button>
              </div>
            )}
            {editActive && (
              <div>
                <h4 className="mt-4 mb-2">Dados Gerais</h4>
                <div>
                  <Form.Group>
                    <Form.Label>Nome</Form.Label>
                    <Form.Control
                      value={dataNew.name}
                      onChange={(e) =>
                        setDataNew({ ...dataNew, name: e.target.value })
                      }
                    />
                  </Form.Group>
                </div>
                <div>
                  <Form.Group>
                    <Form.Label>E-mail</Form.Label>
                    <Form.Control
                      value={dataNew.email}
                      onChange={(e) =>
                        setDataNew({ ...dataNew, email: e.target.value })
                      }
                    />
                  </Form.Group>
                </div>
                <div>
                  <Form.Group>
                    <Form.Label>CPF</Form.Label>
                    <Form.Control
                      value={dataNew.cpf}
                      onChange={(e) =>
                        setDataNew({
                          ...dataNew,
                          cpf: normalizeCpf(e.target.value),
                        })
                      }
                    />
                  </Form.Group>
                </div>
                <div className="mb-4">
                  {editPassword ? (
                    <Form.Group>
                      <Form.Label>{t("Senha")}</Form.Label>
                      <Form.Control
                        value={dataNew.password}
                        onChange={(e) =>
                          setDataNew({ ...dataNew, password: e.target.value })
                        }
                      />
                    </Form.Group>
                  ) : (
                    <button type="button" onClick={() => setEditPassword(true)}>
                      {t("Editar Senha")}
                    </button>
                  )}
                </div>
                <button
                  type="button"
                  disabled={
                    loading ||
                    dataNew.cpf.length < 14 ||
                    (editPassword && dataNew.password && dataNew.password.length < 6) ||
                    dataNew.email.length < 4 ||
                    dataNew.name.length < 3
                  }
                  onClick={handleUpdate}
                  className="btn btn-warning btn-sm"
                >
                  Salvar
                </button>
                <button
                  type="button"
                  disabled={loading}
                  onClick={() => setEditActive(false)}
                  className="btn btn-light btn-sm ml-2"
                >
                  Cancelar
                </button>
              </div>
            )}
          </>
        )}
      </div>
    </>
  );
}

export default ViewCustomer;
