import styled, { keyframes } from "styled-components";

const FadeLogo = keyframes`
  0%{
    opacity:0;
    transform:translateX(-15px);
  }100%{
    opacity:1;
    transform:translateX(0px);
  }
`;
const FadeForm = keyframes`
  0%{
    opacity:0;
    transform:translateX(15px);
  }100%{
    opacity:1;
    transform:translateX(0px);
  }
`;

export const Container = styled.div`
  display: grid;
  grid-template-columns: 1fr 2fr;
  min-height: 100vh;
  overflow: hidden;
  flex: 1;
  img.logo {
    width: 100%;
    max-width: 200px;
    & + h4 {
      color: #4c1616;
      margin-top: 10px;
      font-size: 14px;
    }
  }
  @media screen and (max-width: 900px) {
    & {
      grid-template-columns: 1fr;
    }
  }
  > div {
    padding: 15px;
    background: #fff;
    &:nth-child(1) {
      animation: ${FadeLogo} 600ms linear;
    }
    &:nth-child(2) {
      background: #f1f1f1;
      animation: ${FadeForm} 600ms linear;
      animation-delay: 200ms;
      animation-fill-mode: backwards;
    }
    form {
      padding: 30px;
      border-radius: 6px;
      background: #bd1817;
      min-width: 400px;
      color: #fff;
      small {
        font-weight: 600;
      }
    }
  }
`;
