import React from "react";

import { Content } from "./styles";

import Video from "./components/Video";
import UserHeader from '../components/SubHeader';
import { useTranslation } from "react-i18next";

function Register({ match }) {
  const { t } = useTranslation();
  
  return (
    <Content>
      <UserHeader />
      <div id="main_content">
        <h2>{t("Vídeos")}</h2>
        <Video
          product_id={match.params.product_id}
          course_id={match.params.course_id}
        />
      </div>
    </Content>
  );
}

export default Register;
