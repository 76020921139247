import React, { useState } from "react";

import axios from "axios";

import { FaUser } from "react-icons/fa";
import { Form, Alert } from "react-bootstrap";

import { normalizeCurrency } from "../utils";

function AddCoupon({ onClose, onAdd }) {
  const [title, setTitle] = useState("");
  const [value, setValue] = useState(normalizeCurrency(0));
  const [code, setCode] = useState("");
  const [description, setDescription] = useState("");
  const [dateStart, setDateStart] = useState("");
  const [dateEnd, setDateEnd] = useState("");

  const [msg, setMsg] = useState({
    type: "",
    text: "",
  });

  async function handleSubmit() {
    try {
      const response = await axios.post("/coupons", {
        title,
        description,
        value: Number(value.replace(",","")),
        code,
        dateStart,
        dateEnd,
      });
      if (response.status === 203) {
        setMsg({
          type: "danger",
          text: response.data.msg,
        });
      } else {
        setTitle("");
        setCode("");
        setValue("");
        setDescription("");
        setDateStart("");
        setDateEnd("");
        setMsg({
          type: "success",
          text: "Cumpon de desconto Criado",
        });
        onAdd();
      }
    } catch (err) {
      if (err.response && err.response.data && err.response.data.msg) {
        setMsg({
          type: "danger",
          text: err.response.data.msg,
        });
      } else {
        setMsg({
          type: "danger",
          text:
            "Ocorreu um erro em nossos servidores, contate a equipe técnica.",
        });
      }
    }
  }

  return (
    <>
      <button type="button" onClick={handleSubmit}>
        Salvar
      </button>
      <button type="button" className="ml-2" onClick={onClose}>
        Voltar
      </button>
      <div id="add_coupon_container">
        <span id="avatar">
          <FaUser />
        </span>
        <div>
          <Form>
            {(msg.type !== "" || msg.text !== "") && (
              <Alert variant={msg.type}>{msg.text}</Alert>
            )}
            <div className="row">
              <Form.Group className="col-md-6">
                <Form.Label>Título</Form.Label>
                <Form.Control
                  value={title}
                  onChange={(e) => setTitle(e.target.value)}
                />
              </Form.Group>
              <Form.Group className="col-md-6">
                <Form.Label>Descrição</Form.Label>
                <Form.Control
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
                  type="text"
                />
              </Form.Group>
            </div>
            <div className="row">
              <Form.Group className="col-md-6">
                <Form.Label>Código</Form.Label>
                <Form.Control
                  value={code}
                  onChange={(e) => setCode(e.target.value)}
                  type="text"
                />
              </Form.Group>
              <Form.Group className="col-md-6">
                <Form.Label>Valor</Form.Label>
                <Form.Control
                  value={value}
                  onChange={(e) =>
                    setValue(
                      normalizeCurrency(
                        Number(e.target.value.replace(/[^\d]/g, ""))
                      )
                    )
                  }
                />
              </Form.Group>
            </div>
            <div className="row">
              <Form.Group className="col-md-6">
                <Form.Label>Data de início</Form.Label>
                <Form.Control
                  value={dateStart}
                  onChange={(e) => setDateStart(e.target.value)}
                  type="date"
                />
              </Form.Group>
              <Form.Group className="col-md-6">
                <Form.Label>Data de expiração</Form.Label>
                <Form.Control
                  value={dateEnd}
                  onChange={(e) => setDateEnd(e.target.value)}
                  type="date"
                />
              </Form.Group>
            </div>
          </Form>
        </div>
      </div>
    </>
  );
}

export default AddCoupon;
