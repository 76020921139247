import styled from "styled-components";

export const Content = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  width: 100%;
  #header_content {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    background: #f9f9f9;
    padding: 20px 30px;
    border-bottom: 1px solid #f0f0f0;
    div {
      padding: 10px;
      border-radius: 6px;
      background: #e9e9e9;
      color: ${(props) => props.theme.primary};
      .avatar_user {
        padding: 5px;
        border-radius: 6px;
        margin-right: 10px;
        background: ${(props) => props.theme.primary};
        color: #fff;
        font-size: 30px;
      }
    }
  }
  #main_content {
    padding: 40px;
    @media screen and (max-width: 600px) {
      & {
        padding: 15px;
      }
    }
    display: flex;
    flex-direction: column;
    flex: 1;
    background: #fff;
    overflow: hidden;
    h2 {
      color: ${(props) => props.theme.primary};
    }
    div.grid_items3 {
      display: grid;
      grid-template-columns: 3fr 6fr;
      grid-gap: 30px;
      margin-top: 30px;
      @media screen and (max-width: 900px) {
        & {
          grid-template-columns: 1fr;
        }
      }
      .item {
        border-radius: 4px;
        color: #444;
        border: 1px solid #c9c9c9;
        background: #f9f9f9;
        h4 {
          font-size: 12px;
          padding-bottom: 15px;
          border-bottom: 1px solid #444;
          padding: 20px;
          margin-bottom: 0px;
          text-align: left;
          background: #fff;
        }
        div.content {
          margin: 0px;
          padding: 20px;
          text-align: center;
          border-radius: 4px;
          color: ${(props) => props.theme.primary};
          table {
            background: #fff;
            width: 100%;
            tr {
              td {
                padding: 10px;
                font-size: 14px;
                text-align: left;
              }
            }
          }
          &.box_insights {
            display: grid;
            grid-template-columns: 3fr 3fr 3fr;
            grid-gap: 20px;
          }
          &.box_insights2 {
            display: grid;
            grid-template-columns: 1fr;
            grid-gap: 20px;
          }
          .insights {
            text-align: left;
            background: #fff;
            border: 1px solid #dee2e6;
            padding: 10px 10px 0px;
            p {
              color: #444;
              font-weight: normal;
              strong {
                color: ${(props) => props.theme.primary};
                font-size: 14px;
                padding: 0px 6px;
              }
            }
          }
          p {
            font-size: 12px;
          }
        }
      }
    }

    div.grid_items2 {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-gap: 30px;
      margin-top: 30px;
      @media screen and (max-width: 900px) {
        & {
          grid-template-columns: 1fr;
        }
      }
      .item {
        border-radius: 4px;
        color: #444;
        border: 1px solid #c9c9c9;
        background: #f9f9f9;
        h4 {
          font-size: 12px;
          padding-bottom: 15px;
          border-bottom: 1px solid #444;
          padding: 20px;
          margin-bottom: 0px;
          text-align: left;
          background: #fff;
          display: flex;
          align-items: center;
          justify-content: space-between;
          small {
            display: flex;
            align-items: center;
            margin-top: 10px;
            input {
              font-size: 10px;
              width: 100%;
              max-width: 140px;
              margin: 0px 10px;
            }
          }
          button {
            background: #f9f9f9;
            padding: 5px 10px;
            border: 1px solid #fe0102;
            color: #fe0102;
            border-radius: 6px;
          }
        }
        div.content {
          margin: 0px;
          padding: 20px;
          text-align: center;
          border-radius: 4px;
          color: ${(props) => props.theme.primary};
          p {
            font-size: 12px;
            &.amount {
              font-size: 26px;
              margin: 0px;
              text-align: left;
            }
          }
        }
      }
    }
    div.grid_items {
      display: grid;
      grid-template-columns: 3fr 2fr 2fr 2fr;
      grid-gap: 30px;
      margin-top: 30px;
      @media screen and (max-width: 900px) {
        & {
          grid-template-columns: 1fr;
        }
      }
      .dateExport {
        display: flex;
        align-items: center;
        margin: 20px 15px;
        justify-content: center;
        span {
          display: flex;
          align-items: center;
          small {
            display: flex;
            align-items: center;
            input {
              font-size: 10px;
              width: 100%;
              max-width: 140px;
              margin: 0px 10px;
            }
          }
          button {
            background: #f9f9f9;
            padding: 2.5px 10px;
            font-size: 14px;
            border: 1px solid #fe0102;
            color: #fe0102;
            border-radius: 6px;
          }
        }
      }
      .item {
        border-radius: 4px;
        color: #444;
        border: 1px solid #c9c9c9;
        background: #f9f9f9;
        h4 {
          font-size: 12px;
          padding-bottom: 15px;
          border-bottom: 1px solid #444;
          padding: 20px;
          margin-bottom: 0px;
          text-align: center;
          background: #fff;
          button {
            margin: 0;
            padding: 0;
            border: none;
            background: none;
          }
        }
        div.content {
          margin: 0px;
          padding: 20px;
          text-align: center;
          border-radius: 4px;
          color: ${(props) => props.theme.primary};
          p {
            font-size: 12px;
            &.amount {
              font-size: 26px;
              margin: 0px;
            }
          }
        }
      }
    }
  }
`;
