import React, { useState, useEffect } from "react";

import { Container } from "./styles";

import UserHeader from "../components/SubHeader";
import { Form } from "react-bootstrap";

import { useDispatch } from "react-redux";

import axios from "axios";
import { updateUser } from "../../../store/modules/user/actions";
import { useTranslation } from "react-i18next";

function Profile() {
  const [values, setValues] = useState({
    username: "",
    email: "",
    password: "",
    id: "",
  });
  const [loading, setLoading] = useState(false);
  const [editPassword, setEditPassword] = useState(false);

  const dispatch = useDispatch();

  const { t } = useTranslation();

  useEffect(() => {
    async function loadData() {
      setLoading(true);
      try {
        const { data } = await axios.get("me");
        setValues({
          ...values,
          email: data.email,
          username: data.username,
          id: data.id,
        });
      } catch (err) {}
      setLoading(false);
    }
    loadData();
  }, []); // eslint-disable-line

  async function handleSubmit(e) {
    e.preventDefault();
    setLoading(true);
    try {
      await axios.put(`/users/${values.id}`, {
        ...values,
        password: editPassword ? values.password : undefined,
        password_confirmation: editPassword ? values.password : undefined,
      });
      dispatch(updateUser(values.username, values.email));
    } catch (err) {}
    setLoading(false);
  }

  return (
    <Container>
      <UserHeader />
      <main>
        <h2>{t("Editar Conta")}</h2>
        <Form onSubmit={handleSubmit}>
          <Form.Group>
            <Form.Label>Username</Form.Label>
            <Form.Control
              type="text"
              value={values.username}
              onChange={(e) =>
                setValues({ ...values, username: e.target.value })
              }
              disabled={loading}
              required
            />
          </Form.Group>
          <div className="row">
            <div className="col-md-6">
              <Form.Group>
                <Form.Label>E-mail</Form.Label>
                <Form.Control
                  type="email"
                  value={values.email}
                  onChange={(e) =>
                    setValues({ ...values, email: e.target.value })
                  }
                  disabled={loading}
                  required
                />
              </Form.Group>
            </div>
            <div className="col-md-6">
              {!editPassword && (
                <button
                  type="button"
                  style={{ marginTop: "31px" }}
                  className="btn btn-warning btn-block btn-upper"
                  onClick={() => setEditPassword(true)}
                >
                  {t("Editar Senha")}
                </button>
              )}
              {editPassword && (
                <Form.Group>
                  <Form.Label>Senha</Form.Label>
                  <Form.Control
                    type="password"
                    value={values.password}
                    onChange={(e) =>
                      setValues({ ...values, password: e.target.value })
                    }
                    disabled={loading}
                    required
                  />
                </Form.Group>
              )}
            </div>
          </div>
          <div className="d-flex">
            <button type="submit" className="btn btn-primary">
              {t("Salvar")}
            </button>
          </div>
        </Form>
      </main>
    </Container>
  );
}

export default Profile;
