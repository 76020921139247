import React, { useState} from "react";

import axios from "axios";

import { FaVideo } from "react-icons/fa";
import { Form, Alert, Spinner, ProgressBar } from "react-bootstrap";

function AddUser({ course_id, onClose, onAdd }) {
  const [loading, setLoading] = useState(false);
  const [image, setImage] = useState(null);
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");

  const [progress, setProgress] = useState(0);

  const [msg, setMsg] = useState({
    type: '',
    text: '',
  })

  async function handleSubmit() {
    if(image === null || title === "" || description === "") {
      return setMsg({
        type: 'danger',
        text: 'Preencha todos os campos',
      })
    }
    setProgress(0);
    setLoading(true);
    try {
      const obj = new FormData();
      obj.append('file', image);
      obj.append('title', title);
      obj.append('description', description);
      const response = await axios.post(`courses/${course_id}/videos`, obj);
      if(response.status === 203) {
        setMsg({
          type: 'danger',
          text: response.data.msg,
        });
      }else{
        setImage(null);
        setTitle('');
        setDescription('');
        setMsg({
          type: 'success',
          text: 'Vídeo Criado',
        });
        onAdd();
      }
    } catch (err) {
      if(err.response && err.response.data && err.response.data.msg) {
        setMsg({
          type: 'danger',
          text: err.response.data.msg,
        });
      }else{
        setMsg({
          type: 'danger',
          text: 'Ocorreu um erro em nossos servidores, contate a equipe técnica.',
        });
      }
    }
    setLoading(false);
  }

  return (
    <>
      <div id="add_container">
        <span id="avatar">
          <FaVideo />
        </span>
        <div>
          <Form>
            {(msg.type !== '' || msg.text !== '') && (
              <Alert variant={msg.type}>
                {msg.text}
              </Alert>
            )}
            <Form.Group>
              <Form.Label>Thumbnail</Form.Label>
              <Form.File 
                id="custom-file"
                label={image !== null ? image.name : 'Escoher'}
                custom
                onChange={(e) => setImage(e.target.files[0])}
                disabled={loading}
              />
            </Form.Group>
            <div className="row">
              <Form.Group className="col-md-6">
                <Form.Label>Título</Form.Label>
                <Form.Control
                  value={title}
                  onChange={(e) => setTitle(e.target.value)}
                  type="title"
                  disabled={loading}
                />
              </Form.Group>
              <Form.Group className="col-md-6">
                <Form.Label>Descrição</Form.Label>
                <Form.Control
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
                  type="text"
                  disabled={loading}
                />
              </Form.Group>
            </div>
            {loading && (
              <ProgressBar animated now={progress} variant="warning" className="mb-4"/>
            )}
            <button type="button" onClick={handleSubmit} className="btn btn-primary btn-sm" disabled={loading}>
              {loading ? <Spinner animation="border"/> : 'Salvar'}
            </button>
            <button type="button" className="btn btn-light btn-sm ml-2" onClick={onClose} disabled={loading}>
              Cancelar
            </button>
          </Form>
        </div>
      </div>
    </>
  );
}

export default AddUser;
