import React, { useState, useMemo } from "react";

import axios from "axios";

import { FaProductHunt } from "react-icons/fa";
import { Form, Alert, Spinner, InputGroup } from "react-bootstrap";

import { normalizeCurrency } from "../utils";

function AddUser({ onClose, onAdd }) {
  const [loading, setLoading] = useState(false);
  const [image, setImage] = useState(null);
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [value, setValue] = useState(normalizeCurrency(0));

  const [msg, setMsg] = useState({
    type: "",
    text: "",
  });

  const preview = useMemo(() => {
    return image ? URL.createObjectURL(image) : null;
  }, [image]);

  async function handleSubmit() {
    if (title === "" || description === "") {
      return setMsg({
        type: "danger",
        text: "Preencha todos os campos",
      });
    }
    setLoading(true);
    try {
      const obj = new FormData();
      obj.append("file", image);
      obj.append("title", title);
      obj.append("description", description);
      obj.append("value", Number(value.replace(/[^\d]/g, "")));
      const response = await axios.post("/products", obj);
      if (response.status === 203) {
        setMsg({
          type: "danger",
          text: response.data.msg,
        });
      } else {
        setImage(null);
        setTitle("");
        setDescription("");
        setMsg({
          type: "success",
          text: "Produto Criado",
        });
        onAdd();
        onClose();
      }
    } catch (err) {
      if (err.response && err.response.data && err.response.data.msg) {
        setMsg({
          type: "danger",
          text: err.response.data.msg,
        });
      } else {
        setMsg({
          type: "danger",
          text:
            "Ocorreu um erro em nossos servidores, contate a equipe técnica.",
        });
      }
    }
    setLoading(false);
  }

  return (
    <>
      <div id="add_container">
        <span id="avatar">
          <FaProductHunt />
        </span>
        <div>
          <Form>
            {(msg.type !== "" || msg.text !== "") && (
              <Alert variant={msg.type}>{msg.text}</Alert>
            )}
            <div id="file_image">
              <label
                htmlFor="custom-file"
                style={{ backgroundImage: `url(${preview})` }}
              >
                <h4>
                  {image !== null ? "Trocar imagem" : "Selecionar Imagem"}
                </h4>
              </label>

              <input
                type="file"
                id="custom-file"
                label={image !== null ? image.name : "Escoher"}
                onChange={(e) => setImage(e.target.files[0])}
                disabled={loading}
                style={{ display: "none" }}
              />
              <small>[INFORMAR TAMANHO E TIPO DE IMAGEM]</small>
            </div>
            <div className="row">
              <Form.Group className="col-md-4">
                <Form.Label>Título</Form.Label>
                <Form.Control
                  value={title}
                  onChange={(e) => setTitle(e.target.value)}
                  type="title"
                  disabled={loading}
                />
              </Form.Group>
              <Form.Group className="col-md-4">
                <Form.Label>Descrição</Form.Label>
                <Form.Control
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
                  type="text"
                  disabled={loading}
                />
              </Form.Group>
              <Form.Group className="col-md-4">
                <Form.Label>Valor</Form.Label>
                <InputGroup>
                  <InputGroup.Prepend>
                    <InputGroup.Text>R$</InputGroup.Text>
                  </InputGroup.Prepend>
                  <Form.Control
                    value={value}
                    onChange={(e) =>
                      setValue(
                        normalizeCurrency(
                          Number(e.target.value.replace(/[^\d]/g, ""))
                        )
                      )
                    }
                    type="text"
                    disabled={loading}
                  />
                </InputGroup>
              </Form.Group>
            </div>
            <button
              type="button"
              onClick={handleSubmit}
              className="btn btn-primary btn-sm"
              disabled={loading}
            >
              {loading ? <Spinner animation="border" /> : "Salvar"}
            </button>
            <button
              type="button"
              className="btn btn-light btn-sm ml-2"
              onClick={onClose}
              disabled={loading}
            >
              Cancelar
            </button>
          </Form>
        </div>
      </div>
    </>
  );
}

export default AddUser;
