import React, { useState } from 'react';

import { Content } from './styles';

import UserHeader from '../components/SubHeader';
import Customers from './components/Customers';

function Register() {
  const [current, ] = useState(1);

  return (
      <Content>
        <UserHeader/>
        <div id="main_content">
          <h2>Cliente</h2>
          {current === 1 && <Customers />}
        </div>
      </Content>
  );
}

export default Register;