import React, { useState, useEffect } from "react";

import axios from "axios";
import { MdClose } from "react-icons/md";
import { RiVideoLine } from "react-icons/ri";
import { ProgressBar, Button } from "react-bootstrap";

function VideoUpload({ file, onRemove, onUpload, courseId, uploaded }) {
  const [progress, setProgress] = useState(uploaded ? 100 : 0);
  const [error, setError] = useState("");

  function YouTubeGetID(url) {
    url = url.split(/(vi\/|v=|\/v\/|youtu\.be\/|\/embed\/)/);
    return url[2] !== undefined ? url[2].split(/[^0-9a-z_-]/i)[0] : url[0];
  }

  async function uploadFile() {
    try {
      setProgress(0);
      setError("");

      if (file.type === "youtube") {
        const formData = new FormData();
        formData.append("course_id", courseId);
        formData.append("name", file.name);
        formData.append("description", file.description);
        formData.append("ref_id", YouTubeGetID(file.url));
        formData.append("thumbnail_url", file.thumbnailUrl);

        const res = await axios.post(`/videoyoutube`, formData);

        if (res.status === 200) {
          setProgress(100);
          onUpload();
        }
      } else {
        const formData = new FormData();
        formData.append("path", file);
        formData.append("course_id", courseId);
        console.log("aqui");

        await axios.post(`/video`, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
          onUploadProgress: (e) => {
            setProgress(Math.round((100 * e.loaded) / e.total));
          },
        });
        onUpload();
      }
    } catch (err) {
      if (err.response && err.response.status === 500) {
        setError(
          "Ocorreu um erro em nossos servidores, contate a equipe técnica"
        );
      } else if (err.response && err.response.status === 400) {
        setError("Erro ao fazer upload do arquivo, tente novamente.");
      } else if (err.response && err.response.status === 404) {
        setError("Arquivo não encontrado");
      } else {
        setError(
          "Ocorreu um erro em nossa aplicação, contate a equipe técnica"
        );
      }
    }
  }

  useEffect(() => {
    if (file && progress === 0 && !uploaded) {
      uploadFile();
    }
  }, [file]); // eslint-disable-line

  const reduceTitle = (title) => {
    if (title.length > 12) {
      return `${title.slice(0, 12)}...`;
    } else {
      return title;
    }
  };
  const fileSize = (size) => {
    if (size < 1024) {
      return `${size} B`;
    } else if (size > 1024 && size < 1024000) {
      return `${Math.round(size / 1024)} KB`;
    } else if (size > 1024000 && size < 1024000000) {
      return `${Math.round(size / 1024 / 1024)} MB`;
    } else {
      return `${Math.round(size / 1024 / 1024 / 1024)} GB`;
    }
  };

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "flex-start",
        padding: "16px",
        background: "#f9f9f9",
        border: "1px solid #c9c9c9",
        flexWrap: "wrap",
      }}
    >
      <div className="d-flex flex-column justify-content-center align-items-center flex-wrap">
        <div
          style={{
            width: "100px",
            height: "60px",
            background: "#c9c9c9",
            borderRadius: "2px",
            color: "#fff",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexWrap: "wrap",
          }}
        >
          <RiVideoLine size={30} />
        </div>
        <small>{reduceTitle(file.name)}</small>
        <small>
          {file.type !== "youtube" && `${fileSize(file.size)} | `}
          {file.type !== "youtube"
            ? file.type.split("/")[1].toUpperCase()
            : "YouTube"}
        </small>
      </div>
      <div
        className="d-flex flex-column justify-content-center align-items-center"
        style={{ width: "90%" }}
      >
        <ProgressBar
          animated={error !== "" ? false : progress === 100 ? false : true}
          now={progress}
          variant={
            error !== "" ? "danger" : progress === 100 ? "success" : "primary"
          }
          style={{ width: "90%" }}
          label={error !== "" ? "ERRO" : `${progress}%`}
        />
        <small className="text-danger d-block mt-2">{error}</small>
        {error !== "" && (
          <Button
            type="button"
            variant="outline-secondary"
            size="sm"
            className="mt-2"
            onClick={() => uploadFile()}
          >
            Tentar Novamente
          </Button>
        )}
      </div>
      {(progress < 100 || error !== "") && (
        <button
          type="button"
          style={{
            width: "24px",
            height: "24px",
            borderRadius: "50%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            background: "none",
            border: "none",
          }}
          onClick={() => onRemove()}
        >
          <MdClose />
        </button>
      )}
    </div>
  );
}

export default VideoUpload;
