export function updateUser(username, email) {
  return {
    type: '@user/UPDATE_USER',
    payload: {
      user: {
        username,
        email,
      }
    }
  }
}